import { ModalWrapper, ModalContent, ModalClose, ModalHeader, ModalFooter } from "../../styles/modals";
import { H2 } from "../../styles/type/headings";
import { ButtonList, StandardButtonAnchor, StandardCancelAnchor } from "../../styles/type/buttons";

const ConfirmModal = ({ title, content = null, confirmButtonText = "Yes", cancelButtonText = "No", confirmButtonAction, cancelButtonAction, modalData = null, external = false }) => (
	<>
		<ModalWrapper external={external}>
			<ModalHeader external={external}>
				<H2>Confirmation Required</H2>
				<ModalClose onClick={cancelButtonAction}>close</ModalClose>
			</ModalHeader>
			{(title || content) && (
				<ModalContent external={external}>
					<p>{title}</p>
					<p>{content}</p>
				</ModalContent>
			)}
			<ModalFooter>
				<ButtonList>
					<StandardCancelAnchor onClick={cancelButtonAction}>{cancelButtonText}</StandardCancelAnchor>
				</ButtonList>
				<ButtonList justify="flex-end">
					<StandardButtonAnchor onClick={confirmButtonAction}>{confirmButtonText}</StandardButtonAnchor>
				</ButtonList>
			</ModalFooter>
		</ModalWrapper>
	</>
);

export default ConfirmModal;
