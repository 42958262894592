import { lighten } from 'polished';
import styled from 'styled-components'
import { RelSize, Ratio } from '../mixins'
import theme from '../theme'
import { SearchSelect, SelectOptions, ToggleItem } from './inputs';
import { AddCtaAnchor, EditCtaAnchor } from '../type/buttons';

export const Form = styled.form`
    flex-wrap: wrap;
    justify-content: ${props => props.align ? props.align : "normal"};
    margin: 0 auto;
    padding: 0;
    max-width: 100%;
    width: 100%;
    ${props => props.inline && `
        display: flex;
        flex-direction row;
        flex-wrap: nowrap;
        flex: 1;
        justify-content: ${props => props.align ? props.align : "center"};
        > div {
            &:not(:last-child) {
                @media all and (min-width: 769px) {
                    margin-right: ${RelSize(10, 16)};
                }
            }
        }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) and (min-width: 769px) {  
            min-width: ${RelSize(300, 16)};
        }
    `}
    ${props => props.push && `
        margin-top: ${RelSize(40, 16)};
    `}
`;

export const FormGroup = styled.div`
	border-top-left-radius: ${props => props.active ? RelSize(10, 16) : 0};
	border-top-right-radius: ${props => props.active ? RelSize(10, 16) : 0};
    margin-bottom: ${props => props.flush ? 0 : RelSize(20, 16)};
`;

export const FormGroupSection = styled.div`
    display: ${props => props.active ? "block" : "none"};
	background-color: ${({ alt }) => alt ? theme.white : theme.secondaryBackgroundGrey};
	padding: ${RelSize(20, 16)};
	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
		legend {
			color: ${theme.primaryGreen};
			font-weight: 700;
			margin: 0 0 ${RelSize(20, 16)};
			padding: 0;
		}
		+ fieldset {
			margin-top: ${RelSize(20, 16)};
		}
	}
`;

export const FormGroupLabel = styled.p`
	background-color: ${props => props.danger ? theme.red : props.active ? theme.quarterinaryGreen : props.alt ? theme.white : theme.secondaryBackgroundGrey};
	border-top-left-radius: ${props => props.active ? RelSize(10, 16) : RelSize(10, 16)};
	border-top-right-radius: ${props => props.active ? RelSize(10, 16) : RelSize(10, 16)};
	border-bottom-left-radius: ${props => props.active ? 0 : RelSize(10, 16)};
	border-bottom-right-radius: ${props => props.active ? 0 : RelSize(10, 16)};
	color: ${props => props.active ? theme.white : theme.primaryGreen};
	cursor: ${props => props.accordion ? "pointer" : "default"};
	/* border: 1px solid red; */
    /* border-radius: 8px; */
    font-weight: 700;
    margin: 0;
    padding: ${RelSize(20, 16)};
    position: relative;
	${props => props.accordion && `
		padding-right: ${RelSize(50, 16)};
		&:after {
			border-top: ${props.active ? "2px solid white" : "2px solid #294338"};
			border-right: ${props.active ? "2px solid white" : "2px solid #294338"};
			content: "";
			height: 15px;
			right: ${RelSize(20, 16)};
			position: absolute;
			top: 50%;
			transform: ${!props.active ? "translateY(-75%) rotate(135deg)" : "translateY(-25%) rotate(315deg)"};
			width: 15px;
		}
	`}
`;

export const FormGroupTotal = styled.span`
    font-size: ${RelSize(12, 16)};
    font-weight: 200;
    padding-left: ${RelSize(10, 12)};
`;

export const ModalFormGroup = styled.div`
	margin-bottom: ${props => props.flush ? 0 : RelSize(50, 16)};
`;

export const ModalFormGroupLabel = styled.p`
	border-bottom: 2px solid ${theme.primaryGreen};
	color: ${theme.primaryGreen};
	font-weight: 700;
	margin: 0 0 ${RelSize(20, 16)};
	padding-bottom: ${RelSize(20, 16)};
`;

export const QuickReferenceBlockTitle = styled.div`
	border-bottom: ${props => props.small || props.dividerNone ? 0 : `2px solid ${theme.borderGrey}`};
	color: ${theme.primaryGreen};
	cursor: ${props => props.hasClick ? "pointer" : "default"};
	font-size: ${props => props.small ? RelSize(12, 16) : RelSize(16, 16)};
    font-weight: 700;
    margin: 0;
    padding: ${props => props.small ? RelSize(10, 12) : RelSize(20, 16)} ${props => props.small ? RelSize(20, 12) : RelSize(20, 16)};
    position: relative;
	transition: all ease-in 0.2s;
	${props => props.dividerTop && `
		border-top: 2px solid ${theme.borderGrey};
	`};
	a:not(.noclick) {
		background-color: ${props => props.theme.primaryGreen};
		border-radius: ${RelSize(5, 12)};
		color: ${props => props.theme.white};
		display: inline-block;
		font-size: ${props => props.small ? RelSize(12, 12) : RelSize(12, 16)};
		line-height: ${RelSize(12, 12)};
		margin-left: ${RelSize(5, 12)};
		padding: ${RelSize(5, 12)} ${RelSize(10, 12)};
	}
	a {
		&.noclick {
			font-size: ${RelSize(14, 16)};
			font-weight: 400;
			margin-left: 0;
			margin-top: ${RelSize(10, 14)};
		}
	}
	> span {
		align-items: center;
		display: flex;
		span {
			margin-left: ${props => props.small ? RelSize(5, 12) : RelSize(5, 16)};
		}
	}
	${props => props.hasClick && `
		&:hover {
			background-color: ${props.theme.formGroupBg};
		}
	`}
`;

export const QuickReferenceSectionTitle = styled.div`
	background-color: ${theme.quarterinaryGreen};
	border-top-left-radius: ${RelSize(10, 16)};
	border-top-right-radius: ${RelSize(10, 16)};
	color: ${theme.white};
    font-weight: 700;
    margin: 0;
    padding: ${RelSize(20, 16)};
    position: relative;
`;

export const QuickReferenceSectionBlockRow = styled.div`
	display: flex;
	> div {
		flex: 1 0 auto;
    	width: 100%;

    	&:nth-last-child(n+2),
		&:nth-last-child(n+2) ~ div {
			width: 50%;
		}
		+ div {
			border-left: 1px solid ${theme.backgroundGrey};
		}
	}
	@media all and (max-width: 1439px) {
		flex-wrap: wrap;
		> div {
			flex-basis: 100% !important;
			flex-shrink: 0;
			width: 100%;
		}
	}
`;

export const QuickReferenceSectionBlock = styled.div`
	border-bottom: 1px solid ${theme.backgroundGrey};
	padding: ${RelSize(20, 16)};
	${({ flush }) => flush && `
		border-bottom: none;
		padding-bottom: 0;
	`}
	${EditCtaAnchor} {
		font-size: ${RelSize(14, 16)};
		font-weight: 400;
		margin-left: 0;
	}
	& > & {
		padding-left: 0;
		padding-right: 0;
	}
`;

export const QuickReferenceSectionBlockContent = styled.div`
	display: flex;
	strong, label {
		font-size: ${RelSize(14, 16)};
	}
	span {
		font-size: ${({ large }) => large ? RelSize(16, 16) : RelSize(14, 16)};
	}
	a:not([class]) {
		font-size: ${RelSize(14, 16)};
		text-decoration: underline;
	}
	strong {
		color: ${theme.primaryGreen};
		font-weight: 700;
	}
	& + & {
		margin-top: ${RelSize(5, 16)};
	}
	> * + * {
		margin-left: ${RelSize(10, 16)};
	}
	${EditCtaAnchor} {
		margin-left: ${RelSize(10, 16)};
	}
	${({ flush }) => flush && `
		margin-top: 0 !important;
	`}
	${({ clickable }) => clickable && `
		cursor: pointer
	`}
	${({ expiry }) => expiry && `
		> div {
			align-items: center;
			font-size: ${RelSize(14, 16)};
		}
		> i {
			height: ${RelSize(15, 16)};
			margin-left: ${RelSize(10, 14)};
			width: ${RelSize(15, 16)};
		}
	`}
	${({ small }) => small && `
		font-size: ${RelSize(12, 14)};
	`}
	${({ collapse }) => collapse === "sm" && `
		@media all and (max-width: 768px) {
			flex-direction: column;
		}
	`}
`;

export const QuickReferenceSectionBlockContentStatus = styled.span`
	color: ${({ approved, staticStatus }) => approved ? theme.trafficLightGreen : staticStatus ? theme.primaryGreen : theme.trafficLightRed};
	${({ canClick }) => canClick && `
		cursor: pointer;
	`}
	display: flex;
	flex-wrap: ${({ wrap }) => wrap ? "wrap" : "nowrap"};
	font-weight: 700;
`;

export const QuickReferenceBlock = styled.div`
	border: 2px solid ${theme.borderGrey};
	// border-top: 0;
	border-bottom-left: ${RelSize(10, 16)};
	border-bottom-right: ${RelSize(10, 16)};
    margin-bottom: ${props => props.flush ? 0 : RelSize(20, 16)};
	${QuickReferenceBlockTitle} + ${QuickReferenceBlockTitle} {
		border-top: 0;
	}
`;

export const AssigneeBlock = styled.div`
	border: 2px solid ${theme.borderGrey};
	border-radius: ${RelSize(10, 16)};
    margin-bottom: ${props => props.flush ? 0 : RelSize(20, 16)};
	@media all and (max-width: 768px) {
		display: none;
	}
`;

export const AssigneeBlockTitle = styled.div`
	border-bottom: ${props => props.small ? 0 : `2px solid ${theme.borderGrey}`};
	color: ${theme.primaryGreen};
	cursor: ${props => props.hasClick ? "pointer" : "default"};
	font-size: ${props => props.small ? RelSize(12, 16) : RelSize(16, 16)};
    font-weight: 700;
    margin: 0;
    position: relative;
	transition: all ease-in 0.2s;
	${props => props.dividerTop && `
		border-top: 2px solid ${theme.borderGrey};
	`};
	> a {
		align-items: center;
		color: ${props => props.theme.black};
		display: flex;
		padding: ${props => props.small ? RelSize(10, 12) : RelSize(20, 16)} ${props => props.small ? RelSize(20, 12) : RelSize(20, 16)};
		span {
			margin-left: ${props => props.small ? RelSize(5, 12) : RelSize(5, 16)};
		}
	}
	&:hover {
		background-color: ${props => props.theme.formGroupBg};
	}
`;

export const Assignee = styled.div`
	align-items: center;
	display: flex;
	margin-left: ${RelSize(5, 12)};
`;

export const QuickReferenceBlockContent = styled.div`
	${props => props.dividerTop && `
		border-top: 2px solid ${theme.borderGrey};
	`};
	padding: ${RelSize(20, 16)};
	${EditCtaAnchor}, ${AddCtaAnchor} {
		font-size: ${RelSize(14, 16)};
		font-weight: 400;
	}
`;

export const ExternalFormGroup = styled.div`
	background-color: ${theme.white};
	min-height: calc(100vh - ${RelSize(180, 16)});
	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
		legend {
			color: ${theme.primaryGreen};
			font-weight: 700;
			margin: 0 0 ${RelSize(20, 16)};
			padding: 0;
		}
		+ fieldset {
			margin-top: ${RelSize(20, 16)};
		}
	}
`;

export const ExternalFormGroupTitle = styled.div`
	background-color: ${theme.white};
	margin: 0 auto;
	max-width: 768px;
	padding: ${RelSize(50, 16)};
	text-align: center;
`;

export const ExternalStep = styled.span`
	color: ${theme.primaryGreen};
	display: block;
	font-size: ${RelSize(12, 16)};
	margin-bottom: ${RelSize(20, 12)};
	text-transform: uppercase;
`;

export const ExternalTitle = styled.p`
	color: ${theme.primaryGreen};
	font-size: ${RelSize(30, 16)};
	font-weight: 700;
	margin-bottom: ${RelSize(10, 30)};
`;

export const ExternalDesc = styled.p`
	color: ${props => props.bold ? theme.red : theme.primaryGreen};
	margin: 0 0 ${props => props.pushBottom ? RelSize(20, 16) : 0};
	+ p {
		margin-top: ${RelSize(10, 16)};
	}
	ul {
		margin-top: ${RelSize(10, 16)};
		padding: 0;
		li {
			color: ${props => props.bold ? theme.red : theme.primaryGreen};
		}
	}
`;

export const ExternalDescProgress = styled.p`
	color: ${theme.trafficLightGreen};
	font-weight: 700;
	margin: 0 0 ${props => props.pushBottom ? RelSize(20, 16) : 0};
	+ p {
		margin-top: ${RelSize(10, 16)};
	}
	ul {
		margin-top: ${RelSize(10, 16)};
		padding: 0;
		li {
			color: ${props => props.bold ? theme.red : theme.primaryGreen};
		}
	}
`;

export const ExternalDescList = styled.ul`
	list-style: none;
	margin-top: ${RelSize(10, 16)};
	padding: 0;
	li {
		color: ${props => props.bold ? theme.red : theme.primaryGreen};
	}
`;

export const ExternalFormGroupSection = styled.div`
	background-color: ${props => props.full ? theme.white : theme.formGroupBg};
	padding: ${RelSize(50, 16)};
	${({ flushTop }) => flushTop && `
		padding-top: 0;
	`}
	> div {
		max-width: ${props => props.full ? theme.maxWidth : "768px"};
		margin: 0 auto;
		label {
			color: ${theme.primaryGreen};
		}
		${ToggleItem} {
			background-color: ${theme.white};
			input {
				&:checked {
					~ label {
						&::before {
							background: ${theme.primaryGreen};
						}
					}
				}
				&:checked {
					~ label {
						&::after {
							color: ${theme.primaryGreen};
						}
					}
				}
				&[disabled] {
					&:checked {
						~ label {
							&::after {
								color: ${theme.quarterinaryGreen};
							}
						}
					}
				}
			}
			label {
				&::before {
					border: 2px solid ${theme.quarterinaryGreen};
				}
			}
		}
	}
`;

export const ExternalFormFooter = styled.div`
	background-color: ${theme.white};
	padding: ${RelSize(20, 16)} ${RelSize(50, 16)};
`;

export const FormErrorStyled = styled.div`
	background-color: ${lighten(0.4, theme.red)};
	background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICA8cGF0aCBpZD0iRXhjbHVzaW9uXzUiIGRhdGEtbmFtZT0iRXhjbHVzaW9uIDUiIGQ9Ik0tMjM1Mi0xMDQ3YTkuOTQsOS45NCwwLDAsMS0zLjg5My0uNzg2LDkuOTY0LDkuOTY0LDAsMCwxLTMuMTc4LTIuMTQzLDkuOTYyLDkuOTYyLDAsMCwxLTIuMTQzLTMuMTc5QTkuOTQxLDkuOTQxLDAsMCwxLTIzNjItMTA1N2E5LjkzNyw5LjkzNywwLDAsMSwuNzg2LTMuODkzLDkuOTU4LDkuOTU4LDAsMCwxLDIuMTQzLTMuMTc5LDkuOTY2LDkuOTY2LDAsMCwxLDMuMTc4LTIuMTQzQTkuOTM4LDkuOTM4LDAsMCwxLTIzNTItMTA2N2E5LjkzNiw5LjkzNiwwLDAsMSwzLjg5Mi43ODYsOS45NzIsOS45NzIsMCwwLDEsMy4xNzgsMi4xNDMsOS45NTksOS45NTksMCwwLDEsMi4xNDMsMy4xNzlBOS45MzgsOS45MzgsMCwwLDEtMjM0Mi0xMDU3YTkuOTM2LDkuOTM2LDAsMCwxLS43ODYsMy44OTIsOS45NTksOS45NTksMCwwLDEtMi4xNDMsMy4xNzksOS45NjcsOS45NjcsMCwwLDEtMy4xNzgsMi4xNDNBOS45MzksOS45MzksMCwwLDEtMjM1Mi0xMDQ3Wm0xLjU0MS0xMi40MjloMGwtLjY4OC4xMzNjLS4yNDIuMDQ3LS42NTMuMS0xLjIyMy4xNjhzLTEuMDEuMTA4LTEuMzE3LjEyOXYuNDIyYTIuNzIxLDIuNzIxLDAsMCwxLC43NDIuMWMuMTM1LjA1Mi4yLjE4Ni4yLjRhLjYwNy42MDcsMCwwLDEtLjAxMi4xMTdjLS4wMDguMDQyLS4wMTcuMDgzLS4wMjguMTI1bC0uOTYxLDMuNTk0Yy0uMDc0LjI3LS4xMjguNDgtLjE2NC42NDFhMy4yMTIsMy4yMTIsMCwwLDAtLjA5NC42MTcuOTgxLjk4MSwwLDAsMCwuMzQ3LjgyNCwxLjI1NSwxLjI1NSwwLDAsMCwuNzg1LjI3LDIuMDc5LDIuMDc5LDAsMCwwLDEuNDUzLS42NTYsMTAuOTgsMTAuOTgsMCwwLDAsMS4yLTEuNTQ3bC0uMzUyLS4yMjdhNi4zMDYsNi4zMDYsMCwwLDEtLjYzMy44NjhjLS4yMzkuMjc1LS40NDEuNDE0LS42LjQxNGEuMzEzLjMxMywwLDAsMS0uMTcyLS4wNTFjLS4wNTItLjAzNC0uMDc4LS4xMDktLjA3OC0uMjIzYTQuMjMyLDQuMjMyLDAsMCwxLC4xNDEtLjc1Yy4wMzctLjE1NS4xMS0uNDM2LjIxMS0uODEybDEuMjQyLTQuNTU1Wm0tLjQ0NS0zLjU3MWExLjA1MSwxLjA1MSwwLDAsMC0uNzYyLjMxMiwxLjAzLDEuMDMsMCwwLDAtLjMxNi43NTgsMS4wNDIsMS4wNDIsMCwwLDAsLjMxNi43NjIsMS4wNDQsMS4wNDQsMCwwLDAsLjc2Mi4zMTcsMS4wMywxLjAzLDAsMCwwLC43NTgtLjMxNywxLjA0NywxLjA0NywwLDAsMCwuMzEzLS43NjIsMS4wMzUsMS4wMzUsMCwwLDAtLjMxMi0uNzU4LDEuMDM3LDEuMDM3LDAsMCwwLS43NTUtLjMxMloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIzNjIgMTA2NykiIGZpbGw9IiNlMjMxNDEiLz4KPC9zdmc+Cg==');
	background-size: 20px 20px;
	background-position: 10px 7px;
	background-repeat: no-repeat;
	border: 1px solid ${theme.red};
    color: ${theme.red};
    display: block;
    font-size: ${RelSize(14, 16)};
    font-weight: ${props => props.small ? 400 : 700};
    margin-bottom: ${RelSize(20, 16)};
    padding: ${RelSize(10, 14)} ${RelSize(10, 14)} ${RelSize(10, 14)} ${RelSize(40, 14)};
    position: relative;
    text-align: left;
    p {
        margin-bottom: 0;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        margin: 0;
        padding: 0;
        &:not(:last-child) {
            margin-bottom: ${RelSize(5, 14)};
        }
    }
`;

export const FormSubGroup = styled.div`
    font-size: ${RelSize(14, 16)};
    line-height: ${Ratio(14)};
    margin: ${RelSize(20, 14)} 0;
`;

export const FormFieldset = styled.div`
    border: 2px solid ${theme.black};
    margin-bottom: ${RelSize(20, 16)};
    padding: ${RelSize(20, 16)};
`;

export const QuickReferenceClientTable = styled.div`
	> div {
		&:not(:last-child) {
			border-bottom: 2px solid ${theme.borderGrey};
			margin-bottom: ${RelSize(10, 16)};
			padding-bottom: ${RelSize(10, 16)};
		}
		p {
			margin: 0;
			&:first-child {
				font-size: ${RelSize(14, 16)};
				font-weight: 700;
			}
			&:last-child {
				display: flex;
				flex-wrap: wrap;
			}
		}
		span {
			font-size: ${RelSize(12, 16)};
			&:not(:last-child) {
				margin-right: ${RelSize(10, 12)};
			}
		}
	}
`;

export const FormSubForm = styled.div`
	margin-top: ${RelSize(50, 16)};
`;

export const FormSubFormTitle = styled.p`
	color: ${theme.primaryGreen};
	display: block;
	font-size: ${RelSize(16, 16)};
	font-weight: 700;
	line-height: ${RelSize(21, 16)};
	margin-bottom: ${RelSize(20, 16)};
`;

export const CompanyDocumentsWrapper = styled.div`
	margin-top: ${RelSize(50, 16)};
`;