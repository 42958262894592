import { useState } from "react";
import Toggle from "../forms/inputs/toggle";
import { ModalWrapper, ModalContent, ModalClose, ModalHeader, ModalFooter } from "../../styles/modals";
import { H2 } from "../../styles/type/headings";
import { ButtonList, StandardButtonAnchor, StandardCancelAnchor } from "../../styles/type/buttons";
import { Form } from "../../styles/forms";

type Props = {
	title: string;
	content?: string;
	confirmButtonText?: string;
	cancelButtonText?: string;
	confirmButtonAction: (sendActivation: boolean) => void;
	cancelButtonAction: () => void;
};

const UserAddConfirmModal = ({ title, content = null, confirmButtonText = "Yes", cancelButtonText = "No", confirmButtonAction, cancelButtonAction }: Props) => {
	const [sendActivation, setSendActivation] = useState(false);

	const handleChange = ({ target: { name, value, type, checked } }) => {
		setSendActivation(checked);
	};

	return (
		<>
			<ModalWrapper>
				<ModalHeader>
					<H2>{title}</H2>
					<ModalClose onClick={cancelButtonAction}>close</ModalClose>
				</ModalHeader>
				{content && (
					<ModalContent>
						<p style={{ marginBottom: "1em" }}>{content}</p>
						<Form>
							<Toggle
								name="sendActivation"
								label="Send activation email to user immediately?"
								value={sendActivation}
								onChange={handleChange}
								note="If the activation is not sent now it can be sent manually from the user record at a later date."
							/>
						</Form>
					</ModalContent>
				)}
				<ModalFooter>
					<ButtonList>
						<StandardCancelAnchor onClick={cancelButtonAction}>{cancelButtonText}</StandardCancelAnchor>
					</ButtonList>
					<ButtonList justify="flex-end">
						<StandardButtonAnchor onClick={() => confirmButtonAction(sendActivation)}>{confirmButtonText}</StandardButtonAnchor>
					</ButtonList>
				</ModalFooter>
			</ModalWrapper>
		</>
	);
};

export default UserAddConfirmModal;
