import io from "socket.io-client";

import { NOTIFICATION_SERVICE, NOTIFICATION_SERVICE_KEY } from "../config/endpoints";
import { INotification, NotificationTypeEnum } from "../state/context/notifications.store";

let socket;

export function initSocket(token, setSocket, setDisconnected, updateNotifications, setNotifications, setNotificationsAll, setRecordUpdate) {
	if (!socket) {
		socket = io(NOTIFICATION_SERVICE as string, {
			reconnectionDelayMax: 5000,
			reconnectionDelay: 1000,
			reconnectionAttempts: 20,
			auth: {
				key: NOTIFICATION_SERVICE_KEY,
				token: token,
			},
		});
	}

	socket.on("connect", () => {
		setDisconnected(false);
		setSocket(socket);
	});

	socket?.on("Notification", (data) => {
		updateNotifications(data);
	});

	socket?.on("Verification", (data) => {
		setRecordUpdate(data);
	});

	socket?.on("NotificationBatch", (data: INotification) => {
		setNotifications(data.data ?? []);
	});

	socket?.on("NotificationAll", (data: INotification) => {
		setNotificationsAll(data.data ?? []);
	});

	socket.on("disconnect", () => {
		setDisconnected(true);
	});
}

export function reconnectSocket() {
	if (socket) {
		socket.connect();
	}
}

export function disconnectSocket() {
	if (socket) {
		socket.disconnect();
		socket = null;
	}
}

export function getBatch(user: string) {
	if (socket) {
		socket.emit(NotificationTypeEnum.notificationBatch, user);
	}
}

export function rehydrate(user: string) {
	if (socket) {
		socket.emit(NotificationTypeEnum.notificationRehydrate, { user });
	}
}

export function markAllNotificationsAsRead(user: string) {
	if (socket) {
		socket.emit(NotificationTypeEnum.notificationMarkAsRead, { user });
	}
}

export function markNotificationsAsClicked(user: string, notificationId) {
	if (socket) {
		socket.emit(NotificationTypeEnum.notificationAsClicked, { user, notificationId });
	}
}

export function markGlobalNotificationsAsRead(user: string) {
	if (socket) {
		socket.emit(NotificationTypeEnum.notificationGlobalMarkAsRead, { user });
	}
}

export default function getSocket() {
	return socket;
}
