import styled from 'styled-components'

const RowStyled = styled.div`
    ${props => props.align && `
        align-items: ${props.align};
    `};
    display: flex;
    flex-direction: ${props => props.reverse ? "row-reverse" : "row"};
    flex-wrap: wrap;
    ${props => props.justify && `
        justify-content: ${props.justify};
    `};
    ${props => props.full && `
        flex: 1;
    `};
    margin: 0 ${props => props.flush ? 0 : "-10px"};
    @media all and (max-width: 1024px) {
        margin: 0 ${props => props.flush ? 0 : "-5px"};
    }
	@media all and (max-width: 768px) {
		${props => props.reverse768 && `
			flex-direction: column-reverse
		`}
	}
`;

export default RowStyled;