import { connect } from "react-redux";
import { LoadingWrapper, Loader, LoadingTextWrapper } from "../../styles/loading";
import { useEffect } from "react";

const Loading = ({ loading }) => {
	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("beforeunload", beforeUnload);
		}

		return () => {
			if (typeof window !== "undefined") {
				window.removeEventListener("beforeunload", beforeUnload);
			}
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const beforeUnload = (e) => {
		// Cancel the event and show alert that
		// the unsaved changes would be lost
		e.preventDefault();
		e.returnValue = "You have unfinished background processes. Please wait until these have finished before navigating away resulting in a potential loss of data.";
	};

	return (
		<LoadingWrapper>
			<Loader />
			{loading && typeof loading === "string" ? (
				<LoadingTextWrapper>
					<p>{loading}</p>
				</LoadingTextWrapper>
			) : null}
		</LoadingWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.toggleLoading.loading,
	};
};

export default connect(mapStateToProps)(Loading);
