import { combineReducers } from "redux";
import { toggleLoading } from "./toggleLoading";
import { setUser } from "./setUser";
import { setAlert } from "./setAlert";
import { toggleNavigation } from "./toggleNavigation";

const appReducers = combineReducers({
	toggleLoading,
	setUser,
	setAlert,
	toggleNavigation,
});

export default appReducers;
