export type FieldValidationType = {
	name: string;
	error: string;
};

export class FieldValidaionModel {
	name?: string;
	error?: string;

	constructor(data: any = {}) {
		this.name = data.name || "";
		this.error = data.error || "";
	}
}
