import { FormErrorStyled } from "../../../styles/forms";
import RequestErrorIcon from "../../icons/requestError";

const FormError = (props) => (
	<FormErrorStyled small={true}>
		<ul>
			{props.errors.map((item, index) => (
				<li key={index}>{item}</li>
			))}
		</ul>
	</FormErrorStyled>
);

export default FormError;
