import styled from 'styled-components'
import theme from '../theme'
import { RelSize, Ratio } from '../mixins'
import { lighten } from 'polished'

export const Anchor = styled.a`
    color: ${theme.black};
    cursor: pointer;
    font-weight: 700;
`;

export const AssistiveText = styled.p`
	color: ${props => props.alt ? theme.backgroundGrey : theme.secondaryGrey};
    font-size: ${RelSize(14, 16)};
	line-height: ${Ratio(14)};
	margin-bottom: ${props => props.flush ? 0 : Ratio(14)};
	margin-top: ${props => props.pushTop ? RelSize(10, 14) : 0};
`;

export const AssistiveList = styled.ul`
	color: ${theme.secondaryGrey};
    font-size: ${RelSize(14, 16)};
	line-height: ${Ratio(14)};
	margin: ${props => props.pushTop ? RelSize(10, 14) : 0} 0 ${Ratio(14)} 0;
	padding: 0 0 0 ${RelSize(20, 14)};
	li {
		margin: 0;
		padding: 0;
	}
`;

export const Cta = styled.a`
	color: ${theme.primaryGreen};
	font-size: ${RelSize(14, 16)};
	line-height: ${Ratio(14)};
	text-align: ${props => props.center ? "center" : "left"};
`;

export const CtaStyled = styled.a`
    border-bottom: 2px solid ${theme.black};
    font-weight: 700;
    line-height: ${Ratio(16)};
    padding-right: ${RelSize(15, 16)};
    position: relative;
    i {
        position: absolute;
        right: 0;
        top: 50%;
        transition: all ease-in 0.2s;
        transform: translate(0, -50%);
    }
    &:hover {
        i {
            transform: translate(-2px, -50%);
        }
    }
    &::after {
        background-color: ${lighten(0.2, theme.black)};
        bottom: -2px;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
    }
`;

export const Strong = styled.span`
	color: ${theme.primaryGreen};
    font-weight: 700;
`;

export const CopyLink = styled.a`
	cursor: pointer;
	padding: 0 ${props => props.help ? "25px" : 0} 0 0;
	position: relative;
	span {
		align-items: center;
		display: flex;
	}
	i {
		height: 20px;
		margin-left: 5px;
		width: 20px;
		svg {
			display: block;
			height: 100%;
		}
	}
`;