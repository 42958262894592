import Axios, { AxiosResponse } from "axios";
import { CustomerInterface } from "../../_types/customer";
import { toggleLoading } from "../../state/creators";

const UNAUTH_KEY = process.env.UNAUTH_KEY;

export const HeadersAuth = (TOKEN) => ({
	"Content-type": "application/json",
	Authorization: TOKEN ? `Bearer ${TOKEN}` : "",
});

export const HeadersAuthBasic = (TOKEN, PRE_AUTH_TOKEN) => ({
	"Content-type": "application/json",
	Authorization: `Basic ${TOKEN}`,
	"Pre-Authorization": `Basic ${PRE_AUTH_TOKEN}`,
});

export const HeadersApiKey = (KEY) => ({
	"Content-type": "application/json",
	"Access-Key": KEY,
});

export const HeadersNoAuth = {
	"Content-type": "application/json",
};

export const HeadersKey = {
	"Content-type": "application/json",
	Authorization: `Bearer ${UNAUTH_KEY}`,
};

export interface IHttp {
	Method: string;
	Url: string;
	Data?: any;
	Headers?: HeadersInit;
	FormData?: boolean;
}

export interface IHttpResponse<D = any> {
	type: number;
	message?: string;
	data?: D;
	total?: number;
	totalActive?: number;
	errors?: any[];
	customer?: CustomerInterface;
}

export const HTTP = ({ Method, Url, Data = null, Headers = {} }: IHttp) => {
	let headers = Headers["Content-type"] ? Headers : { ...Headers, "Content-type": "application/json" };
	return new Promise((resolve, reject) => {
		fetch(Url, {
			method: Method,
			headers,
			body: Data ? JSON.stringify(Data) : null,
		})
			.then(async (res) => {
				const result = await res.json();
				if (res.ok) {
					resolve(result as IHttpResponse);
				} else {
					throw result as IHttpResponse;
				}
			})
			.catch(async (err) => {
				if (err?.data?.logout && typeof window !== "undefined") {
					toggleLoading(false);
					setTimeout(() => {
						window.location.href = "/account/logout";
					}, 10);
				}
				reject(err);
			});
	});
};

export const HTTP_UPLOAD = ({ Method, Url, Data = null, Headers = {} }) => {
	return new Promise((resolve, reject) => {
		Axios({
			method: Method,
			url: Url,
			data: Data,
			headers: Headers,
		})
			.then(async (res) => {
				const result = res.data;
				if (result.type === 200) {
					resolve(result as IHttpResponse);
				}

				throw result as IHttpResponse;
			})
			.catch(async (err) => {
				reject((err?.response?.data as IHttpResponse) ?? err);
			});
	});
};

export const BLOB = ({ Method, Url, Data = null, Headers = {} }: IHttp) => {
	let headers = Headers["Content-type"] ? Headers : { ...Headers, "Content-type": "application/json" };
	return new Promise((resolve, reject) => {
		Axios({
			method: Method,
			responseType: "blob",
			url: Url,
			data: Data,
			headers,
		})
			.then(async (res) => {
				resolve(res as AxiosResponse<Blob>);
			})
			.catch(async (err) => {
				reject((err?.response?.data as IHttpResponse) ? JSON.parse(await err.response.data.text()) : err);
			});
	});
};

export const BUFFER = ({ Method, Url, Data = null, Headers = {} }: IHttp) => {
	let headers = Headers["Content-type"] ? Headers : { ...Headers, "Content-type": "application/json" };
	return new Promise((resolve, reject) => {
		Axios({
			method: Method,
			responseType: "arraybuffer",
			url: Url,
			data: Data,
			headers,
		})
			.then(async (res) => {
				resolve(res as AxiosResponse<Blob>);
			})
			.catch(async (err) => {
				reject(err);
			});
	});
};
