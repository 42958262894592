import { useModalsStore } from "../../../state/context/modal.store";
import { IHelp } from "../../../state/context/modals.reducers";
import { HelpWrapperStyled } from "../../../styles/forms/inputs";
import theme from "../../../styles/theme";

type Props = {
	help: IHelp;
	header?: boolean;
	error?: boolean;
};

const InputHelp = ({ help, header, error }: Props) => {
	const { dispatch } = useModalsStore();

	return (
		<HelpWrapperStyled
			header={header}
			onClick={() => {
				dispatch({
					type: "UPDATE_MODAL",
					payload: help,
				});
			}}
			error={error}
		>
			<i>
				<svg viewBox="0 0 6.31 16.97">
					<g data-name="Layer 2">
						<path
							fill={theme.white}
							d="M5.36 5.52L3.08 14a4.81 4.81 0 0 0-.19 1 .36.36 0 0 0 .1.25.31.31 0 0 0 .21.11.56.56 0 0 0 .38-.18 6.94 6.94 0 0 0 1.2-1.61l.38.24Q3.48 17 1.58 17a1.55 1.55 0 0 1-1.15-.44A1.5 1.5 0 0 1 0 15.42a4.33 4.33 0 0 1 .19-1.13l1.55-5.73A5.2 5.2 0 0 0 2 7.32a.62.62 0 0 0-.21-.47.78.78 0 0 0-.58-.21h-.4l.15-.48 3.74-.64zM4.67 0a1.52 1.52 0 0 1 1.17.52 1.79 1.79 0 0 1 .47 1.24A1.76 1.76 0 0 1 5.83 3a1.54 1.54 0 0 1-2.31 0A1.76 1.76 0 0 1 3 1.76 1.75 1.75 0 0 1 3.52.52 1.48 1.48 0 0 1 4.67 0z"
							data-name="Layer 1"
						/>
					</g>
				</svg>
			</i>
		</HelpWrapperStyled>
	);
};

export default InputHelp;
