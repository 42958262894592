export class ApiResultModel {
	type?: number;
	message?: string;
	data?: any;
	duplicate?: string | string[];

	constructor(data: any = {}) {
		this.type = data.type || 0;
		this.message = data.message || "";
		this.data = data || null;
		if (data.duplicate) this.duplicate = data.duplicate || null;
	}
}
