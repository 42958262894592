import NotificationIcon from "../../styles/icons/notificationIcon";

const CrossIcon = (props) => (
	<NotificationIcon large={props.large}>
		<svg viewBox="0 0 60 60">
			<g fill="none" fillRule="evenodd" stroke={props.color ? props.color : "#FFF"} strokeWidth="3" transform="translate(1 1)">
				<circle cx="29" cy="29" r="29" strokeWidth="2" />
				<path strokeLinecap="square" d="M19.25 40.75l8.763-10.077L39.25 17.75" />
				<path strokeLinecap="square" d="M40.25 38.75l-10.077-8.763L17.25 18.75" />
			</g>
		</svg>
	</NotificationIcon>
);

export default CrossIcon;
