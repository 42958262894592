import { XeroLogoStyled } from "../../styles/icons/logo";

type Props = {
	small?: boolean;
};

const XeroLogo = ({ small }: Props) => (
	<XeroLogoStyled small={small}>
		<svg viewBox="0 0 256 256">
			<path d="M128 256A128 128 0 1 0 0 128a128 128 0 0 0 128 128" fill="#1fc0e7" />
			<path
				d="m62.367 127.968 21.8-21.9a3.906 3.906 0 1 0-5.558-5.49l-21.788 21.803-21.874-21.845a3.912 3.912 0 1 0-5.5 5.569l21.8 21.828-21.784 21.846a3.914 3.914 0 0 0 2.725 6.719 3.867 3.867 0 0 0 2.759-1.138l21.845-21.86 21.76 21.771a3.907 3.907 0 1 0 5.6-5.45l-21.79-21.876Zm129.6-.006a7.1 7.1 0 1 1 .017 0Zm-13.454 0a20.557 20.557 0 1 1 20.56 20.594 20.6 20.6 0 0 1-20.56-20.594Zm-8.09 0a28.692 28.692 0 1 1 0 .006Zm-2.031-28.2H167.2a16.223 16.223 0 0 0-10 3.385 3.961 3.961 0 0 0-3.834-3.072 3.87 3.87 0 0 0-3.868 3.891l.011 48.327a3.9 3.9 0 0 0 7.805-.011v-29.719c0-9.9.91-13.9 9.375-14.962a13.509 13.509 0 0 1 1.65-.08 3.855 3.855 0 0 0 3.959-3.84 3.923 3.923 0 0 0-3.925-3.914Zm-75.015 23.484c0-.114.011-.228.017-.33a20.686 20.686 0 0 1 40.192.313H93.372Zm48.2-.74a28.507 28.507 0 0 0-44.6-17.9 28.7 28.7 0 0 0 13.09 51.905 26.987 26.987 0 0 0 10.3-.683 28.245 28.245 0 0 0 8.567-3.664 30.939 30.939 0 0 0 7.339-6.952 1.484 1.484 0 0 1 .125-.154 4.275 4.275 0 0 0-.438-6.007 3.906 3.906 0 0 0-5.769.887c-.41.592-.87 1.195-1.365 1.792a22.569 22.569 0 0 1-5.655 4.562 20.63 20.63 0 0 1-9.614 2.429 20.642 20.642 0 0 1-20.48-17.044l-.057-.6h40.789c5.586-.125 8.59-4.079 7.76-8.59Z"
				fill="#fff"
			/>
		</svg>
	</XeroLogoStyled>
);

export default XeroLogo;
