export enum ContactRoles {
	HeadTeacher = 1,
	DeputyHead = 2,
	CoverManager = 3,
	HRManager = 4,
	FinanceManager = 5,
	HeadMaths = 6,
	HeadScience = 7,
	HeadEnglish = 8,
	AssistantHeadteacher = 9,
	HeadteachersPA = 10,
	SENCO = 11,
	Other = 12,
	BusinessManager = 13,
	BusinessAdministrator = 14,
	Invoice = 15,
	AcademyRecruitmentContact = 16,
	NurseryManager = 17,
	CEO = 18,
	COO = 19,
	ExecutiveHeadteacher = 20,
	InclusionManager = 21,
	EYFSLead = 22,
	AdminTeam = 23,
	HeadHumanities = 24,
	HeadHistory = 25,
	HeadGeography = 26,
	HeadBusinessStudies = 27,
	HeadPE = 28,
	HeadKS1 = 29,
	HeadKS2 = 30,
	HeadKS3 = 31,
	HeadKS4 = 32,
}
