export enum EmailPreferencesEnum {
	CandidateOnboardingComplete = 1,
	CandidateOnboardingDocumentComplete = 2,
	CandidateOnboardingReferenceComplete = 3,
	CandidateOnboardingBankComplete = 4,
	TimesheetAssignee = 100,
	CandidateAssignee = 101,
	BookingAssignee = 102,
	BookingPlaceholderAssignee = 103,
	TimesheetCandidateStatusUpdate = 200,
	TimesheetClientStatusUpdate = 201,
	TimesheetCandidateQueryUpdate = 202,
	TimesheetClientQueryUpdate = 203,
	DocumentExpiry = 300,
	BookingInterviewAttendanceUpdate = 400,
	BookingTrialAttendanceUpdate = 401,
	PlacementApplication = 500,
	CandidateInterviewConfirmationComplete = 600,
}
