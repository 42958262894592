import React, { useState, useEffect, FormEvent } from "react";
import { FormRow, FormRowInner, Label, ReadOnly, CanEdit, Note } from "../../../styles/forms/inputs";
import InputError from "../errors/input";
import { IsEmail, IsTelephone, IsWebSite } from "../../../functions/validators";
import moment from "moment";
import { EventTargetType } from "../../../_types/form";
import InputHelp from "./help";
import { IHelp } from "../../../state/context/modals.reducers";

type Props = {
	label?: string;
	light?: boolean;
	help?: IHelp;
	placeholder?: string;
	name: string;
	value: string | number | null | undefined;
	readonly?: boolean;
	error?: string;
	inline?: boolean;
	onChange: (e: EventTargetType) => void;
	required?: boolean;
	canEditReadOnly?: boolean;
	note?: string;
	type?: "text" | "email" | "tel" | "number" | "date" | "website";
	min?: number | string;
	max?: number | string;
	maxLength?: number;
	flush?: boolean;
};

const TextInput: React.FC<Props> = ({
	label,
	light,
	help,
	placeholder,
	name,
	value,
	readonly,
	error,
	inline,
	onChange,
	required,
	canEditReadOnly = null,
	note,
	type = "text",
	min,
	max,
	maxLength,
	flush,
}) => {
	let timeout: any = null;

	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);
	const [isReadOnly, setReadOnly] = useState(readonly);

	const handleChange = (e: EventTargetType) => {
		(e as unknown as FormEvent<HTMLInputElement>).persist();
		const target = e.target as HTMLInputElement;
		setInputValue(target.value);
		timeout = setTimeout(() => {
			clearTimeout(timeout);
			handleBlur(e);
		}, 100);
	};

	const handleBlur = (e: EventTargetType) => {
		onChange(e);
	};

	const validateType = (e: EventTargetType) => {
		const target = e.target as HTMLInputElement;
		if (!target.value) {
			handleBlur(e);
			setInputError(null);
		} else if (type === "email") {
			if (IsEmail(target.value)) {
				handleBlur(e);
			} else {
				setInputError("Please enter a valid email address.");
			}
		} else if (type === "tel") {
			if (IsTelephone(target.value)) {
				handleBlur(e);
			} else {
				setInputError("Please enter a valid telephone number.");
			}
		} else if (type === "website") {
			if (IsWebSite(target.value)) {
				handleBlur(e);
			} else {
				setInputError("Please enter a valid website url.");
			}
		} else handleBlur(e);
	};

	useEffect(() => {
		setInputError(error);
		if (value !== inputValue) setInputValue(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, value]);

	useEffect(() => {
		if (readonly !== isReadOnly) setReadOnly(readonly);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readonly]);

	useEffect(() => {
		if (canEditReadOnly !== null && !canEditReadOnly) setReadOnly(true);
	}, [canEditReadOnly]);

	return (
		<FormRow hasError={inputError ? true : false} flush={flush}>
			<FormRowInner type={isReadOnly ? null : type} readonly={isReadOnly}>
				{label && (
					<Label light={light ? true : false} help={help}>
						{label}
						{required ? "*" : null}
						{help ? <InputHelp help={help} /> : null}
					</Label>
				)}
				{note && <Note>{note}</Note>}
				{isReadOnly ? (
					<ReadOnly canEdit={canEditReadOnly}>
						{type === "date" ? moment(inputValue as string).format("DD/MM/YYYY HH:mm") : inputValue}
						{canEditReadOnly ? <CanEdit onClick={() => setReadOnly(!isReadOnly)} /> : null}
					</ReadOnly>
				) : (
					<input
						type={type}
						placeholder={!label ? placeholder || "" : ""}
						name={name}
						value={inputValue}
						onChange={(e) => handleChange(e)}
						onBlur={(e) => validateType(e)}
						readOnly={isReadOnly ? true : false}
						min={min ?? null}
						max={max ?? null}
						maxLength={maxLength ?? null}
					/>
				)}
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default TextInput;
