import styled from 'styled-components'
import theme from '../theme'
import { RelSize } from '../mixins'

export const PaginationItem = styled.a`
	align-items: center;
	background-color: ${theme.primaryGreen};
	border-radius: 50%;
	display: flex;
    height: ${RelSize(25, 14)};
	justify-content: center;
	width: ${RelSize(25, 14)};
`;

export const PaginationStyled = styled.div`
    align-items: center;
	border-bottom: 2px solid ${theme.borderGrey};
	border-top: 2px solid ${theme.borderGrey};
    display: flex;
    justify-content: center;
    margin: ${props => props.active ? RelSize(20, 16) : 0} 0 ${RelSize(50, 16)};
	padding: ${RelSize(10, 16)} 0;
	@media all and (max-width: 768px) {
		flex-wrap: wrap;
	}
    ul {
        align-items: center;
		background-color: ${theme.borderGrey};
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: ${RelSize(5, 16)} ${RelSize(5, 16)};
		${({ hasPrevious }) => !hasPrevious && `
			border-bottom-left-radius: ${RelSize(20, 16)};
			border-top-left-radius: ${RelSize(20, 16)};
		`}
		${({ hasNext }) => !hasNext && `
			border-bottom-right-radius: ${RelSize(20, 16)};
			border-top-right-radius: ${RelSize(20, 16)};
		`}
    }
    li {
        margin: 0;
        padding: 0;
        &:not(:last-child) {
            margin-right: ${RelSize(10, 16)};
        }
		a {
			color: ${theme.primaryGreen};
			display: block;
			font-size: ${RelSize(14, 16)};
			height: ${RelSize(25, 14)};
			padding: ${RelSize(5, 14)};
			text-align: center;
		}
		${PaginationItem} {
			color: ${theme.white};
		}
    }
`;

export const PaginationPrevious = styled.a`
	background-color: ${theme.borderGrey};
	border-bottom-left-radius: ${RelSize(20, 16)};
	border-top-left-radius: ${RelSize(20, 16)};
	border-right: 1px solid ${theme.white};
	color: ${theme.primaryGreen};
    cursor: pointer;
    display: block;
	font-size: ${RelSize(14, 16)};
	line-height: ${RelSize(14, 14)};
	padding: ${RelSize(11, 14)} ${RelSize(10, 14)} ${RelSize(10, 14)} ${RelSize(25, 14)};
	position: relative;
	&::before {
		border-left: 2px solid ${props => props.theme.black};
		border-top: 2px solid ${props => props.theme.black};
		content: "";
		height: ${RelSize(8, 14)};
		left: 15px;
		position: absolute;
		transform: rotate(-45deg) translateY(-50%);
		top: 44%;
		width: ${RelSize(8, 14)};
	}
`;

export const PaginationNext = styled.a`
	background-color: ${theme.borderGrey};
	border-bottom-right-radius: ${RelSize(20, 16)};
	border-top-right-radius: ${RelSize(20, 16)};
	border-left: 1px solid ${theme.white};
    color: ${theme.primaryGreen};
    cursor: pointer;
    display: block;
	font-size: ${RelSize(14, 16)};
	line-height: ${RelSize(14, 14)};
	padding: ${RelSize(11, 14)} ${RelSize(25, 14)} ${RelSize(10, 14)} ${RelSize(10, 14)};
	position: relative;
	&::before {
		border-right: 2px solid ${props => props.theme.black};
		border-top: 2px solid ${props => props.theme.black};
		content: "";
		height: ${RelSize(8, 14)};
		position: absolute;
		right: 15px;
		transform: rotate(45deg) translateY(-50%);
		top: 44%;
		width: ${RelSize(8, 14)};
	}
`;

export const PaginationShowAll = styled.a`
    color: ${theme.primaryGreen};
    cursor: pointer;
    display: block;
	font-size: ${RelSize(10, 14)};
	line-height: 1em;
	margin-left: ${RelSize(10, 10)};
	position: relative;
`;

export const PaginationSelect = styled.div`
	align-items: center;
	display: flex;
	margin-left: ${RelSize(20, 14)};
	@media all and (max-width: 768px) {
		justify-content: center;
		margin: ${RelSize(10, 14)} 0 0;
		width: 100%;
	}
	> span {
		color: ${theme.secondaryGrey};
		font-size: ${RelSize(14, 16)};
		margin-right: ${RelSize(5, 14)};
	}
	ul {
		align-items: center;
		background-color: ${theme.white};
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0;
			&:not(:last-child) {
				margin-right: 0;
			}
			a {
				color: ${theme.secondaryGrey};
				display: block;
				font-size: ${RelSize(14, 16)};
				height: ${RelSize(25, 14)};
				padding: ${RelSize(5, 14)};
				text-align: center;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
`;

export const PaginationSelectAnchor = styled.a`
	color: ${({ active }) => active ? theme.primaryGreen : theme.secondaryGrey};
	text-decoration: ${({ active }) => active ? 'underline' : 'none'};
`;

export const TotalSummaryStyled = styled.div`
    align-items: center;
	border-top: 2px solid ${theme.borderGrey};
    display: flex;
    justify-content: center;
    margin: ${RelSize(10, 16)} 0 0;
	padding: ${RelSize(10, 16)} 0;
    span {
		color: ${theme.primaryGreen};
		font-size: ${RelSize(14, 16)};
		padding: ${RelSize(5, 14)};
	}
`;

export const ResetFiltersStyled = styled.div`
    align-items: center;
	border-top: 2px solid ${theme.borderGrey};
    display: flex;
    justify-content: flex-end;
    margin: ${RelSize(5, 16)} 0 0;
	padding: ${RelSize(5, 16)} 0 0;
    a {
		align-items: center;
		color: ${theme.primaryGreen};
		display: flex;
		font-size: ${RelSize(14, 16)};
		padding: ${RelSize(5, 14)};
		&:hover {
			text-decoration: underline;
		}
		i {
			display: block;
			height: ${RelSize(15, 14)};
			margin-right: ${RelSize(5, 14)};
			width: ${RelSize(15, 14)};
			svg {
				display: block;
				height: 100%;
				width: 100%;
			}
		}
	}
`;