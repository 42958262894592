import moment from "moment";
import { PayType } from "../../_enums/payType.enum";
import { BookingDayInterface } from "../../_types/bookings";
import { round } from "../helpers";

export const BookingUnits = (PAY_TYPE: PayType, DAYS: BookingDayInterface[] = [], BREAKS?: string) => {
	if (PAY_TYPE === PayType.Day) return DAYS.length;

	let units = 0;
	let breakMinutes = 0;
	if (BREAKS) {
		breakMinutes = parseFloat(BREAKS) * 60;
	}
	for (let i = 0, day: BookingDayInterface; (day = DAYS[i++]); ) {
		let startMoment = moment(day.start);
		let endMoment = moment(day.end);

		// Check if end time is before start time (overnight shift)
		if (endMoment.isBefore(startMoment)) {
			endMoment = endMoment.add(1, "day");
		}
		let minutes = endMoment.diff(startMoment, "minutes"); // if the pay type is hourly the minutes will be used to calculate the pay rate
		units += (minutes - breakMinutes) / 60;
	}
	return round(units, 2);
};

export const UnitAmount = (DAY: BookingDayInterface, PAY_TYPE: PayType, CHARGE_RATE: string, BREAKS?: string) => {
	let unitAmount = 0;

	const dcr = DAY.chargeRate ? parseFloat(DAY.chargeRate) : parseFloat(CHARGE_RATE); // day pay rate

	let minutes = moment(DAY.end).diff(moment(DAY.start), "minutes"); // if the pay type is hourly the minutes will be used to calculate the pay rate

	if (BREAKS) {
		const breaks = parseFloat(BREAKS);
		minutes = minutes - breaks * 60;
	}

	!PAY_TYPE || PAY_TYPE == PayType.Day ? (unitAmount = unitAmount + dcr) : (unitAmount = unitAmount + (dcr * minutes) / 60);

	return unitAmount;
};
