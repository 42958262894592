import NotificationIcon from "../../styles/icons/notificationIcon";

const TickIcon = (props) => (
	<NotificationIcon large={props.large}>
		<svg viewBox="0 0 60 60">
			<g fill="none" fillRule="evenodd" stroke={props.color ? props.color : "#FFF"} strokeWidth="3" transform="translate(1 1)">
				<circle cx="29" cy="29" r="29" />
				<path strokeLinecap="square" d="M25 41l8.763-10.077L45 18M25 41l-5.5-4.5L14 32" />
			</g>
		</svg>
	</NotificationIcon>
);

export default TickIcon;
