import { BookingDayInterface, BookingInterface } from "../_types/bookings";
import { ICommission } from "../components/forms/inputs/commission";

export interface BookingCommissionInterface extends BookingInterface {
	bookingProfit: number;
	days: BookingDayCommissionInterface[];
}

interface BookingDayCommissionInterface extends BookingDayInterface {
	dayProfit: number;
}

/**
 * Calculate the commission for a booking in full
 * @param booking The booking to calculate commission for
 * @returns The raw total commission
 */
const calculateTotalCommissionForDays = (booking: BookingCommissionInterface, days: number[], filteredUser?: string): number => {
	// if filtererd user, see if that user is in bookingSplits, if so get split
	// if not in booking Splits then get booking commission and work out split

	let commissionRecords = [];

	if (filteredUser) {
		const matchingBookingSplit = booking.bookingSplits?.find((bookingSplit) => bookingSplit.user === filteredUser);
		if (matchingBookingSplit) {
			commissionRecords = [
				{
					split: 100,
					commission: matchingBookingSplit.commission,
				},
			];
		} else {
			commissionRecords = [
				{
					split: 100,
					commission: booking.commission,
				},
			];
		}
	} else {
		commissionRecords = [
			{ commission: booking.commission, split: booking.bookingSplits?.length ? 100 - booking.bookingSplits.reduce((acc, curr) => acc + parseInt(curr.split as unknown as string), 0) : 100 },
			...(booking.bookingSplits?.map((bookingSplit) => ({ commission: bookingSplit.commission, split: bookingSplit.split })) ?? []),
		];
	}

	// let commissionRecords: any = filteredUser ?
	// 	filteredUser === (booking.assignee as unknown as string) ? [booking.commission] : [(booking.bookingSplits.find((bookingSplit) => bookingSplit.user === filteredUser)?.commission as ICommission[])]
	// 	: [{ commission: booking.commission, split: booking.bookingSplits?.length ? 100 - booking.bookingSplits.reduce((acc, curr) => acc + parseInt(curr.split as unknown as string), 0) : 100 }, ...booking.bookingSplits?.map((bookingSplit) => ({ commission: bookingSplit.commission, split: bookingSplit.split })) ?? []];

	let returnedCommissionValue = 0;
	for (const split of commissionRecords) {
		const commission = split.commission;
		const daysTotalProfit = booking.days.filter((_d, i) => days.includes(i)).reduce((accumulator, { dayProfit }) => accumulator + (split.split / 100) * dayProfit, 0);
		if (booking.commissionType === 1) {
			let totalCommission = 0;
			const sortedCommissionsReversed = commission.sort((a, b) => (b.min as number) - (a.min as number));

			innerloop: for (let t = 0, threshold; (threshold = sortedCommissionsReversed[t++]); ) {
				const removal = sortedCommissionsReversed[sortedCommissionsReversed.length - 1];

				const min = threshold.min;
				if (daysTotalProfit >= min) {
					totalCommission += (daysTotalProfit - (removal?.min as number) ?? 0) * (threshold.commission / 100);
					returnedCommissionValue += totalCommission;
					break innerloop;
				}
			}
		} else if (booking.commissionType === 2) {
			let totalCommission = 0;

			const sortedCommissions = commission.sort((a, b) => (a.min as number) - (b.min as number));

			innerloop: for (let i = 0; i < sortedCommissions.length; i++) {
				const tier = sortedCommissions[i];
				const nextTier = sortedCommissions[i + 1] ?? null;
				if ((tier.min as number) > daysTotalProfit) {
					break innerloop;
				}

				const applicableProfit = nextTier === null ? daysTotalProfit - (tier.min as number) : Math.min(daysTotalProfit - (tier.min as number), (nextTier.min as number) - (tier.min as number));

				totalCommission += applicableProfit * ((tier.commission as number) / 100);
			}

			returnedCommissionValue += totalCommission;
		}
	}

	return returnedCommissionValue;
};

/**
 * Calculate the weights for booking days based on 'dayProfit'
 * @param booking The booking to calculate day weights for
 * @returns array of weights
 */
const calculateDayWeightsForBooking = (booking: BookingCommissionInterface, days: number[]): number[] => {
	const filteredDays = booking.days.filter((_d, i) => days.includes(i));

	const daysTotalProfit = filteredDays.reduce((accumulator, { dayProfit }) => accumulator + dayProfit, 0);

	const baseProfitPerDay = booking.bookingProfit !== 0 ? daysTotalProfit / days.length : 0;

	const weights = filteredDays.map((day) => {
		// If baseProfitPerDay is 0, return 0 to avoid division by 0.
		if (baseProfitPerDay === 0) {
			return 0;
		}

		// Otherwise, return the calculated weight
		return day.dayProfit / baseProfitPerDay;
	});

	return weights;
};

/**
 * Calculate the indexes of days within a booking which fall between a start and end date (inclusive)
 * @param booking The booking which contains the days array
 * @param startDateRange The start date for the range (inclusive)
 * @param endDateRange The end date for the range (inclusive)
 * @returns Indexes of days which are within the given date range
 */
const calculateDayIndexesInDayRange = (booking: BookingCommissionInterface, startDateRange: Date, endDateRange: Date): number[] => {
	const indexes = booking.days.reduce<Array<number>>((accumulator, { start, end }, currentIndex) => {
		const startDate = new Date(start);
		const endDate = new Date(end);

		const isWithinRange = startDate >= startDateRange && startDate <= endDateRange && endDate >= startDateRange && endDate <= endDateRange;

		if (isWithinRange) {
			accumulator.push(currentIndex);
		}

		return accumulator;
	}, []);
	return indexes;
};

/**
 * Split booking days into chunks based on the commissionWeekly variable
 * @param booking The booking which contains the days to be chunked
 * @returns A 2D array of day indexes split into chunks
 */
const calculateDayChunks = (booking: BookingCommissionInterface): number[][] => {
	const chunks = booking.days.reduce<Record<string, number[]>>((accumulator, day, currentIndex) => {
		const dayDate = new Date(day.start);

		const key = (
			booking.commissionWeekly ? new Date(dayDate.getFullYear(), dayDate.getMonth(), dayDate.getDate() - dayDate.getDay() + 1) : new Date(dayDate.getFullYear(), dayDate.getMonth(), 1)
		).toISOString();

		if (accumulator.hasOwnProperty(key)) {
			accumulator[key].push(currentIndex);
		} else {
			accumulator[key] = [currentIndex];
		}

		return accumulator;
	}, {});

	return Object.values(chunks);
};

/**
 * The structure returned when calculating weighted commissions
 * dayIndex is not actually used so this could just be a number
 * however we already have it and could be useful in the future
 * such as providing a day calculation breakdown if ever needed
 */
type DayCalculations = {
	dayIndex: number;
	weightedCommission: number;
};

/**
 * Calculate the weight adjusted commission for eacy day in the date range (inclusive)
 * @param booking The booking to calculate weighted commissions for
 * @param startDate The start date for the range (inclusive)
 * @param endDate The end date for the range (inclusive)
 * @returns array of DayCalculations
 */
const calculateWeightAdjustedCommissionForDaysInRangeForBooking = (booking: BookingCommissionInterface, startDate: Date, endDate: Date, filteredUser?: string): Array<DayCalculations> => {
	const dayChunks = calculateDayChunks(booking);

	const result = dayChunks.reduce<Array<DayCalculations>>((accumulator, chunkDayIndexes) => {
		const totalCommissionForChunk = calculateTotalCommissionForDays(booking, chunkDayIndexes, filteredUser);

		const dayWeights = calculateDayWeightsForBooking(booking, chunkDayIndexes);

		const dayIndexesInDayRange = calculateDayIndexesInDayRange(booking, startDate, endDate);

		const baseCommissionPerDay = totalCommissionForChunk / chunkDayIndexes.length;

		const result = booking.days
			.map((day, index) => ({ day, index }))
			.filter((_d, i) => chunkDayIndexes.includes(i))
			.reduce<Array<DayCalculations>>((accumulator, { index: dayIndex }, currentIndex) => {
				if (!dayIndexesInDayRange.includes(dayIndex)) {
					return accumulator;
				}

				const weightedCommission = baseCommissionPerDay * dayWeights[currentIndex];

				accumulator.push({
					dayIndex,
					weightedCommission,
				});

				return accumulator;
			}, []);

		accumulator.push(...result);

		return accumulator;
	}, []);

	return result;
};

/**
 * Calculates the representative comission for bookings between two dates
 * @param bookings The bookings that comission should be calculated for
 * @param startDate The start date for comission calculations
 * @param endDate The end date for comission calculations
 */
export const calculateRepresentativeCommissionValue = (bookings: BookingCommissionInterface[], startDate: Date, endDate: Date, filteredUser?: string): number => {
	const start = new Date(startDate).setUTCHours(0, 0, 0, 0);
	const end = new Date(endDate).setUTCHours(23, 59, 59, 999);

	try {
		const totalCommission = bookings.reduce<number>((accumulator, booking) => {
			const weightAdjustedComissionForDaysInDayRange = calculateWeightAdjustedCommissionForDaysInRangeForBooking(booking, new Date(start), new Date(end), filteredUser);

			const totalForBooking = weightAdjustedComissionForDaysInDayRange.reduce<number>((accumulator, dayCalculations: DayCalculations) => accumulator + dayCalculations.weightedCommission, 0);

			return accumulator + totalForBooking;
		}, 0);

		return totalCommission;
	} catch (error) {
		return 0;
	}
};
