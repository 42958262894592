import React, { useState, useEffect } from "react";
import { FormRow, FormRowInner, Label, ToggleWrapper, ToggleItem, ReadOnly, CanEdit, Note } from "../../../styles/forms/inputs";
import InputError from "../errors/input";
import { EventTargetType } from "../../../_types/form";
import InputHelp from "./help";
import { IHelp } from "../../../state/context/modals.reducers";

type Props = {
	label?: string;
	light?: boolean;
	help?: IHelp;
	name: string;
	value: boolean;
	readonly?: boolean;
	error?: string;
	inline?: boolean;
	onChange: (e: EventTargetType) => void;
	required?: boolean;
	canEditReadOnly?: boolean;
	note?: string | JSX.Element;
	trueText?: string;
	falseText?: string;
};

const ToggleInput: React.FC<Props> = ({ label, light, help, name, value, readonly, error, inline, onChange, required, canEditReadOnly, note, trueText, falseText }) => {
	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);
	const [isReadOnly, setReadOnly] = useState(readonly);

	const handleChange = (e) => {
		const checkedValue = e.target.id === `${name}-True` ? true : false;
		setInputValue(checkedValue);
		onChange({
			target: {
				name,
				checked: checkedValue,
				type: "checkbox",
			},
		});
	};

	useEffect(() => {
		setInputError(error);
		if (value !== inputValue) setInputValue(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, value]);

	useEffect(() => {
		setReadOnly((prevValue) => {
			if (prevValue !== readonly) return readonly;
			return prevValue;
		});
	}, [readonly]);

	useEffect(() => {
		if (canEditReadOnly !== null && canEditReadOnly !== undefined && !canEditReadOnly) setReadOnly(true);
	}, [canEditReadOnly]);

	return (
		<FormRow>
			<FormRowInner>
				{label && (
					<Label light={light ? true : false} help={help}>
						{label}
						{required ? "*" : null}
						{help ? <InputHelp help={help} /> : null}
					</Label>
				)}
				{note && <Note>{note}</Note>}
				{isReadOnly ? (
					<ReadOnly canEdit={canEditReadOnly} toggle>
						<ToggleWrapper readOnly={true}>
							<ToggleItem>
								<input type="radio" name={name} value="True" id={`${name}-True`} checked={inputValue ? true : false} disabled={true} />
								<label htmlFor={`${name}-True`}>{trueText || "Yes"}</label>
							</ToggleItem>
							<ToggleItem>
								<input type="radio" name={name} value="False" id={`${name}-False`} checked={inputValue !== null ? (!inputValue ? true : false) : false} disabled={true} />
								<label htmlFor={`${name}-False`}>{falseText || "No"}</label>
							</ToggleItem>
						</ToggleWrapper>
						{canEditReadOnly ? <CanEdit onClick={() => setReadOnly(!isReadOnly)} /> : null}
					</ReadOnly>
				) : (
					<ToggleWrapper>
						<ToggleItem>
							<input type="radio" name={name} value="True" id={`${name}-True`} checked={inputValue ? true : false} onChange={handleChange} disabled={isReadOnly ? true : false} />
							<label htmlFor={`${name}-True`}>{trueText || "Yes"}</label>
						</ToggleItem>
						<ToggleItem>
							<input
								type="radio"
								name={name}
								value="False"
								id={`${name}-False`}
								checked={inputValue !== null ? (!inputValue ? true : false) : false}
								onChange={handleChange}
								disabled={isReadOnly ? true : false}
							/>
							<label htmlFor={`${name}-False`}>{falseText || "No"}</label>
						</ToggleItem>
					</ToggleWrapper>
				)}
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default ToggleInput;
