export class DefaultPaginationModel {
	pageNo: number;
	pageSize: number;
	showAll: boolean;
	resultItems: any;
	paginationItems: any;

	constructor(data: any = {}) {
		this.pageNo = data.pageNo || 1;
		this.pageSize = data.pageSize || 10;
		this.showAll = data.showAll || false;
		this.resultItems = data.resultItems || [];
		this.paginationItems = data.paginationItems || [];
	}
}
