import { Dispatch } from "react";

// Global state type
export interface GlobalModalsState {
	modal: {
		modalContent: JSX.Element;
		modalTitle: string;
		external?: boolean;
	};
	dispatch?: Dispatch<Action>;
}

export interface IHelp {
	modalContent: JSX.Element;
	modalTitle: string;
	external?: boolean;
}

// Reducer action type
type Action = {
	type: string;
	payload?: unknown;
};

// Global context type
export type Context = {
	state: GlobalModalsState;
	dispatch: Dispatch<Action>;
};

// Initial state type
export const initialModalsState: GlobalModalsState = {
	modal: null,
};

export const UPDATE_MODAL = "UPDATE_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

// Store reducer for handling various methods
export const modalsStoreReducer = (state = initialModalsState, action: Action): any => {
	switch (action.type) {
		case UPDATE_MODAL:
			return {
				...state,
				...{
					modal: action.payload,
				},
			};
		case CLOSE_MODAL:
			return {
				...state,
				...{
					modal: null,
				},
			};
		default:
			return state;
	}
};
