import * as React from "react";
import { connect } from "react-redux";
import { toggleLoading } from "../../state/creators";
import Modal from "react-modal";
import { ThemeProvider } from "styled-components";
import Alert from "../alert";
import Loading from "../loading";
import theme from "../../styles/theme";
import { GlobalStyle } from "../../styles/global";
import { useModalsStore } from "../../state/context/modal.store";
import { ModalTopStyles } from "../../styles/modals";

type Props = {
	loading: boolean | string;
	toggleLoading: (loading: boolean) => void;
	children: JSX.Element | JSX.Element[];
};

const Page = ({ loading, toggleLoading, children }: Props) => {
	const { modal, dispatch } = useModalsStore();

	React.useEffect(() => {
		toggleLoading(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyle />
				{children}
				{loading && (
					<div>
						<Loading />
					</div>
				)}
				<Modal
					isOpen={modal ? true : false}
					onRequestClose={() => dispatch({ type: "CLOSE_MODAL" })}
					style={ModalTopStyles}
					contentLabel={modal?.modalTitle}
					shouldCloseOnOverlayClick={true}
					ariaHideApp={false}
					parentSelector={() => document.querySelector("#helpModalParent")}
				>
					{modal?.modalContent}
				</Modal>
				<Alert />
			</>
		</ThemeProvider>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.toggleLoading.loading,
	};
};

const mapDispatchToProps = {
	toggleLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
