export enum LocationTypes {
	AllLocations = "All Locations",
	AllLocationsMinusLondon = "All Locations minus London",
	AylesburyVale = "Aylesbury Vale",
	BarkingAndDagenham = "Barking and Dagenham",
	Barnet = "Barnet",
	BasedOverseas = "Based Overseas",
	BasedOverseasCanada = "Based Overseas - Canada",
	BasedOverseasIreland = "Based Overseas - Ireland",
	BasedOverseasJamaica = "Based Overseas - Jamaica",
	BasedOverseasNorthernIreland = "Based Overseas - Northern Ireland",
	BasedOverseasOther = "Based Overseas - Other",
	BasedOverseasScotland = "Based Overseas - Scotland",
	BasedOverseasSouthAfrica = "Based Overseas - South Africa",
	BasedOverseasUSA = "Based Overseas - USA",
	BasedOverseasWales = "Based Overseas - Wales",
	BasedOverseasZimbabwe = "Based Overseas - Zimbabwe",
	BasedOverseasAustralia = "Based Overseas-Australia",
	BathAndNorthEastSomerset = "Bath & North East Somerset",
	Beaconsfiled = "Beaconsfiled",
	Bedfordshire = "Bedfordshire",
	Berkshire = "Berkshire",
	BerkshireCentral = "Berkshire Central",
	BerkshireEast = "Berkshire East",
	BerkshireWest = "Berkshire West",
	Bexley = "Bexley",
	Birmingham = "Birmingham",
	Brent = "Brent",
	BrightonAndHove = "Brighton and Hove",
	BristolCityOf = "Bristol City of",
	Bromley = "Bromley",
	Buckingham = "Buckingham",
	Buckinghamshire = "Buckinghamshire",
	Cambridgeshire = "Cambridgeshire",
	CambridgeshireSouth = "Cambridgeshire South",
	Cambridge = "Cambridge",
	EastCambridge = "East Cambridge",
	Camden = "Camden",
	Chesham = "Chesham",
	CheshireEast = "Cheshire East",
	Corby = "Corby",
	CentralBeds = "Central Beds",
	SouthBeds = "South Beds",
	Cornwall = "Cornwall",
	Coventry = "Coventry",
	Croydon = "Croydon",
	Cumbria = "Cumbria",
	Daventry = "Daventry",
	Derbyshire = "Derbyshire",
	Devon = "Devon",
	Dorset = "Dorset",
	DorsetNorth = "Dorset North",
	DorsetSouth = "Dorset South",
	DorsetSouthEast = "Dorset South East",
	Dudley = "Dudley",
	Durham = "Durham",
	Ealing = "Ealing",
	EastLondon = "East London",
	EastOxfordshire = "East Oxfordshire",
	EastRidingOfYorkshire = "East Riding of Yorkshire",
	EastSussex = "East Sussex",
	Enfield = "Enfield",
	Essex = "Essex",
	EssexCentral = "Essex Central",
	EssexEast = "Essex East",
	EssexNorth = "Essex North",
	EssexSouth = "Essex South",
	EssexWest = "Essex West",
	Gloucestershire = "Gloucestershire",
	GreaterLondon = "Greater London",
	GreaterManchester = "Greater Manchester",
	Greenwich = "Greenwich",
	Hackney = "Hackney",
	HammersmithAndFulham = "Hammersmith and Fulham",
	Hampshire = "Hampshire",
	HampshireIsleOfWight = "Hampshire - Isle of Wight",
	HampshireNorth = "Hampshire North",
	HampshireSouthEast = "Hampshire South East",
	HampshireSouthWest = "Hampshire South West",
	Haringey = "Haringey",
	Harrow = "Harrow",
	Havering = "Havering",
	Herefordshire = "Herefordshire",
	Hertfordshire = "Hertfordshire",
	HertsBishopStortford = "Herts Bishop Stortford",
	HertsCentral = "Herts Central",
	HertsNorth = "Herts North",
	HertsRoyston = "Herts Royston",
	HertsWest = "Herts West",
	HighWycombe = "High Wycombe",
	Hillingdon = "Hillingdon",
	Hounslow = "Hounslow",
	InnerLondon = "Inner London",
	Islington = "Islington",
	KensingtonAndChelsea = "Kensington and Chelsea",
	Kent = "Kent",
	KentEast = "Kent East",
	KentSouthEast = "Kent South East",
	KentWest = "Kent West",
	KentCentral = "Kent Central",
	KentNorth = "Kent North",
	Kettering = "Kettering",
	KingstonUponThames = "Kingston upon Thames",
	Lambeth = "Lambeth",
	Lancashire = "Lancashire",
	Leeds = "Leeds",
	Leicester = "Leicester",
	Leicestershire = "Leicestershire",
	Lewisham = "Lewisham",
	Lincolnshire = "Lincolnshire",
	Merseyside = "Merseyside",
	Merton = "Merton",
	MiltonKeynes = "Milton Keynes",
	NewcastleUponTyne = "Newcastle upon Tyne",
	Newham = "Newham",
	Norfolk = "Norfolk",
	NorfolkEast = "Norfolk East",
	NorfolkNorth = "Norfolk North",
	NorfolkSouth = "Norfolk South",
	NorfolkWest = "Norfolk West",
	NorthEastLincolnshire = "North East Lincolnshire",
	NorthLincolnshire = "North Lincolnshire",
	Luton = "Luton",
	NorthLondon = "North London",
	NorthOxfordshire = "North Oxfordshire",
	NorthSomerset = "North Somerset",
	NorthYorkshire = "North Yorkshire",
	Northampton = "Northampton",
	Northamptonshire = "Northamptonshire",
	Northumberland = "Northumberland",
	Nottinghamshire = "Nottinghamshire",
	NWLondon = "NW London",
	OuterLondon = "Outer London",
	Overseas = "Overseas",
	OverseasUAE = "Overseas UAE",
	Oxford = "Oxford",
	Oxfordshire = "Oxfordshire",
	Peterborough = "Peterborough",
	PrincesRisborough = "Princes Risborough",
	Redbridge = "Redbridge",
	RichmondUponThames = "Richmond upon Thames",
	SEHerts = "SE Herts",
	SELondon = "SE London",
	Shropshire = "Shropshire",
	Slough = "Slough",
	Solihull = "Solihull",
	Somerset = "Somerset",
	SouthGloucestershire = "South Gloucestershire",
	SouthOxfordshire = "South Oxfordshire",
	Bristol = "Bristol",
	SouthYorkshire = "South Yorkshire",
	Southwark = "Southwark",
	Staffordshire = "Staffordshire",
	Suffolk = "Suffolk",
	Surrey = "Surrey",
	SurreyCentral = "Surrey Central",
	SurreyNorth = "Surrey North",
	SurreySouthEast = "Surrey South East",
	SurreySouthWest = "Surrey South West",
	Sutton = "Sutton",
	SWLondon = "SW London",
	Thurrock = "Thurrock",
	TowerHamlets = "Tower Hamlets",
	TyneAndWear = "Tyne and Wear",
	Wales = "Wales",
	WalthamForest = "Waltham Forest",
	Wandsworth = "Wandsworth",
	Warwickshire = "Warwickshire",
	Wellingborough = "Wellingborough",
	West = "West",
	WestLondon = "West London",
	WestMidlands = "West Midlands",
	WestOxfordshire = "West Oxfordshire",
	WestSussex = "West Sussex",
	WestYorkshire = "West Yorkshire",
	Westminster = "Westminster",
	Wiltshire = "Wiltshire",
	WiltshireNorth = "Wiltshire North",
	WiltshireSouth = "Wiltshire South",
	WiltshireWest = "Wiltshire West",
	BedfordBorough = "Bedford Borough",
	Huntingdonshire = "Huntingdonshire",
	Fenlands = "Fenlands",
	Wolverhampton = "Wolverhampton",
	OverseasCentralAndEasternAsiaAll = "Overseas – Central & Eastern Asia (all)",
	OverseasCentralAndEasternAsiaChina = "Overseas – Central & Eastern Asia - China",
	OverseasCentralAndEasternAsiaJapan = "Overseas – Central & Eastern Asia - Japan",
	OverseasCentralAndNorthernEuropeAll = "Overseas – Central & Northern Europe (all)",
	OverseasCentralAndNorthernEuropeDenmark = "Overseas – Central & Northern Europe - Denmark",
	OverseasCentralAndNorthernEuropeFrance = "Overseas – Central & Northern Europe - France",
	OverseasCentralAndNorthernEuropeGermany = "Overseas – Central & Northern Europe Germany",
	OverseasCentralAndNorthernEuropeNetherlands = "Overseas – Central & Northern Europe - Netherlands",
	OverseasEasternEuropeAll = "Overseas – Eastern Europe (all)",
	OverseasEasternEuropeCzechRepublic = "Overseas – Eastern Europe - Czech Republic",
	OverseasEasternEuropePoland = "Overseas – Eastern Europe - Poland",
	OverseasMiddleEastAll = "Overseas – Middle East (all)",
	OverseasMiddleEastOman = "Overseas – Middle East - Oman",
	OverseasMiddleEastQatar = "Overseas – Middle East - Qatar",
	OverseasMiddleEastSaudiArabia = "Overseas – Middle East - Saudi Arabia",
	OverseasMiddleEastUnitedArabEmirates = "Overseas – Middle East - United Arab Emirates",
	OverseasSouthEastAsiaAll = "Overseas – South East Asia (all)",
	OverseasSouthEastAsiaSingapore = "Overseas – South East Asia - Singapore",
	OverseasSouthEastAsiaThailand = "Overseas – South East Asia - Thailand",
	OverseasSouthernEuropeAll = "Overseas – Southern Europe (all)",
	OverseasSouthernEuropePortugal = "Overseas – Southern Europe - Portugal",
	OverseasSouthernEuropeItaly = "Overseas – Southern Europe - Italy",
	OverseasSouthernEuropeSpain = "Overseas – Southern Europe - Spain",
	OverseasAllLocations = "Overseas- All Locations",
	OverseasGhana = "Overseas – Ghana",
	OverseasNotLookingOverseas = "Overseas- NOT LOOKING OVERSEAS",
}
