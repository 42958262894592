import { TOGGLE_NAVIGATION } from "../actions";

const initialState = {
	navigationHidden: false,
};

export function toggleNavigation(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_NAVIGATION:
			return Object.assign({}, state, {
				navigationHidden: action.navigationHidden,
			});
		default:
			return state;
	}
}
