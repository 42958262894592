import { SET_ALERT } from "../actions";

const initialState = {
	alert: null,
};

export type AlertType = {
	type?: "success" | "error" | "warning";
	message: string[] | string;
	central?: boolean;
};

export function setAlert(state = initialState, action) {
	switch (action.type) {
		case SET_ALERT:
			return Object.assign({}, state, {
				alert: action.alert,
			});
		default:
			return state;
	}
}
