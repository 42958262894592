import React, { useState, useEffect, useRef, Fragment } from "react";
import { FormRow, FormRowInner, Label, ReadOnly, Note } from "../../../styles/forms/inputs";
import { EventTargetType } from "../../../_types/form";
import { IHelp } from "../../../state/context/modals.reducers";
import InputHelp from "./help";
import { LineItem, LineItemCol, LineItemsWrapper } from "../../../styles/invoices";
import RowStyled from "../../../styles/layout/row";
import ColStyled from "../../../styles/layout/col";
import { StandardButtonAnchor, ButtonList } from "../../../styles/type/buttons";
import ButtonIcon from "../../icons/buttons";
import TextInput from "./text";
import HelpModal from "../../modals/help.modal";
import {
	CommissionThresholdStartsAtHelpText,
	CommissionThresholdCommissionHelpText,
	CommissionThresholdCommissionFixedVariableText,
	CommissionThresholdCommissionWeeklyMonthlyText,
} from "../../../config/helpText";
import { ValidateCommission } from "../../../functions/validators";
import FormError from "../errors/http";
import { CommissionTypeEnum } from "../../../_enums/commissionType.enum";
import ToggleSwitchInput from "./switchToggle";
import { Warning } from "../errors/input";

export interface ICommission {
	min: number | string;
	commission: number | string;
	type?: CommissionTypeEnum | null;
	weekly?: boolean;
}

export interface ICommissionErrors {
	minError?: string;
	commissionError?: string;
}

type Props = {
	label?: string;
	light?: boolean;
	help?: IHelp;
	placeholder?: string;
	name: string;
	value: ICommission[];
	readonly?: boolean;
	error?: string;
	onChange: (e: EventTargetType) => void;
	required?: boolean;
	note?: string;
	showCommissionType?: boolean;
	setCommissionType?: (commissionType: CommissionTypeEnum) => void;
};

const checkOverlap = (commission: ICommission, inputValue: ICommission[]) => {
	const min = commission.min ? parseFloat(commission.min as string) : 0;

	if (inputValue.find((item) => parseFloat(item.min as string) === min)) return true;

	return false;
};

const commissionOverviewString = (commission: ICommission) => {
	return commission.type === CommissionTypeEnum.Fixed
		? `If profit reaches £${commission.min} the commission paid will be ${commission.commission}% on all profit.`
		: `Starting at £${commission.min} the commission paid will be ${commission.commission}%`;
};

const CommissionInput: React.FC<Props> = ({
	label,
	light,
	help,
	placeholder,
	name,
	value,
	readonly,
	error,
	onChange,
	required,
	note,
	showCommissionType,
	setCommissionType: setCommissionTypeParent,
}) => {
	const [inputValue, setInputValue] = useState(value);
	const [commission, setCommission] = useState<ICommission>({ min: "", commission: "", type: showCommissionType ? CommissionTypeEnum.Fixed : null } as ICommission);
	const [errors, setErrors] = useState<ICommissionErrors>({});
	const [inputError, setInputError] = useState<JSX.Element>(null);
	const [isReadOnly, setReadOnly] = useState(readonly);
	const [commissionType, setCommissionType] = useState<CommissionTypeEnum>(showCommissionType ? (value && value.length ? value[0].type : CommissionTypeEnum.Fixed) : null);
	const [weekly, setWeekly] = useState<boolean>(value && value.length ? value[0].weekly : false);

	const handleChange = (e: EventTargetType) => {
		setCommission((commission) => ({ ...commission, [e.target.name]: e.target.value }));
	};

	useEffect(() => {
		setInputError(error ? <FormError errors={[error]} /> : null);
		if (value !== inputValue) setInputValue(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, value]);

	useEffect(() => {
		if (readonly !== isReadOnly) setReadOnly(readonly);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readonly]);

	const addCommissionRange = () => {
		setInputError(null);
		setErrors({});

		const ValidateStep = ValidateCommission(commission);
		if (ValidateStep.valid) {
			if (!checkOverlap(commission, inputValue)) {
				onChange({
					target: {
						name: name,
						value: [...inputValue, ...[{ min: parseFloat(commission.min as string), commission: parseFloat(commission.commission as string), type: commissionType }]],
					},
				});
				setCommission({ min: "", commission: "", type: CommissionTypeEnum.Fixed } as ICommission);
			} else {
				setInputError(<FormError errors={["The commission range you are trying to add overlaps with another commission range. Please amend your commission range and try again."]} />);
			}
		} else {
			for (let i = 0, item; !!(item = ValidateStep.errors[i++]); ) {
				setErrors((prevState) => ({
					...prevState,
					[item.name]: item.error,
				}));
			}
			setInputError(<FormError errors={["There were one or more errors validating your submission. The fields have been highlighted above."]} />);
		}
	};

	const removeCommissionRange = (index: number) => {
		const newValue = [...inputValue];
		newValue.splice(index, 1);
		onChange({
			target: {
				name: name,
				value: newValue,
			},
		});
	};

	return (
		<FormRow hasError={inputError ? true : false}>
			<FormRowInner>
				{label && (
					<Label light={light ? true : false} help={help}>
						{label}
						{required ? "*" : null}
						{help ? <InputHelp help={help} /> : null}
					</Label>
				)}
				{note && <Note>{note}</Note>}
				{isReadOnly ? (
					<ReadOnly>{inputValue}</ReadOnly>
				) : (
					<LineItemsWrapper>
						{!value.length ? (
							<LineItem>
								<Note>
									No commission threshold ranges have been added.{" "}
									{showCommissionType ? "" : "The commission threshold for this user will default to that set at the company level for newly created bookings."}
								</Note>
							</LineItem>
						) : (
							value
								.sort((a, b) => (a.min as number) - (b.min as number))
								.map((commission, index) => (
									<Fragment key={index}>
										<LineItem flush={true}>
											<RowStyled>
												<ColStyled span={4}>
													<LineItemCol>
														<span>Min</span>£{commission.min}
													</LineItemCol>
												</ColStyled>
												<ColStyled span={3}>
													<LineItemCol>
														<span>Commission</span>
														{commission.commission}%
													</LineItemCol>
												</ColStyled>
												<ColStyled span={4}>
													<LineItemCol align="right">
														<span>&nbsp;</span>
														<span>
															<a onClick={() => removeCommissionRange(index)}>Remove</a>
														</span>
													</LineItemCol>
												</ColStyled>
											</RowStyled>
										</LineItem>
										<LineItem>
											<LineItemCol>
												<span>&nbsp;</span>
												<span>{commissionOverviewString(commission)}</span>
											</LineItemCol>
										</LineItem>
									</Fragment>
								))
						)}
						<LineItem>
							<RowStyled>
								<ColStyled span={4}>
									<LineItemCol>
										<TextInput
											name="min"
											value={commission.min}
											onChange={handleChange}
											label="Commission Threshold (£)*"
											help={{
												modalTitle: "Commission % starts at",
												modalContent: <HelpModal title="Commission % end at" content={CommissionThresholdStartsAtHelpText} />,
											}}
											type="number"
											error={errors?.minError}
											min={0}
											required
										/>
									</LineItemCol>
								</ColStyled>
								<ColStyled span={3}>
									<LineItemCol>
										<TextInput
											name="commission"
											value={commission.commission}
											onChange={handleChange}
											label="Commission %"
											help={{
												modalTitle: "Commission %",
												modalContent: <HelpModal title="Commission %" content={CommissionThresholdCommissionHelpText} />,
											}}
											type="number"
											error={errors?.commissionError}
											max={100}
											required
										/>
									</LineItemCol>
								</ColStyled>
								<ColStyled span={4}>
									<LineItemCol>
										<Label>&nbsp;</Label>
										<ButtonList>
											<StandardButtonAnchor onClick={addCommissionRange}>
												<ButtonIcon type="add" />
												Add Commission Threshold Range
											</StandardButtonAnchor>
										</ButtonList>
									</LineItemCol>
								</ColStyled>
							</RowStyled>
						</LineItem>
						{showCommissionType ? (
							<>
								<LineItem>
									<ToggleSwitchInput
										label="Weekly / Monthly Commission Thresholds"
										name="weekly"
										value={weekly}
										onChange={(e) => {
											setWeekly(e.target.value as boolean);
											onChange({
												target: {
													name: name,
													value: inputValue.map((commission) => ({ ...commission, weekly: e.target.value })),
												},
											});
										}}
										trueLabel="Monthly"
										falseLabel="Weekly"
										note="Is commission calculated per week or per month?"
										help={{
											modalTitle: "Fixed / Variable Commission",
											modalContent: <HelpModal title="Weekly / Monthly Commission Thresholds" content={CommissionThresholdCommissionWeeklyMonthlyText} />,
										}}
									/>
								</LineItem>
								<LineItem>
									<ToggleSwitchInput
										label="Fixed / Variable Commission"
										name="groupByClient"
										value={commissionType === CommissionTypeEnum.Fixed ? false : true}
										onChange={(e) => {
											setCommissionType(e.target.value ? CommissionTypeEnum.Variable : CommissionTypeEnum.Fixed);
											setCommissionTypeParent(e.target.value ? CommissionTypeEnum.Variable : CommissionTypeEnum.Fixed);
											onChange({
												target: {
													name: name,
													value: inputValue.map((commission) => ({ ...commission, type: e.target.value ? CommissionTypeEnum.Variable : CommissionTypeEnum.Fixed })),
												},
											});
										}}
										trueLabel="Fixed commission"
										falseLabel="Variable commission"
										help={{
											modalTitle: "Fixed / Variable Commission",
											modalContent: <HelpModal title="Fixed / Variable Commission" content={CommissionThresholdCommissionFixedVariableText} />,
										}}
									/>
									<Warning message="Be careful when changing this setting, it is an organisation wide value and will affect all newly created bookings going forward. If you end up with a mixture of fixed and variable commission bookings Edun will be unable to provide a representative commission value from the bookings calendar." />
								</LineItem>
							</>
						) : null}
					</LineItemsWrapper>
				)}
			</FormRowInner>
			{!inputError ? null : inputError}
		</FormRow>
	);
};
export default CommissionInput;
