import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../state/creators";
import { AlertType } from "../../state/reducers/setAlert";
import { ToastContainer, toast } from "react-toastify";

type Props = {
	setAlert: (alert?: AlertType) => void;
	alert?: AlertType;
};

const Alert: React.FC<Props> = ({ setAlert, alert }) => {
	const [{ type, message, center }, setState] = useState({
		type: null,
		message: null,
		alert,
		center: false,
	});

	let timeout = null;

	useEffect(() => {
		clearTimeout(timeout);
		if (alert) processAlert();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alert]);

	const processAlert = () => {
		if (alert.type === "error") {
			toast.error(alert.message);
		} else if (alert.type === "warning") {
			toast.warning(alert.message);
		} else {
			toast.success(alert.message);
		}
		timeout = setTimeout(() => {
			setAlert(null);
		}, 5000);
	};

	return (
		<ToastContainer
			position="top-right"
			autoClose={5000}
			newestOnTop
			closeOnClick
			pauseOnFocusLoss={false}
			style={{
				fontSize: 12,
				padding: 5,
			}}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		alert: state.setAlert.alert,
	};
};

const mapDispatchToProps = {
	setAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
