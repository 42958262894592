export enum CandidateSourceEnum {
	CompanyWebsite = "Company Website",
	iCanTeachUKWebsite = "iCan Teach UK Website",
	ApplytoEducation = "Apply to Education",
	CVLibraryCVSearch = "CV Library - CV Search",
	CVLibraryAdvert = "CV Library Advert",
	CVLibraryWatchdog = "CV Library - Watchdog",
	GuardianCVSearch = "Guardian- CV Search",
	GuardianAdvert = "Guardian Advert",
	IndeedCVSearch = "Indeed - CV Search",
	IndeedAdvert = "Indeed Advert",
	ReedCVSearch = "Reed - CV Search",
	ReedAdvert = "Reed Advert",
	ReedWatchdog = "Reed – Watchdog",
	SENployAdvert = "SENploy - Advert",
	TotalJobs = "Total Jobs",
	OtherJobBoard = "Other Job Board",
	Google = "Google",
	LinkedIn = "LinkedIn",
	LinkedinRecruiter = "Linkedin Recruiter",
	LinkedInAdvert = "LinkedIn Advert",
	ReferralSchool = "Referral - School",
	ReferralCandidate = "Referral - Candidate",
	UKUniversity = "UK University",
	IrishUniversity = "Irish University",
	CanadianUniversity = "Canadian University",
	MarketingCampaign = "Marketing Campaign",
	Woo = "Woo",
	JobShow = "Job Show",
	RecruitmentFair = "Recruitment Fair",
	CareerFair = "Career Fair",
	Other = "Other",
}
