import React, { useState, useContext, createContext, useRef } from "react";
import { OptionsType } from "../../_types/form";
import { CandidateStatusItems, CandidateStatusItemsNonApproved } from "../../config/formData";
import { MeInterface } from "../../_types/user";
import { ICandidateAdditionalCandidateStatusConfig } from "../../_models/data.meCustomerModel";

export interface GlobalCandidateStatusesState {
	candidateStatuses: OptionsType[];
	candidateStatusesNonApproved: OptionsType[];
	updateCandidateStatuses: (additionalCandidateStatusConfig?: ICandidateAdditionalCandidateStatusConfig) => void;
}

export const initialCandidateStatusesState: GlobalCandidateStatusesState = {
	candidateStatuses: null,
	candidateStatusesNonApproved: null,
	updateCandidateStatuses: () => {},
};

const CandidateStatusesStoreContext = createContext<GlobalCandidateStatusesState>(initialCandidateStatusesState);

type Props = {
	children: React.ReactChild;
	me: MeInterface;
};

export const CandidateStatusesStoreProvider = ({ children, me }: Props) => {
	const [candidateStatuses, setCandidateStatuses] = useState<OptionsType[]>(CandidateStatusItems);
	const [candidateStatusesNonApproved, setCandidateStatusesNonApproved] = useState<OptionsType[]>(CandidateStatusItemsNonApproved);

	const statusUpdated = useRef(null);

	const updateCandidateStatuses = (additionalCandidateStatusConfig?: ICandidateAdditionalCandidateStatusConfig) => {
		if (!statusUpdated?.current && (additionalCandidateStatusConfig?.hold || additionalCandidateStatusConfig?.future)) {
			let updatedStatuses = [...CandidateStatusItems];
			let updatedNonApprovedStatuses = [...CandidateStatusItemsNonApproved];
			if (additionalCandidateStatusConfig?.hold) {
				const newStatus = {
					ID: additionalCandidateStatusConfig.hold.id,
					Name: additionalCandidateStatusConfig.hold.name,
				};
				updatedStatuses.splice(1, 0, newStatus);
				updatedNonApprovedStatuses.splice(0, 0, newStatus); // Insert at 2nd last position
			}
			if (additionalCandidateStatusConfig?.future) {
				const newStatus = {
					ID: additionalCandidateStatusConfig.future.id,
					Name: additionalCandidateStatusConfig.future.name,
				};
				updatedStatuses.splice(updatedStatuses.length - 1, 0, newStatus);
				updatedNonApprovedStatuses.splice(updatedNonApprovedStatuses.length - 1, 0, newStatus); // Insert at 2nd last position
			}
			if (additionalCandidateStatusConfig?.dnc) {
				const newStatus = {
					ID: additionalCandidateStatusConfig.dnc.id,
					Name: additionalCandidateStatusConfig.dnc.name,
				};
				updatedStatuses.push(newStatus);
			}
			setCandidateStatuses(updatedStatuses);
			setCandidateStatusesNonApproved(updatedNonApprovedStatuses);
			statusUpdated.current = true;
		}
	};

	return (
		<CandidateStatusesStoreContext.Provider
			value={{
				candidateStatuses,
				candidateStatusesNonApproved,
				updateCandidateStatuses,
			}}
		>
			{children}
		</CandidateStatusesStoreContext.Provider>
	);
};

export const useCandidateStatus = () => useContext(CandidateStatusesStoreContext);
