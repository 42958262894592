import React, { useState, useEffect, useRef, MouseEventHandler } from "react";
import { FormRow, FormRowInner, Label, SearchSelectWrapper, SearchSelect, SelectOptions, SelectOption, SelectSearchField, SelectClear, Note, ReadOnly } from "../../../styles/forms/inputs";
import InputError from "../errors/input";
import { IHelp } from "../../../state/context/modals.reducers";
import { EventTargetType, OptionsType } from "../../../_types/form";

interface Props {
	uncontrolled?: boolean;
	flush?: boolean;
	hideActive?: boolean;
	label?: string;
	light?: boolean;
	help?: IHelp;
	placeholder?: string;
	name: string;
	value?: any;
	readonly?: boolean;
	error?: string;
	inline?: boolean;
	onChange: (e: EventTargetType) => void;
	required?: boolean;
	options: OptionsType[];
	note?: string;
	includeSearch?: boolean;
	external?: boolean;
	labelAction?: {
		text: string;
		action: MouseEventHandler<HTMLAnchorElement>;
	};
	refreshAction?: {
		text: string;
		action: MouseEventHandler<HTMLAnchorElement>;
	};
	addAll?: boolean;
	open?: boolean;
}

const EmailInput: React.FC<Props> = ({
	uncontrolled = false,
	flush = false,
	hideActive = false,
	label = false,
	light = false,
	help = false,
	placeholder = null,
	name,
	value = [],
	readonly = false,
	error = null,
	inline = false,
	onChange,
	required = false,
	options = [],
	note,
	includeSearch = false,
	external = false,
	labelAction = null,
	refreshAction = null,
	addAll = false,
	open = false,
}) => {
	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);
	const [dropActive, setDrop] = useState(false);
	const [searchSelectValue, setSearchSelectValue] = useState("");
	const [searchResults, setSearchResults] = useState(options);

	const searchRef: any = useRef(null);

	const handleChange = (e) => {
		// toggleDrop();

		let values = [...inputValue];
		if (e.target.checked) {
			values.push(e.target.value);
		} else {
			values = values.filter((item) => item != e.target.value);
		}

		onChange({
			target: {
				name: name,
				type: "array",
				value: values,
			},
		});
	};

	const handleSearch = (e) => {
		const value = e.target.value;
		setSearchSelectValue(value);
		const searchResults = options.filter((item) => item.Name.trim().toLowerCase().indexOf(value?.trim().toLowerCase()) > -1);
		setSearchResults(searchResults);
	};

	const clear = () => {
		if (!uncontrolled) setInputValue([]);
		onChange({ target: { name: name, value: [] } });
		toggleDrop();
	};

	const addAllOptions = () => {
		const updatedValue = inputValue?.filter((item) => !searchResults?.find((sr) => sr.ID === item)) || [];
		onChange({
			target: {
				name: name,
				type: "array",
				value: [...updatedValue, ...searchResults?.map((item) => item.ID)],
			},
		});
		setDrop(false);
	};

	useEffect(() => {
		setInputError(error);
		setSearchResults(options);
		if (value !== inputValue) {
			setInputValue(value);
			// setDrop(false)
			setSearchResults(options);
		}

		document.addEventListener("mousedown", handleOuterClick, false);

		return () => {
			document.removeEventListener("mousedown", handleOuterClick, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, value, options]);

	const toggleDrop = () => {
		if (!open) {
			setDrop(!dropActive);
		}
	};

	const handleOuterClick = (e) => {
		if (searchRef && searchRef.current && !searchRef.current.contains(e.target)) setDrop(false);
	};
	return (
		<FormRow hasError={inputError ? true : false} flush={flush}>
			<FormRowInner>
				{label && (
					<Label light={light ? true : false} help={help}>
						{label}
						{required ? "*" : null}
						{labelAction ? (
							<span>
								<a onClick={labelAction.action}>{labelAction.text}</a>
							</span>
						) : null}{" "}
						{refreshAction ? (
							<span>
								<a onClick={refreshAction.action}>{refreshAction.text}</a>
							</span>
						) : null}
					</Label>
				)}
				{note && <Note>{note}</Note>}
				{readonly ? (
					<ReadOnly>{inputValue ? (options.find((item) => item.ID == inputValue) ? options.find((item) => item.ID == inputValue).Name : "N/A") : "Select an option"}</ReadOnly>
				) : (
					<SearchSelectWrapper ref={searchRef}>
						<SearchSelect onClick={toggleDrop} active={dropActive} error={inputError ? true : false} open={open}>
							<span>{inputValue.length ? `${inputValue.length} item/s selected` : placeholder ? placeholder : "Select an option"}</span>
						</SearchSelect>
						{(dropActive || open) && (
							<>
								<SelectOptions open={open}>
									{includeSearch && (
										<SelectSearchField>
											<input type="text" name="searchSelect" value={searchSelectValue} onChange={handleSearch} placeholder="Enter search term" />
										</SelectSearchField>
									)}
									{addAll ? (
										<SelectClear>
											<a onClick={addAllOptions}>+ Add All</a>
										</SelectClear>
									) : null}
									{inputValue ? (
										<SelectClear>
											<a onClick={clear}>Clear</a>
										</SelectClear>
									) : null}
									{searchResults.map((item, index) => (
										<SelectOption
											key={index}
											onClick={() =>
												handleChange({
													target: {
														name: name,
														value: item.ID,
														type: "select",
														checked: inputValue.includes(item.ID) || inputValue.includes(JSON.stringify(item.ID)) ? false : true,
													},
												})
											}
											active={inputValue.includes(item.ID) || inputValue.includes(JSON.stringify(item.ID))}
											external={external}
											hideActive={hideActive}
										>
											<span>{item.Name}</span>
										</SelectOption>
									))}
								</SelectOptions>
							</>
						)}
					</SearchSelectWrapper>
				)}
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default EmailInput;
