const SummaryIcon = () => (
	<i>
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
			<path d="M8.597 3.629a1 1 0 011-1h2a1 1 0 010 2h-2a1 1 0 01-1-1z" fill="#294338"></path>
			<path d="M6.597 3.629a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2v-11a2 2 0 00-2-2 3 3 0 01-3 3h-2a3 3 0 01-3-3z" fill="#294338"></path>
		</svg>
	</i>
);

export default SummaryIcon;
