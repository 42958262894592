export enum SubjectTypes {
	Accounting = "Accounting",
	Arabic = "Arabic",
	ArtAndDesign = "Art & Design",
	Biology = "Biology",
	BusinessStudies = "Business Studies",
	Catering = "Catering",
	CertificateOfSponsorship = "Certificate of Sponsorship",
	Chemistry = "Chemistry",
	Citizenship = "Citizenship",
	Classics = "Classics",
	Computing = "Computing",
	Cover = "Cover",
	Dance = "Dance",
	DesignAndTechnology = "Design & Technology",
	Drama = "Drama",
	EAL = "EAL",
	Economics = "Economics",
	Engineering = "Engineering",
	English = "English",
	EthicsAndPhilosophy = "Ethics & Philosophy",
	FilmStudies = "Film Studies",
	FineArt = "Fine Art",
	FoodTechnology = "Food Technology",
	French = "French",
	FurtherMaths = "Further Maths",
	GeneralSupplyPrimary = "General Supply Primary",
	GeneralSupplySecondary = "General Supply Secondary",
	Geography = "Geography",
	German = "German",
	Graphics = "Graphics",
	Greek = "Greek",
	HealthAndSocialCare = "Health & Social Care",
	History = "History",
	Hospitality = "Hospitality",
	Humanities = "Humanities",
	ICT = "ICT",
	Italian = "Italian",
	Latin = "Latin",
	Law = "Law",
	Level = "Level",
	Mandarin = "Mandarin",
	Maths = "Maths",
	MediaStudies = "Media Studies",
	MFL = "MFL",
	Music = "Music",
	MusicTechnology = "Music Technology",
	NurseryEYFS = "Nursery - EYFS",
	Other = "Other",
	Overseas = "Overseas",
	PEFemale = "PE Female",
	PEMale = "PE Male",
	PerformingArts = "Performing Arts",
	Photography = "Photography",
	PHSE = "PHSE",
	PhysicalEducation = "Physical Education",
	Physics = "Physics",
	Politics = "Politics",
	PrimaryEYFS = "Primary EYFS",
	PrimaryKS1 = "Primary KS1",
	PrimaryKS2 = "Primary KS2",
	ProductDesign = "Product Design",
	PSHE = "PSHE",
	Psychology = "Psychology",
	Rating = "Rating",
	ReligiousEducation = "Religious Education",
	ResistantMaterials = "Resistant Materials",
	Science = "Science",
	SEN = "SEN",
	SENADHD = "SEN ADHD",
	SENASD = "SEN ASD",
	SENBESD = "SEN BESD",
	SENDyslexia = "SEN Dyslexia",
	SENEBD = "SEN EBD",
	SENEYFS = "SEN EYFS",
	SENHI = "SEN HI",
	SENMakaton = "SEN Makaton",
	SENMAPA = "SEN MAPA",
	SENMLD = "SEN MLD",
	SENNursery = "SEN Nursery",
	SENPMLD = "SEN PMLD",
	SENPrimaryKS1 = "SEN Primary KS1",
	SENPrimaryKS2 = "SEN Primary KS2",
	SENSEBD = "SEN SEBD",
	SENSEMH = "SEN SEMH",
	SENSLCN = "SEN SLCN",
	SENSLD = "SEN SLD",
	SENSPLD = "SEN SPLD",
	SENTeamTeach = "SEN Team Teach",
	SENVI = "SEN VI",
	SENco = "SENco",
	Sociology = "Sociology",
	Spanish = "Spanish",
	SpecialNeeds = "Special Needs",
	Statistics = "Statistics",
	Subjects = "Subjects",
	Textiles = "Textiles",
	TravelAndTourism = "Travel & Tourism",
	MathsFurther = "Maths Further",
	SENOCD = "SEN OCD",
	SENTherapy = "SEN Therapy",
	SENPersonalCare = "SEN Personal Care",
	SENBSL = "SEN BSL",
	PrimaryYR = "Primary YR",
	PrimaryYR1 = "Primary YR1",
	PrimaryYR2 = "Primary YR2",
	PrimaryYR3 = "Primary YR3",
	PrimaryYR4 = "Primary YR4",
	PrimaryYR5 = "Primary YR5",
	PrimaryYR6 = "Primary YR6",
	PrimaryReception = "Primary Reception",
	Support = "Support",
	ZInternalRecCon = "z Internal Rec Con",
	ZInternalCompliance = "z Internal Compliance",
	ZInternalManager = "z Internal Manager",
	ZInternalDirector = "Z Internal Director",
	ZInternalRecruiter = "Z Internal Recruiter",
	ZInternalResourcer = "Z Internal Resourcer",
	ComputerScience = "Computer Science",
	Criminology = "Criminology",
	Electronics = "Electronics",
	FunctionalSkills = "Functional Skills",
	SocialSciences = "Social Sciences",
	SocialStudies = "Social Studies",
	FinalYearStudent = "Final Year Student",
	DSLSupport = "DSL Support",
	ExamsOfficer = "Exams Officer",
	ArtDesignInternal = "Art&Design Internal",
	ZRecConTemp = "z Rec Con Temp",
	ZRecConPerm = "z Rec Con Perm",
	ZICanRecCon = "z iCan Rec Con",
	ZICanAftercare = "z iCan Aftercare",
	ZResourcer = "z resourcer",
}
