import React, { useState, useEffect } from "react";
import { FormRow, FormRowInner, Label, SwitchContainer, SwitchWrapper, SwitchSubContainer, SwitchInput, SwitchLabel, Note } from "../../../styles/forms/inputs";
import InputError from "../errors/input";
import { EventTargetType } from "../../../_types/form";
import InputHelp from "./help";
import { IHelp } from "../../../state/context/modals.reducers";

type Props = {
	label?: string;
	name: string;
	value: boolean;
	readonly?: boolean;
	error?: string;
	inline?: boolean;
	onChange: (e: EventTargetType) => void;
	trueLabel?: string;
	falseLabel?: string;
	flush?: boolean;
	help?: IHelp;
	note?: string;
};

const ToggleSwitchInput: React.FC<Props> = ({ label = false, name, value, readonly = false, error = null, inline = false, onChange, trueLabel, falseLabel, flush, help, note }) => {
	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);

	const handleChange = (e) => {
		setInputValue(e.target.checked);
		onChange({
			target: {
				name,
				value: e.target.checked,
				type: "checkbox",
			},
		});
	};

	useEffect(() => {
		setInputError(error);
	}, [error]);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	return (
		<FormRow flush={flush}>
			<FormRowInner>
				<SwitchContainer inline={inline}>
					{label ? (
						<Label center help={help ? true : false}>
							{label}
							{help ? <InputHelp help={help} /> : null}
						</Label>
					) : null}
					{note ? <Note>{note}</Note> : null}
					<SwitchSubContainer readonly={readonly}>
						<span>{trueLabel}</span>
						<SwitchWrapper>
							<SwitchInput id={name} type="checkbox" name={name} checked={inputValue} onChange={handleChange} disabled={readonly} />
							<SwitchLabel htmlFor={name} readonly={readonly} />
						</SwitchWrapper>
						<span>{falseLabel}</span>
					</SwitchSubContainer>
				</SwitchContainer>
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default ToggleSwitchInput;
