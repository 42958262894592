import styled from 'styled-components';
import { RelSize, Ratio } from '../mixins'
import theme from '../theme'

export const H1 = styled.h1`
	color: ${theme.primaryGreen};
    font-family: 'Poppins', sans-serif;
    font-size: ${RelSize(40, 16)};
    font-weight: 700;
    margin: 0 0 ${RelSize(10, 40)};
	${props => props.small && `
		font-size: ${RelSize(17, 16)};
		font-weight: 700;
		line-height: ${Ratio(17)};
		margin-bottom: ${RelSize(10, 17)};
	`}
`;

export const ExternalH1 = styled.h1`
	color: ${theme.primaryGreen};
    font-family: 'Poppins', sans-serif;
    font-size: ${RelSize(40, 16)};
    font-weight: 700;
    margin: 0 0 ${RelSize(10, 40)};
	${props => props.small && `
		font-size: ${RelSize(17, 16)};
		font-weight: 700;
		line-height: ${Ratio(17)};
		margin-bottom: ${RelSize(10, 17)};
	`}
`;

export const H2 = styled.h2`
	color: ${theme.primaryGreen};
    font-family: 'Poppins', sans-serif;
    font-size: ${RelSize(30, 16)};
    font-weight: 700;
	line-height: ${Ratio(30)};
    margin: 0 0 ${RelSize(15, 25)};
	text-align: ${props => props.align ? props.align : "left"};
`;

export const ExternalH2 = styled.h2`
	color: ${theme.primaryGreen};
    font-family: 'Poppins', sans-serif;
    font-size: ${RelSize(20, 16)};
    font-weight: 700;
    margin: 0 0 ${RelSize(25, 20)};
`;

export const H3 = styled.h3`
	color: ${theme.primaryGreen};
    font-family: 'Poppins', sans-serif;
    font-size: ${RelSize(20, 16)};
    font-weight: 700;
    margin: 0 0 ${RelSize(15, 20)};
`;

export const H4 = styled.h4`
	color: ${theme.primaryGreen};
    font-family: 'Poppins', sans-serif;
    font-size: ${RelSize(18, 16)};
    font-weight: 700;
    margin: 0 0 ${RelSize(15, 20)};
`;