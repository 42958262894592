export const getFromLocalStorage = (key: string) => {
	return JSON.parse(localStorage.getItem(key));
};

export const addToLocalStorage = (key: string, data: object) => {
	localStorage.setItem(key, JSON.stringify(data));
};

export const updateToLocalStorage = (key: string, data: object) => {
	const existing = localStorage.getItem(key);
	localStorage.setItem(
		key,
		JSON.stringify({
			...JSON.parse(existing),
			...data,
		})
	);
};

export const removeFromLocalStorage = (key: string) => {
	localStorage.removeItem(key);
};
