import { lighten } from 'polished';
import theme from './theme'

export const RelSize = (pixels, context = 16) => {
    return `${pixels / context}em`;
}

export const Ratio = (val = 16) => {
    return `${(val * theme.ratio) / val}em`;
}

type TableHighlightColor = "green";
export const GetTableRowHighlightColour = (highlightColour: TableHighlightColor) => {
	if (highlightColour === "green") return lighten(0.45, theme.green);
	else if (highlightColour === "red") return lighten(0.3, theme.red);
	else if (highlightColour === "blue") return lighten(0.65, theme.blue);
	else if (highlightColour === "orange") return lighten(0.35, theme.trafficLightAmber);
	else return "transparent";
}