import styled from 'styled-components';
import { RelSize } from '../mixins'

const LogoStyled = styled.i`
    display: block;
    height: ${props => props.small ? RelSize(26.68, 16) : RelSize(44.178639197614522, 16)};
    width: ${props => props.small ? RelSize(94, 16) : RelSize(150, 16)};
    svg {
        display: block;
        height: 100%;
        width: 100%;
    }
`;

export const XeroLogoStyled = styled.i`
    display: block;
    height: ${props => props.small ? RelSize(50, 16) : RelSize(75, 16)};
    width: ${props => props.small ? RelSize(50, 16) : RelSize(75, 16)};
    svg {
        display: block;
        height: 100%;
        width: 100%;
    }
`;

export default LogoStyled;