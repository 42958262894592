import styled from 'styled-components'
import { Ratio, RelSize } from '../mixins'
import theme from '../theme'

export const FlexColEnd = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
`;

export const FlexColStart = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
`;

export const IconList = styled.ul`
	${props => props.divider && `
		border-bottom: 1px solid ${theme.borderGrey};
	`}
	list-style: none;
	margin: 0 0 ${props => props.pushBottom ? RelSize(15, 16) : 0};
	padding: 0 0 ${props => props.divider ? RelSize(10, 16) : 0};
	li {
		display: flex;
		i {
			flex-shrink: 0;
			margin-right: ${RelSize(10, 16)};
		}
		span {
			margin-right: ${RelSize(5, 16)};
		}
		&:not(:last-child) {
			margin-bottom: ${RelSize(15, 16)};
			${props => props.documents && `
				border-bottom: 1px solid ${theme.borderGrey};
				padding-bottom: ${RelSize(15, 16)};
			`}
		}
		${props => props.documents && `
			align-items: flex-start;
			justify-content: space-between;
			> div {
				display: flex;
				min-width: 0;
				overflow: hidden;
				padding-right: ${RelSize(10, 16)};
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		`}
	}
`;

export const ListRowGroup = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	> div {
		display: flex;
		&:not(:last-child) {
			margin-right: ${RelSize(20, 16)};
		}
	}
`;

export const ScrollWrapper = styled.div`
	max-height: ${RelSize(175, 16)};
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 ${RelSize(15, 16)} 0 0;
	width: 100%;
	scrollbar-width: thin;
	scrollbar-color: ${theme.primaryGreen} transparent;
	&::-webkit-scrollbar {
	  width: 5px;
	}
	&::-webkit-scrollbar-track {
	  background: transparent;
	}
	&::-webkit-scrollbar-thumb {
	  background: ${theme.primaryGreen};
	}
	background:
		linear-gradient(white 30%, rgba(255,255,255,0)),
		linear-gradient(rgba(255,255,255,0), white 70%) 0 100%,
		radial-gradient(farthest-side at 50% 0, rgba(0,0,0,0.08), rgba(0,0,0,0)),
		radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,0.08), rgba(0,0,0,0)) 0 100%;
		background-size: 100% 30px, 100% 30px, 100% 14px, 100% 14px;
		background-attachment: local, local, scroll, scroll;
		background-repeat: no-repeat;
}`;


export const IconListDesc = styled.div`
	${props => props.documents && `
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`}
	span {
		display: block;
		font-size: ${RelSize(12, 16)};
		line-height: ${RelSize(16, 12)};
		&.nowrap {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
`;

export const BulletList = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		font-weight: ${props => props.strong ? 700 : 400};
		padding-left: ${props => props.nobullet ? 0 : RelSize(20, 16)};
		position: relative;
		${props => !props.nobullet && `
			&::before {
				background-color: ${theme.tertiaryGreen};
				border: 2px solid ${theme.primaryGreen};
				border-radius: 50%;
				content: "";
				height: ${RelSize(10, 16)};
				left: 0;
				position: absolute;
				top: ${RelSize(2, 16)};
				width: ${RelSize(10, 16)};
			}
		`}
		&:not(:last-child) {
			margin-bottom: ${RelSize(10, 16)};
		}
		span {
			display: block;
			font-size: ${RelSize(14, 16)};
			margin-top: ${RelSize(10, 14)};
		}
		${props => props.flex && `
			align-items: flex-start;
			display: flex;
			justify-content: space-between;
			> div {
				display: flex;
				padding-right: ${RelSize(10, 16)};
				word-break: break-word;
			}
		`}
	}
`;

export const FeatureList = styled.ul`
	background-color: ${theme.trueWhite};
	list-style: none;
	margin: ${RelSize(40, 16)} 0 0;
	padding: ${RelSize(50, 16)};
	li {
		font-family: 'Poppins', sans-serif;
		font-size: ${RelSize(20, 16)};
		margin: 0;
		padding: 0 ${RelSize(50, 20)} 0 0;
		position: relative;
		&:not(:last-child) {
			border-bottom: 1px solid ${theme.borderGrey};
			margin-bottom: ${RelSize(50, 20)};
			padding-bottom: ${RelSize(20, 20)};
		}
		&::after {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDMwIDMwIj4KICA8ZyBpZD0iR3JvdXBfOTc0MCIgZGF0YS1uYW1lPSJHcm91cCA5NzQwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTM1NCAtMzk1MikiPgogICAgPGcgaWQ9Ikdyb3VwXzk3MzgiIGRhdGEtbmFtZT0iR3JvdXAgOTczOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTM1NCAzOTUyKSI+CiAgICAgIDxnIGlkPSJHcm91cF85NzM3IiBkYXRhLW5hbWU9Ikdyb3VwIDk3MzciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMCkiPgogICAgICAgIDxnIGlkPSJHcm91cF85NzM5IiBkYXRhLW5hbWU9Ikdyb3VwIDk3MzkiPgogICAgICAgICAgPGNpcmNsZSBpZD0iRWxsaXBzZV8yNDYiIGRhdGEtbmFtZT0iRWxsaXBzZSAyNDYiIGN4PSIxNSIgY3k9IjE1IiByPSIxNSIgZmlsbD0iI2I5Y2UxYSIvPgogICAgICAgIDwvZz4KICAgICAgPC9nPgogICAgPC9nPgogICAgPHBhdGggaWQ9IlBhdGhfMTA3NTEiIGRhdGEtbmFtZT0iUGF0aCAxMDc1MSIgZD0iTTIxLjMsMTAuNTg2bC05LjQ1Nyw4LjgyNkw4LjcsMTYuMjYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzNTQuMzIyIDM5NTEuMzg1KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMjk0MzM4IiBzdHJva2Utd2lkdGg9IjIiLz4KICA8L2c+Cjwvc3ZnPgo=");
			background-repeat: no-repeat;
			background-size: contain;
			background-position: 0 0;
			content: "";
			height: ${RelSize(30, 20)};
			position: absolute;
			right: 0;
			top: 0;
			width: ${RelSize(30, 20)};
		}
		&.nofeature {
			&:not(:last-child) {
				padding-bottom: ${RelSize(50, 20)};
			}
		}
	}
	span {
		display: block;
	}
	a {
		display: inline-block;
		font-size: ${RelSize(10, 20)};
		margin-top: ${RelSize(20, 10)};
	}
	.featureContent {
		font-size: ${RelSize(14, 20)};
		padding: ${RelSize(20, 14)} 0;
		line-height: ${Ratio(14)};
		p {
			&:last-of-type {
				margin: 0;
			}
		}
	}
`;

export const MobileContent = styled.div`
	@media all and (min-width: 481px) {
		display: none;
	}
`;

export const ContentWrapper = styled.div`
	margin-bottom: ${RelSize(50, 16)};
	@media all and (max-width: 767px) {
		margin-bottom: ${RelSize(20, 16)};
	}
`;

export const Divider = styled.hr`
	background-color: ${theme.quarterinaryGreen};
	border: 0;
	height: 2px;
	margin: ${RelSize(20, 16)} 0 ${RelSize(30, 16)};
`;

export const CodeBlockStyled = styled.pre`
	background-color: ${theme.borderGrey};
	box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
	font-size: ${RelSize(12, 16)};
	padding: ${RelSize(20, 12)} ${RelSize(20, 12)} ${RelSize(30, 12)} ${RelSize(20, 12)};
	word-break: break-word;
	width: 100%;
	overflow-x: auto;
	scrollbar-width: thin;
	scrollbar-color: ${theme.primaryGreen} transparent;
	&::-webkit-scrollbar {
	  width: 5px;
	}
	&::-webkit-scrollbar-track {
	  background: transparent;
	}
	&::-webkit-scrollbar-thumb {
	  background: ${theme.primaryGreen};
	}
`;