import React, { useState, useEffect } from "react";
import { Props } from "../../../_interfaces/component.inputText.interface";
import InputError from "../errors/input";
import { FormRow, FormRowInner, Label, ToggleWrapper, ToggleItem, ToggleDesc, Note } from "../../../styles/forms/inputs";
import Row from "../../../styles/layout/row";
import Col from "../../../styles/layout/col";

const CheckboxGroupInput: React.FC<Props> = ({
	label = false,
	light = false,
	help = false,
	placeholder = null,
	name,
	value = [],
	readonly = false,
	error = null,
	inline = false,
	onChange,
	required = false,
	canEditReadOnly = null,
	note = null,
	trueText = null,
	falseText = null,
	options = [],
	hasDesc = false,
}) => {
	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);
	const [isReadOnly, setReadOnly] = useState(readonly);

	const handleChange = (e) => {
		const target = e.target;
		setInputValue(target.checked ? [...inputValue, ...[target.value]] : inputValue.filter((item) => item != target.value));
		onChange({
			target: {
				name,
				value: target.checked ? [...inputValue, ...[target.value]] : inputValue.filter((item) => item != target.value),
				type: "checkboxGroup",
			},
		});
	};

	useEffect(() => {
		setInputError(error);
		if (value !== inputValue) setInputValue(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, value]);

	return (
		<FormRow>
			<FormRowInner>
				{label && (
					<Label light={light ? true : false} help={help}>
						{label}
						{required ? "*" : null}
					</Label>
				)}
				{note && <Note>{note}</Note>}
				<Row>
					{options.map((item, index) => (
						<Col span={3} key={index}>
							<ToggleWrapper key={index} pushBottom={hasDesc ? false : true}>
								<ToggleItem small>
									<input
										type="checkbox"
										name={name}
										value={item.ID}
										id={item.ID}
										checked={inputValue.find((valueItem) => valueItem === item.ID)}
										onChange={handleChange}
										disabled={isReadOnly ? true : false}
									/>
									<label htmlFor={item.ID}>{item.Name}</label>
								</ToggleItem>
							</ToggleWrapper>
							{hasDesc && item.Desc ? <ToggleDesc>{item.Desc}</ToggleDesc> : null}
						</Col>
					))}
				</Row>
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default CheckboxGroupInput;
