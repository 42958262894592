import { CandidateStatus } from "../../_enums/candidateStatus.enum";
import NotificationIcon, { NotificationStatusIcon } from "../../styles/icons/notificationIcon";
import theme from "../../styles/theme";

const StatusIcon = ({ status }: { status: CandidateStatus }) => (
	<NotificationIcon>
		{status == CandidateStatus.Approved ? (
			<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clip-rule="evenodd"
					d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707Z"
					fill="white"
				/>
			</svg>
		) : (
			<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="10.417" cy="10.4167" r="8.75" fill="white" />
				<g clipPath="url(#clip0_30_255)">
					<path
						d="M12.0442 7.95482L10.4663 13.774C10.3981 13.9974 10.354 14.2275 10.3348 14.4602C10.3346 14.5241 10.3594 14.5856 10.404 14.6317C10.4404 14.675 10.4927 14.7021 10.5493 14.7072C10.6498 14.7015 10.7442 14.6572 10.8123 14.5837C11.1417 14.2573 11.4214 13.8851 11.6428 13.4789L11.9058 13.6436C11.1307 15.1029 10.3048 15.8326 9.42819 15.8326C9.28176 15.8389 9.1356 15.8153 8.99872 15.7634C8.86183 15.7114 8.73713 15.6322 8.63231 15.5307C8.53028 15.4282 8.45107 15.3056 8.39981 15.1708C8.34855 15.0361 8.32637 14.8922 8.33473 14.7484C8.34372 14.4853 8.38794 14.2245 8.46622 13.973L9.53892 10.0409C9.63365 9.76562 9.69408 9.47986 9.71885 9.19001C9.71934 9.12907 9.70661 9.06873 9.68154 9.01309C9.65647 8.95744 9.61963 8.90779 9.57352 8.86749C9.52001 8.81767 9.45681 8.7792 9.3878 8.75442C9.31878 8.72964 9.24539 8.71908 9.17212 8.72338H8.8953L8.99911 8.394L11.5874 7.95482H12.0442ZM11.5667 4.16689C11.7197 4.16439 11.8714 4.19502 12.0113 4.25665C12.1511 4.31828 12.2757 4.40942 12.3764 4.52372C12.5907 4.75561 12.7071 5.06017 12.7016 5.37464C12.7074 5.69043 12.5881 5.99589 12.3695 6.22555C12.2694 6.33799 12.1464 6.42805 12.0086 6.48974C11.8708 6.55143 11.7213 6.58333 11.5701 6.58333C11.4189 6.58333 11.2694 6.55143 11.1316 6.48974C10.9938 6.42805 10.8708 6.33799 10.7708 6.22555C10.542 6.00031 10.4126 5.69432 10.4109 5.37464C10.4114 5.21628 10.4434 5.05957 10.5051 4.91354C10.5669 4.76751 10.6572 4.63502 10.7708 4.52372C10.8687 4.40975 10.9909 4.31866 11.1284 4.25697C11.266 4.19528 11.4157 4.16452 11.5667 4.16689Z"
						fill={
							status == CandidateStatus.Rejected || status == CandidateStatus.Archived || status == CandidateStatus.Anonymised
								? theme.trafficLightRed
								: status == CandidateStatus.Enquired
								? theme.trafficLightAmber
								: theme.trafficLightBlue
						}
					/>
				</g>
				<defs>
					<clipPath id="clip0_30_255">
						<rect width="5" height="13.3333" fill="white" transform="translate(8.33301 3.33331)" />
					</clipPath>
				</defs>
			</svg>
		)}
	</NotificationIcon>
);

export default StatusIcon;

export const StatusSummaryIcon = ({ approved, staticStatus }: { approved: boolean; staticStatus?: boolean }) => (
	<NotificationStatusIcon>
		{approved ? (
			<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.707 6.707C11.8892 6.5184 11.99 6.2658 11.9877 6.0036C11.9854 5.7414 11.8802 5.49059 11.6948 5.30518C11.5094 5.11977 11.2586 5.0146 10.9964 5.01233C10.7342 5.01005 10.4816 5.11084 10.293 5.293L7 8.586L5.707 7.293C5.5184 7.11084 5.2658 7.01005 5.0036 7.01233C4.7414 7.0146 4.49059 7.11977 4.30518 7.30518C4.11977 7.49059 4.0146 7.7414 4.01233 8.0036C4.01005 8.2658 4.11084 8.5184 4.293 8.707L6.293 10.707C6.48053 10.8945 6.73484 10.9998 7 10.9998C7.26516 10.9998 7.51947 10.8945 7.707 10.707L11.707 6.707Z"
					fill={theme.trafficLightGreen}
				/>
			</svg>
		) : (
			<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM6.707 5.293C6.5184 5.11084 6.2658 5.01005 6.0036 5.01233C5.7414 5.0146 5.49059 5.11977 5.30518 5.30518C5.11977 5.49059 5.0146 5.7414 5.01233 6.0036C5.01005 6.2658 5.11084 6.5184 5.293 6.707L6.586 8L5.293 9.293C5.19749 9.38525 5.12131 9.49559 5.0689 9.6176C5.01649 9.7396 4.9889 9.87082 4.98775 10.0036C4.9866 10.1364 5.0119 10.2681 5.06218 10.391C5.11246 10.5139 5.18671 10.6255 5.2806 10.7194C5.3745 10.8133 5.48615 10.8875 5.60905 10.9378C5.73194 10.9881 5.86362 11.0134 5.9964 11.0123C6.12918 11.0111 6.2604 10.9835 6.3824 10.9311C6.50441 10.8787 6.61475 10.8025 6.707 10.707L8 9.414L9.293 10.707C9.4816 10.8892 9.7342 10.99 9.9964 10.9877C10.2586 10.9854 10.5094 10.8802 10.6948 10.6948C10.8802 10.5094 10.9854 10.2586 10.9877 9.9964C10.99 9.7342 10.8892 9.4816 10.707 9.293L9.414 8L10.707 6.707C10.8892 6.5184 10.99 6.2658 10.9877 6.0036C10.9854 5.7414 10.8802 5.49059 10.6948 5.30518C10.5094 5.11977 10.2586 5.0146 9.9964 5.01233C9.7342 5.01005 9.4816 5.11084 9.293 5.293L8 6.586L6.707 5.293Z"
					fill={staticStatus ? theme.primaryGreen : theme.trafficLightRed}
				/>
			</svg>
		)}
	</NotificationStatusIcon>
);

export const StatusSummaryCustomIcon = ({ variant }: { variant: "green" | "amber" | "red" }) => (
	<NotificationStatusIcon>
		{variant === "green" ? (
			<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.707 6.707C11.8892 6.5184 11.99 6.2658 11.9877 6.0036C11.9854 5.7414 11.8802 5.49059 11.6948 5.30518C11.5094 5.11977 11.2586 5.0146 10.9964 5.01233C10.7342 5.01005 10.4816 5.11084 10.293 5.293L7 8.586L5.707 7.293C5.5184 7.11084 5.2658 7.01005 5.0036 7.01233C4.7414 7.0146 4.49059 7.11977 4.30518 7.30518C4.11977 7.49059 4.0146 7.7414 4.01233 8.0036C4.01005 8.2658 4.11084 8.5184 4.293 8.707L6.293 10.707C6.48053 10.8945 6.73484 10.9998 7 10.9998C7.26516 10.9998 7.51947 10.8945 7.707 10.707L11.707 6.707Z"
					fill={theme.trafficLightGreen}
				/>
			</svg>
		) : variant === "red" ? (
			<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM6.707 5.293C6.5184 5.11084 6.2658 5.01005 6.0036 5.01233C5.7414 5.0146 5.49059 5.11977 5.30518 5.30518C5.11977 5.49059 5.0146 5.7414 5.01233 6.0036C5.01005 6.2658 5.11084 6.5184 5.293 6.707L6.586 8L5.293 9.293C5.19749 9.38525 5.12131 9.49559 5.0689 9.6176C5.01649 9.7396 4.9889 9.87082 4.98775 10.0036C4.9866 10.1364 5.0119 10.2681 5.06218 10.391C5.11246 10.5139 5.18671 10.6255 5.2806 10.7194C5.3745 10.8133 5.48615 10.8875 5.60905 10.9378C5.73194 10.9881 5.86362 11.0134 5.9964 11.0123C6.12918 11.0111 6.2604 10.9835 6.3824 10.9311C6.50441 10.8787 6.61475 10.8025 6.707 10.707L8 9.414L9.293 10.707C9.4816 10.8892 9.7342 10.99 9.9964 10.9877C10.2586 10.9854 10.5094 10.8802 10.6948 10.6948C10.8802 10.5094 10.9854 10.2586 10.9877 9.9964C10.99 9.7342 10.8892 9.4816 10.707 9.293L9.414 8L10.707 6.707C10.8892 6.5184 10.99 6.2658 10.9877 6.0036C10.9854 5.7414 10.8802 5.49059 10.6948 5.30518C10.5094 5.11977 10.2586 5.0146 9.9964 5.01233C9.7342 5.01005 9.4816 5.11084 9.293 5.293L8 6.586L6.707 5.293Z"
					fill={variant === "red" ? theme.trafficLightRed : theme.trafficLightAmber}
				/>
			</svg>
		) : (
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M17.5 8.75C17.5 13.5825 13.5825 17.5 8.75 17.5C3.91751 17.5 0 13.5825 0 8.75C0 3.91751 3.91751 0 8.75 0C13.5825 0 17.5 3.91751 17.5 8.75Z" fill={theme.trafficLightAmber} />
				<path
					d="M10.3772 6.28813L8.79929 12.1073C8.73111 12.3307 8.68703 12.5608 8.6678 12.7935C8.66762 12.8574 8.69242 12.9189 8.737 12.965C8.77345 13.0083 8.82572 13.0354 8.88234 13.0405C8.98285 13.0348 9.07717 12.9905 9.14532 12.917C9.4747 12.5906 9.75445 12.2184 9.9758 11.8122L10.2388 11.9769C9.46367 13.4362 8.63781 14.1659 7.76119 14.1659C7.61477 14.1722 7.46861 14.1486 7.33172 14.0967C7.19484 14.0447 7.07014 13.9656 6.96532 13.864C6.86328 13.7615 6.78407 13.6389 6.73281 13.5041C6.68155 13.3694 6.65938 13.2255 6.66773 13.0817C6.67673 12.8186 6.72094 12.5578 6.79923 12.3063L7.87192 8.37424C7.96666 8.09893 8.02708 7.81317 8.05186 7.52332C8.05235 7.46238 8.03962 7.40204 8.01455 7.3464C7.98947 7.29076 7.95264 7.2411 7.90653 7.2008C7.85302 7.15098 7.78982 7.11251 7.72081 7.08773C7.65179 7.06295 7.5784 7.05239 7.50513 7.05669H7.2283L7.33211 6.72731L9.92043 6.28813H10.3772ZM9.89967 2.5002C10.0527 2.4977 10.2044 2.52833 10.3443 2.58996C10.4841 2.65159 10.6087 2.74274 10.7094 2.85704C10.9237 3.08892 11.0401 3.39348 11.0347 3.70795C11.0404 4.02374 10.9211 4.3292 10.7025 4.55886C10.6025 4.67131 10.4795 4.76137 10.3416 4.82305C10.2038 4.88474 10.0543 4.91664 9.90313 4.91664C9.75192 4.91664 9.60245 4.88474 9.46463 4.82305C9.32681 4.76137 9.20381 4.67131 9.1038 4.55886C8.87499 4.33363 8.74558 4.02763 8.74393 3.70795C8.74437 3.54959 8.77639 3.39289 8.83815 3.24685C8.89991 3.10082 8.99019 2.96834 9.1038 2.85704C9.20173 2.74306 9.32386 2.65197 9.46145 2.59028C9.59904 2.52859 9.74869 2.49783 9.89967 2.5002Z"
					fill="white"
				/>
			</svg>
		)}
	</NotificationStatusIcon>
);
