import React from "react";

export const RefreshIcon = () => {
	return (
		<i>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
				<path
					stroke="#294338"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.5"
					d="M2.667 2.667V6h.388m0 0a5.334 5.334 0 0110.237 1.333M3.054 6H6m7.333 7.333V10h-.387m0 0A5.336 5.336 0 012.708 8.667M12.946 10H10"
				></path>
			</svg>
		</i>
	);
};
