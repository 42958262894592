import React, { useReducer, useContext, createContext } from "react";
import { GlobalModalsState, initialModalsState, modalsStoreReducer } from "./modals.reducers";

const ModalsStoreContext = createContext<GlobalModalsState>(initialModalsState);

type Props = {
	children: React.ReactChild;
};

export const ModalsStoreProvider = ({ children }: Props) => {
	const [state, dispatch] = useReducer(modalsStoreReducer, initialModalsState);

	return (
		<ModalsStoreContext.Provider
			value={{
				...state,
				dispatch,
			}}
		>
			{children}
		</ModalsStoreContext.Provider>
	);
};

export const useModalsStore = () => useContext(ModalsStoreContext);
