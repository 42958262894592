import React, { Component } from "react";
import { PaginationStyled, PaginationPrevious, PaginationNext, PaginationItem, PaginationSelect, PaginationSelectAnchor } from "../../styles/tables/pagination";

class TablePagination extends Component<
	{
		paginationItems?: any;
		UpdatePage: any;
		pageNo?: number;
		showAll?: boolean;
		totalRecords?: number;
		pageSize?: number;
		theme?: any;
		fullPagination?: boolean;
		fullPaginationSlim?: boolean;
	},
	any
> {
	constructor(props) {
		super(props);
		this.state = {
			paginationItems: this.props.paginationItems,
			pageNo: this.props.pageNo,
			pageSize: this.props.pageSize,
			showAll: this.props.showAll,
			totalRecords: this.props.totalRecords,
		};
	}
	componentDidMount() {
		this.updatePagination();
	}
	componentDidUpdate(prevProps) {
		if (prevProps.paginationItems !== this.props.paginationItems) this.setState({ paginationItems: this.props.paginationItems }, () => this.rebuildPagination());
		if (prevProps.pageNo !== this.props.pageNo) this.setState({ pageNo: this.props.pageNo }, () => this.rebuildPagination());
		if (prevProps.pageSize !== this.props.pageSize) this.setState({ pageSize: this.props.pageSize }, () => this.rebuildPagination());
		if (prevProps.totalRecords !== this.props.totalRecords) this.setState({ totalRecords: this.props.totalRecords }, () => this.rebuildPagination());
		if (prevProps.showAll !== this.props.showAll) this.setState({ showAll: this.props.showAll }, () => this.rebuildPagination());
	}
	rebuildPagination = () => {
		let PaginationItems = [];
		const Previous = this.state.pageNo > 1 ? { PageNumberLabel: "Previous" } : null;
		const Next = this.state.totalRecords / this.state.pageSize > this.state.pageNo ? { PageNumberLabel: "Next" } : null;
		if (Previous) PaginationItems.push(Previous);
		if (Next) PaginationItems.push(Next);
		this.setState({ paginationItems: PaginationItems }, () => this.updatePagination());
	};
	updatePagination = () => {
		const Previous = this.state.paginationItems.filter((item) => item.PageNumberLabel === "Previous");
		const Next = this.state.paginationItems.filter((item) => item.PageNumberLabel === "Next");
		this.setState({
			Previous: Previous.length ? Previous[0] : null,
			Next: Next.length ? Next[0] : null,
		});
	};
	renderPreviousNumbers = () => {
		const pageNum = this.state.pageNo;
		const pages = Math.ceil(this.props.totalRecords / this.state.pageSize);

		let Prev = [];

		if (pageNum > 0) {
			if (pageNum - 1 !== 0) Prev.push(pageNum - 1);
			if (pageNum - 2 !== 0) Prev.push(pageNum - 2);
		}
		return Prev.sort((a, b) => a - b).map((item, index) => (
			<li key={index}>
				<a onClick={() => this.props.UpdatePage("paginate", { pageNo: item, showAll: this.state.showAll })}>{item}</a>
			</li>
		));
	};
	renderNextNumbers = () => {
		const pageNum = this.state.pageNo;
		const pages = Math.ceil(this.props.totalRecords / this.state.pageSize);

		let Next = [];

		if (pageNum !== pages) {
			if (pageNum + 1 !== pages + 1) Next.push(pageNum + 1);
			if (pageNum + 2 !== pages + 1) Next.push(pageNum + 2);
		}
		return Next.map((item, index) => (
			<li key={index}>
				<a onClick={() => this.props.UpdatePage("paginate", { pageNo: item, showAll: this.state.showAll })}>{item}</a>
			</li>
		));
	};
	render() {
		if (!this.state.totalRecords || this.state.totalRecords <= 0 || this.state.totalRecords <= this.state.pageSize) return <></>;
		return (
			<PaginationStyled active={true} hasPrevious={this.state.Previous ? true : false} hasNext={this.state.Next ? true : false}>
				<>
					{this.state.Previous && (
						<PaginationPrevious title="Previous" onClick={() => this.props.UpdatePage("prev", { showAll: this.state.showAll })} previous>
							Prev
						</PaginationPrevious>
					)}
					<ul>
						{this.state.Previous ? this.renderPreviousNumbers() : null}
						<li>
							<PaginationItem>{this.state.pageNo}</PaginationItem>
						</li>
						{this.state.Next ? this.renderNextNumbers() : null}
					</ul>
					{this.state.Next && (
						<PaginationNext title="Next" onClick={() => this.props.UpdatePage("next", { showAll: this.state.showAll })} next>
							Next
						</PaginationNext>
					)}
				</>
				{this.props.fullPagination ? (
					<PaginationSelect onChange={(e) => this.props.UpdatePage("paginate", { pageSize: parseInt(e.target.value), pageNo: 1 })} value={this.state.pageSize}>
						<span>Per Page:</span>
						<ul>
							<li>
								<PaginationSelectAnchor active={this.state.pageSize == "10" ? true : false} onClick={(e) => this.props.UpdatePage("paginate", { pageSize: parseInt("10"), pageNo: 1 })}>
									10
								</PaginationSelectAnchor>
							</li>
							<li>
								<PaginationSelectAnchor
									active={this.state.pageSize == "100" ? true : false}
									onClick={(e) => this.props.UpdatePage("paginate", { pageSize: parseInt("100"), pageNo: 1 })}
								>
									100
								</PaginationSelectAnchor>
							</li>
							{!this.props.fullPaginationSlim ? (
								<li>
									<PaginationSelectAnchor
										active={this.state.pageSize == "1000" ? true : false}
										onClick={(e) => this.props.UpdatePage("paginate", { pageSize: parseInt("1000"), pageNo: 1 })}
									>
										1000
									</PaginationSelectAnchor>
								</li>
							) : null}
						</ul>
					</PaginationSelect>
				) : null}
			</PaginationStyled>
		);
	}
}

export default TablePagination;
