import React, { useEffect, useState, useContext, createContext } from "react";
import { MeInterface, UserInterface } from "../../_types/user";
import { CustomerInterface } from "../../_types/customer";

export interface GlobalAuthState {
	auth:
		| (MeInterface & { user: UserInterface; customer: CustomerInterface })
		| {
				token: string;
				user?: UserInterface;
				customer?: CustomerInterface;
		  };
	me: Partial<MeInterface>;
	onboarding: boolean;
	setAuth: (
		auth:
			| (MeInterface & { user: UserInterface; customer: CustomerInterface })
			| {
					token: string;
					user?: UserInterface;
					customer?: CustomerInterface;
			  }
	) => void;
	setMe: (me: UserInterface | null) => void;
	setOnboarding: (onboarding: boolean) => void;
	supportRedirectURL: string | null;
	setSupportRedirectURL: (url: string) => void;
}

export const initialAuthState: GlobalAuthState = {
	auth: {
		token: "",
	},
	me: {},
	onboarding: false,
	setAuth: () => {},
	setMe: () => {},
	setOnboarding: () => {},
	setSupportRedirectURL: () => {},
	supportRedirectURL: null,
};

const AuthStoreContext = createContext<GlobalAuthState>(initialAuthState);

type Props = {
	children: React.ReactChild;
	me: MeInterface;
};

export const AuthStoreProvider = ({ children, me }: Props) => {
	const [auth, setAuth] = useState<
		| MeInterface
		| {
				token: string;
		  }
	>(
		me ?? {
			token: "",
		}
	);
	const [meState, setMeState] = useState<Partial<UserInterface>>(me ?? {});
	const [onboarding, setOnboarding] = useState(false);
	const [supportRedirectURL, setSupportRedirectURL] = useState<string | null>(null);
	useEffect(() => {
		setAuth(
			me ?? {
				token: "",
			}
		);
		setMeState(me?.user ? me.user : {});
		setOnboarding(me?.user?.displayOnboarding || false);
	}, [me]);

	return (
		<AuthStoreContext.Provider
			value={{
				auth,
				me: meState,
				setAuth,
				setMe: setMeState,
				onboarding,
				setOnboarding: setOnboarding,
				supportRedirectURL,
				setSupportRedirectURL,
			}}
		>
			{children}
		</AuthStoreContext.Provider>
	);
};

export const useAuth = () => useContext(AuthStoreContext);
