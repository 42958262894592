import styled from 'styled-components';
import { RelSize } from '../mixins'

const NavigationIconStyled = styled.i`
    display: block;
    height: ${RelSize(20, 16)};
	margin-right: ${props => props.flush ? 0 : RelSize(20, 16)};
    width: ${RelSize(20, 16)};
    svg {
        display: block;
        height: 100%;
        width: 100%;
    }
`;

export default NavigationIconStyled;