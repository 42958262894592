export class ListingModel {
	filter: any;
	paginationItems: any;
	resultItems: any;
	resultItemsAll?: any;
	totalRecords: number;

	constructor(data: any = {}, config: any = {}) {
		let PaginationItems = [];
		const Previous = config.pageNo > 1 ? { PageNumberLabel: "Previous" } : null;
		const Next = (data.totalRecords || data.resultItems.length) / config.pageSize > config.pageNo ? { PageNumberLabel: "Next" } : null;
		if (Previous) PaginationItems.push(Previous);
		if (Next) PaginationItems.push(Next);

		this.filter = data.filter || {};
		this.paginationItems = PaginationItems;
		this.resultItems = data.resultItems || [];
		this.resultItemsAll = data.resultItemsAll || data.resultItems || [];
		this.totalRecords = data.totalRecords || data.resultItems.length;
	}
}
