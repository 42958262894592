const theme = {
	black: '#141414',
	white: '#FFFFFF',
	trueWhite: '#FFFFFF',
	gold: '#FFC659',
	blue: '#1C2C5B',
	green: '#4BB543',
	amber: '#FFBF00',
	greenStd: '#748976',
	backgroundGrey: '#F7F6F0',
	secondaryBackgroundGrey: '#F7F7F7',
	primaryGreen: '#294338',
	secondaryGreen: '#2F4239',
	tertiaryGreen: '#D5D5BB',
	quarterinaryGreen: '#7A8E87',
	secondaryGrey: '#7C7C7C',
	borderGrey: '#F2F2F2',
	formGroupBg: '#F1F1F1',
	red: '#D32F2F',
	trafficLightGreen: '#388E3C',
	trafficLightAmber: '#FFC107',
	trafficLightRed: '#D32F2F',
	trafficLightBlue: '#2A65CC',
	tagComingSoon: '#B9CE1A',
	brightGreen: '#03D325',
	xero: '#bbf3fd',
	containerPadding: '0 20px',
	maxWidth: '1600px',
	smallMaxWidth: '640px',
	ratio: 1.61803399,
};

export const MainTheme = {
	PrimaryColour: theme.black,
	SecondaryColour: theme.gold,
	TertiaryColour: theme.blue,
}

export default theme;
