import { Permissions } from "../_enums/permissions.enum";
import { UserRole } from "../components/forms/user";

export const PopulatePermissionsBasedOnRole = (role: UserRole): number[] => {
	if (role === UserRole.RecruitmentConsultant) {
		return [
			Permissions.CanManageClients,
			Permissions.CanExportPersonnelClients,
			Permissions.CanAddClients,
			Permissions.CanExportClients,
			Permissions.CanEditClients,
			Permissions.CanViewAllClients,
			Permissions.CanDeleteClients,
			Permissions.CanManageCandidates,
			Permissions.CanDeleteCandidates,
			Permissions.CanAddCandidates,
			Permissions.CanExportPersonnelCandidates,
			Permissions.CanEditCandidates,
			Permissions.CanAnonymiseCandidates,
			Permissions.CandidateComplianceManagerAssigned,
			Permissions.CandidateComplianceDashboard,
			Permissions.CanManageBookings,
			Permissions.CanDeleteBookings,
			Permissions.CanAddBookings,
			Permissions.CanExportBookings,
			Permissions.CanEditBookings,
			Permissions.CanManageBookingSplits,
			Permissions.CanManageTutorBookings,
			Permissions.CanManageSearchSelect,
			Permissions.CanAddSearchSelect,
			Permissions.CanEditSearchSelect,
			Permissions.CanDeleteSearchSelect,
			Permissions.CanManageAvailabilityManager,
			Permissions.CanAddAvailabilityManager,
			Permissions.CanEditAvailabilityManager,
			Permissions.CanManageTimesheets,
			Permissions.CanAddTimesheets,
			Permissions.CanEditTimesheets,
			Permissions.CanDeleteTimesheets,
			Permissions.CanManageMarketing,
			Permissions.CanAddMarketingTemplate,
			Permissions.CanEditMarketingTemplate,
			Permissions.CanAddMarketingSingleSend,
			Permissions.CanViewStats,
			Permissions.CanViewStatsDaysOut,
			Permissions.CanViewStatsBookings,
			Permissions.CanViewStatsAverageMargin,
			Permissions.CanViewStatsProfit,
			Permissions.DefaultStatsToIndividual,
			Permissions.CanViewStatsCommision,
			Permissions.CanViewAnalytics,
			Permissions.CanViewAuditTrail,
		];
	} else if (role === UserRole.Compliance) {
		return [
			Permissions.CanManageClients,
			Permissions.CanAddClients,
			Permissions.CanEditClients,
			Permissions.CanDeleteClients,
			Permissions.CanViewAllClients,
			Permissions.CanExportClients,
			Permissions.CanExportPersonnelClients,
			Permissions.CanManageCandidates,
			Permissions.CanAddCandidates,
			Permissions.CanEditCandidates,
			Permissions.CanAnonymiseCandidates,
			Permissions.CanExportCandidates,
			Permissions.CanExportPersonnelCandidates,
			Permissions.CanDeleteCandidates,
			Permissions.CandidateComplianceManager,
			Permissions.CandidateComplianceDashboard,
			Permissions.CandidateComplianceManagerEdit,
			Permissions.CanManageBookings,
			Permissions.CanAddBookings,
			Permissions.CanExportBookings,
			Permissions.CanDeleteBookings,
			Permissions.CanEditBookings,
			Permissions.CanManageBookingSplits,
			Permissions.CanManageTutorBookings,
			Permissions.CanManageSearchSelect,
			Permissions.CanAddSearchSelect,
			Permissions.CanEditSearchSelect,
			Permissions.CanDeleteSearchSelect,
			Permissions.CanManageAvailabilityManager,
			Permissions.CanAddAvailabilityManager,
			Permissions.CanEditAvailabilityManager,
			Permissions.CanManageTimesheets,
			Permissions.CanAddTimesheets,
			Permissions.CanEditTimesheets,
			Permissions.CanDeleteTimesheets,
			Permissions.CanManageMarketing,
			Permissions.CanAddMarketingSingleSend,
			Permissions.CanAddMarketingTemplate,
			Permissions.CanEditMarketingTemplate,
			Permissions.CanDeleteMarketingTemplate,
			Permissions.CanViewAuditTrail,
			Permissions.CanViewStats,
			Permissions.CanViewStatsDaysOut,
			Permissions.CanViewStatsBookings,
			Permissions.CanViewStatsAverageMargin,
			Permissions.CanViewStatsProfit,
			Permissions.DefaultStatsToIndividual,
			Permissions.CanViewStatsCommision,
			Permissions.CanViewAnalytics,
		];
	} else if (role === UserRole.Manager) {
		return [
			Permissions.CanManageCustomer,
			Permissions.CanEditCustomer,
			Permissions.CanManageCustomerUsers,
			Permissions.CanAddCustomerUsers,
			Permissions.CanEditCustomerUsers,
			Permissions.CanManageClients,
			Permissions.CanExportPersonnelClients,
			Permissions.CanAddClients,
			Permissions.CanExportClients,
			Permissions.CanEditClients,
			Permissions.CanViewAllClients,
			Permissions.CanDeleteClients,
			Permissions.CanAddBookingsAllClients,
			Permissions.CanManageCandidates,
			Permissions.CanDeleteCandidates,
			Permissions.CanExportPersonnelCandidates,
			Permissions.CanAddCandidates,
			Permissions.CanEditCandidates,
			Permissions.CanExportCandidates,
			Permissions.CanAnonymiseCandidates,
			Permissions.CandidateComplianceManager,
			Permissions.CandidateComplianceDashboard,
			Permissions.CandidateComplianceManagerEdit,
			Permissions.CanManageBookings,
			Permissions.CanDeleteBookings,
			Permissions.CanAddBookings,
			Permissions.CanExportBookings,
			Permissions.CanEditBookings,
			Permissions.CanManageBookingSplits,
			Permissions.CanManageAllBookings,
			Permissions.CanManageTutorBookings,
			Permissions.CanManageSearchSelect,
			Permissions.CanManageAllSearchSelect,
			Permissions.CanAddSearchSelect,
			Permissions.CanEditSearchSelect,
			Permissions.CanDeleteSearchSelect,
			Permissions.CanManageAvailabilityManager,
			Permissions.CanAddAvailabilityManager,
			Permissions.CanEditAvailabilityManager,
			Permissions.CanManageTimesheets,
			Permissions.CanAddTimesheets,
			Permissions.CanEditTimesheets,
			Permissions.CanDeleteTimesheets,
			Permissions.CanManageMarketing,
			Permissions.CanAddMarketingSingleSend,
			Permissions.CanAddMarketingTemplate,
			Permissions.CanDeleteMarketingTemplate,
			Permissions.CanEditMarketingTemplate,
			Permissions.CanManageInvoices,
			Permissions.CanManageJobBoards,
			Permissions.CanCreateInvoice,
			Permissions.CanEditInvoice,
			Permissions.CanManagePayments,
			Permissions.CanCreatePayments,
			Permissions.CanEditPayments,
			Permissions.CanDeletePayments,
			Permissions.CanViewAuditTrail,
			Permissions.CanViewStats,
			Permissions.CanViewStatsDaysOut,
			Permissions.CanViewStatsBookings,
			Permissions.CanViewStatsAverageMargin,
			Permissions.CanViewStatsProfit,
			Permissions.CanToggleStats,
			Permissions.CanViewBranchStats,
			Permissions.CanToggleUsers,
			Permissions.CanViewStatsCommision,
			Permissions.CanViewStatsCommisionCompany,
			Permissions.CanViewAnalytics,
			Permissions.CanViewAnalyticsCompany,
			Permissions.CanViewAnalyticsFinancial,
			Permissions.CanViewUserTargetReporting,
		];
	} else if (role === UserRole.SuperAdministrator) {
		return [
			Permissions.CanManageCustomer,
			Permissions.CanEditCustomer,
			Permissions.CanManageCustomerUsers,
			Permissions.CanAddCustomerUsers,
			Permissions.CanEditCustomerUsers,
			Permissions.CanManageClients,
			Permissions.CanExportPersonnelClients,
			Permissions.CanAddClients,
			Permissions.CanExportClients,
			Permissions.CanEditClients,
			Permissions.CanViewAllClients,
			Permissions.CanDeleteClients,
			Permissions.CanAddBookingsAllClients,
			Permissions.CanManageCandidates,
			Permissions.CanDeleteCandidates,
			Permissions.CanExportPersonnelCandidates,
			Permissions.CanAddCandidates,
			Permissions.CanEditCandidates,
			Permissions.CanExportCandidates,
			Permissions.CanAnonymiseCandidates,
			Permissions.CandidateComplianceManager,
			Permissions.CandidateComplianceDashboard,
			Permissions.CandidateComplianceManagerEdit,
			Permissions.CanManageBookings,
			Permissions.CanDeleteBookings,
			Permissions.CanAddBookings,
			Permissions.CanExportBookings,
			Permissions.CanEditBookings,
			Permissions.CanManageBookingSplits,
			Permissions.CanManageAllBookings,
			Permissions.CanManageTutorBookings,
			Permissions.CanManageSearchSelect,
			Permissions.CanManageAllSearchSelect,
			Permissions.CanAddSearchSelect,
			Permissions.CanEditSearchSelect,
			Permissions.CanDeleteSearchSelect,
			Permissions.CanManageAvailabilityManager,
			Permissions.CanAddAvailabilityManager,
			Permissions.CanEditAvailabilityManager,
			Permissions.CanManageTimesheets,
			Permissions.CanAddTimesheets,
			Permissions.CanEditTimesheets,
			Permissions.CanDeleteTimesheets,
			Permissions.CanManageMarketing,
			Permissions.CanAddMarketingSingleSend,
			Permissions.CanAddMarketingTemplate,
			Permissions.CanDeleteMarketingTemplate,
			Permissions.CanEditMarketingTemplate,
			Permissions.CanManageInvoices,
			Permissions.CanManageJobBoards,
			Permissions.CanCreateInvoice,
			Permissions.CanEditInvoice,
			Permissions.CanManagePayments,
			Permissions.CanCreatePayments,
			Permissions.CanEditPayments,
			Permissions.CanDeletePayments,
			Permissions.CanViewAuditTrail,
			Permissions.CanViewStats,
			Permissions.CanViewStatsDaysOut,
			Permissions.CanViewStatsBookings,
			Permissions.CanViewStatsAverageMargin,
			Permissions.CanViewStatsProfit,
			Permissions.CanToggleStats,
			Permissions.CanViewBranchStats,
			Permissions.CanToggleUsers,
			Permissions.CanViewStatsCommision,
			Permissions.CanViewStatsCommisionCompany,
			Permissions.CanViewAnalytics,
			Permissions.CanViewAnalyticsCompany,
			Permissions.CanViewAnalyticsFinancial,
			Permissions.CanViewUserTargetReporting,
			Permissions.CanViewAnalyticsFinancialLoss,
			Permissions.CanExportAnalytics,
			Permissions.CanEditInvoiceRaisedTimesheet,
		];
	} else {
		return [];
	}
};
