import { EmailPreferencesEnum } from "../_enums/emailPreference.enum";

export interface IEmailPreference {
	user: string;
	preferences: EmailPreferencesEnum[];
}

export class EmailPreferenceModel implements IEmailPreference {
	user: string;
	preferences: EmailPreferencesEnum[];

	constructor(data: IEmailPreference) {
		this.user = data?.user ?? "";
		this.preferences = data?.preferences ?? [];
	}
}

export const BaseEmailPreferences = [
	EmailPreferencesEnum.CandidateOnboardingComplete,
	EmailPreferencesEnum.CandidateOnboardingDocumentComplete,
	EmailPreferencesEnum.CandidateOnboardingReferenceComplete,
	EmailPreferencesEnum.CandidateOnboardingBankComplete,
	EmailPreferencesEnum.TimesheetAssignee,
	EmailPreferencesEnum.CandidateAssignee,
	EmailPreferencesEnum.BookingAssignee,
	EmailPreferencesEnum.BookingPlaceholderAssignee,
	EmailPreferencesEnum.TimesheetCandidateStatusUpdate,
	EmailPreferencesEnum.TimesheetClientStatusUpdate,
	EmailPreferencesEnum.TimesheetCandidateQueryUpdate,
	EmailPreferencesEnum.TimesheetClientQueryUpdate,
	EmailPreferencesEnum.DocumentExpiry,
	EmailPreferencesEnum.BookingInterviewAttendanceUpdate,
	EmailPreferencesEnum.BookingTrialAttendanceUpdate,
];

export enum EmailSmsPreferenceTypes {
	CandidateOnboardingEmail = 1,
	CandidateOnboardingSms = 2,
	CandidateOnboardingReferenceEmail = 3,
	CandidateOnboardingReferenceSms = 4,
	CandidateOnboardingBankEmail = 5,
	CandidateOnboardingBankSms = 6,
	CandidateOnboardingDocumentEmail = 7,
	CandidateOnboardingDocumentSms = 8,
	CandidateSms = 9,
	CandidateDocumentEmail = 10,
	CandidateDocumentSms = 11,
	CandidateAvailabilityEmail = 100,
	CandidateAvailabilitySms = 101,
	CandidateTimesheetEmail = 200,
	CandidateTimesheetSms = 201,
	ClientTimesheetEmail = 300,
	ClientTimesheetSms = 301,
	CandidateReferenceSubmissionEmail = 401,
	CandidateReferenceSubmissionSms = 402,
	PlacementManagerCandidateInterestEmail = 501,
	PlacementManagerCandidateInterestSms = 502,
	PlacementManagerClientInterestEmail = 503,
	PlacementManagerClientinterestSms = 504,
	CandidateBookingConfirmationEmail = 601,
	CandidateBookingConfirmationSms = 602,
	ClientBookingConfirmationEmail = 603,
	ClientBookingConfirmationSms = 604,
	CandidateInterviewConfirmationEmail = 701,
	CandidateInterviewConfirmationSms = 702,
}

export interface IEmailNotificationPreference {
	preferences: EmailSmsPreferenceTypes[];
}

export class EmailNotificationPreferenceModel implements IEmailNotificationPreference {
	preferences: EmailSmsPreferenceTypes[];

	constructor(data: IEmailNotificationPreference) {
		this.preferences = data?.preferences ?? [];
	}
}
