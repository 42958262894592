import { TOGGLE_LOADING, SET_USER, SET_ALERT, TOGGLE_NAVIGATION } from "../actions";

export function toggleLoading(loading) {
	return {
		type: TOGGLE_LOADING,
		loading,
	};
}

export function setUser(user) {
	return {
		type: SET_USER,
		user,
	};
}

export function setAlert(alert) {
	return {
		type: SET_ALERT,
		alert,
	};
}

export function toggleNavigation(navigationHidden) {
	return {
		type: TOGGLE_NAVIGATION,
		navigationHidden,
	};
}
