import styled from 'styled-components';
import { RelSize } from '../mixins'

const ButtonIconStyled = styled.i`
    display: block;
    height: ${RelSize(12, 14)};
    width: ${RelSize(12, 14)};
    svg {
        display: block;
        height: 100%;
        width: 100%;
    }
`;

export default ButtonIconStyled;