import styled from 'styled-components';
import { RelSize } from '../mixins';
import theme from '../theme';
import { ExportCtaAnchor } from '../type/buttons';
import { HelpWrapperStyled } from '../forms/inputs';

export const LineItemsWrapper = styled.div`
    position: relative;
	${props => props.divider && `
		border-top: 1px solid ${theme.borderGrey};
		margin-top: ${RelSize(20, 16)};
		padding-top: ${RelSize(20, 16)};
	`}
	${props => props.dividerBottom && `
		border-bottom: 1px solid ${theme.borderGrey};
		margin-bottom: ${RelSize(20, 16)};
		padding-bottom: ${RelSize(20, 16)};
	`}
	${props => props.pushTop && `
		margin-top: ${RelSize(50, 16)};
	`}
`;

export const LineItemCol = styled.div`
	${props => props.help && `
		padding-right: ${RelSize(25, 16)};
	`}
	position: relative;
	span {
		&:first-child {
			display: block;
			font-weight: 700;
			margin-bottom: ${RelSize(5, 16)};
			padding-bottom: ${RelSize(5, 16)};
		}
		&:last-child {
			font-size: ${RelSize(14, 16)};
			text-align: left;
			${props => props.wrap && `
				word-break: break-all;
			`}
		}
		&.lineItemColAction {
			color: ${theme.red};
			font-size: ${RelSize(14, 14)};
			font-weight: 400;
			margin: ${RelSize(5, 14)} 0 0 0;
			padding: 0;
		}
		${ExportCtaAnchor} {
			color: ${theme.red};
			font-size: ${RelSize(14, 14)};
			justify-content: flex-start;
			margin: 0;
			padding-left: 0;
		}
	}
	text-align: ${props => props.align ?? 'left'};
	.inputHelp {
		display: inline;
		font-size: inherit;
		font-weight: inherit;
		margin-bottom: 0;
		padding-bottom: 0;
		padding-right: ${RelSize(30, 16)};
		position: relative;
		${HelpWrapperStyled} {
			top: -${RelSize(5, 14)};
		}
	}
	.lineitemInvoiceHighlight {
		color: ${theme.trafficLightRed};
		text-decoration: none;
	}
`;

export const LineItem = styled.div`
	&:not(:first-child) {
		${LineItemCol} {
			span {
				&:first-child {
					&:not(.lineItemColAction) {
						display: none;
					}
				}
			}
		}
	}
	&:not(:last-child) {
		border-bottom: ${props => props.flush ? "none" : `1px solid ${theme.borderGrey}`};
		margin-bottom: ${props => props.flush ? RelSize(5, 16) : RelSize(20, 16)};
		padding-bottom: ${props => props.flush ? RelSize(5, 16) : RelSize(20, 16)};
	}
	${({ highlight }) => highlight && `
		color: ${theme.red};
		font-weight: 700;
	`}
`;