import { InputError, InputWarning } from "../../../styles/forms/inputs";

const Error = (props) => (
	<InputError small={true} inline={props.inline}>
		{props.message}
	</InputError>
);

export const Warning = (props) => (
	<InputWarning small={true} inline={props.inline} pushBottom={props.pushBottom}>
		{props.message}
	</InputWarning>
);

export default Error;
