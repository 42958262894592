export class TableModel {
	pageNo?: number;
	showAll?: boolean;
	records?: any;
	resultItemsFiltered?: any;
	resultItemsFilteredPrePaginate?: any;
	loading?: boolean;
	paginationItems?: any;
	pageSize?: number;
	totalRecords?: number;
	filters?: any;
	sortBy?: string;
	sortDir?: string;
	order?: any;

	constructor(data: any = {}) {
		this.pageNo = data.pageNo || 1;
		this.showAll = data.showAll || false;
		this.records = data.records || [];
		this.resultItemsFiltered = data.resultItemsFiltered || [];
		this.resultItemsFilteredPrePaginate = data.resultItemsFilteredPrePaginate || [];
		this.loading = data.loading || false;
		this.paginationItems = data.paginationItems || {};
		this.pageSize = data.pageSize || 10;
		this.totalRecords = data.totalRecords || 0;
		this.sortBy = data.sortBy || "";
		this.sortDir = data.sortDir || "";
		this.filters = data.filters || [];
		this.order = data.order || null;
	}
}
