import styled from 'styled-components';
import { RelSize, Ratio } from '../mixins'
import theme from '../theme'

export const ModalStyles = {
	overlay: {
		backgroundColor: 'rgba(56, 76, 68, 0.5)',
		zIndex: 99999
	},
	content: {
		alignItems: 'center',
		backgroundColor: 'transparent',
		border: '0',
		display: 'flex',
		justifyContent: 'center',
		top: '50%',
		left: '50%',
		maxWidth: RelSize(768, 16),
		overflow: 'initial',
		transform: 'translate(-50%, -50%)',
		width: '100%'
	}
};

export const ModalTopStyles = {
	overlay: {
		backgroundColor: 'rgba(56, 76, 68, 0.5)',
		zIndex: 999999
	},
	content: {
		alignItems: 'center',
		backgroundColor: 'transparent',
		border: '0',
		display: 'flex',
		justifyContent: 'center',
		top: '50%',
		left: '50%',
		maxWidth: RelSize(768, 16),
		overflow: 'initial',
		transform: 'translate(-50%, -50%)',
		width: '100%'
	}
};

export const ModalStylesLarge = {
	overlay: {
		backgroundColor: 'rgba(56, 76, 68, 0.5)',
		zIndex: 99999
	},
	content: {
		alignItems: 'center',
		backgroundColor: 'transparent',
		border: '0',
		display: 'flex',
		justifyContent: 'center',
		top: '50%',
		left: '50%',
		maxWidth: RelSize(1240, 16),
		overflow: 'initial',
		transform: 'translate(-50%, -50%)',
		width: '100%'
	}
};

export const ExternalModalStylesLarge = {
	overlay: {
		backgroundColor: 'rgba(28,44,91,.5)',
		zIndex: 99999
	},
	content: {
		alignItems: 'center',
		backgroundColor: 'transparent',
		border: '0',
		display: 'flex',
		justifyContent: 'center',
		top: '50%',
		left: '50%',
		maxWidth: RelSize(768, 16),
		overflow: 'initial',
		transform: 'translate(-50%, -50%)',
		width: '100%'
	}
};

export const RegisterStyles = {
	overlay: {
		backgroundColor: 'rgba(56, 76, 68, 0.8)',
		zIndex: 99999
	},
	content: {
		alignItems: 'center',
		backgroundColor: 'transparent',
		border: '0',
		display: 'flex',
		justifyContent: 'center',
		top: '50%',
		left: '50%',
		maxWidth: RelSize(400, 16),
		overflow: 'initial',
		transform: 'translate(-50%, -50%)',
		width: '100%'
	}
};

export const ModalWrapper = styled.div`
    background-color: ${theme.white};
    border-bottom: 2px solid ${props => props.external ? "transparent" : theme.primaryGreen};
	border-radius: ${props => props.external ? 0 : RelSize(10, 16)};
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    width: 100%;
	scrollbar-width: auto;
	scrollbar-color: ${theme.primaryGreen} transparent;
	&::-webkit-scrollbar {
	  width: 10px;
	}
	&::-webkit-scrollbar-track {
	  background: transparent;
	}
	&::-webkit-scrollbar-thumb {
	  background: ${theme.primaryGreen};
	}
`;

export const RegisterModalWrapper = styled.div`
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    width: 100%;
	scrollbar-width: thin;
	scrollbar-color: ${theme.primaryGreen} transparent;
	&::-webkit-scrollbar {
	  width: 5px;
	}
	&::-webkit-scrollbar-track {
	  background: transparent;
	}
	&::-webkit-scrollbar-thumb {
	  background: ${theme.primaryGreen};
	}
`;

export const ModalClose = styled.a`
    align-items: center;
    color: ${theme.white};
    cursor: pointer;
    display: flex;
    font-size: ${RelSize(16, 16)};
    font-weight: 700;
	line-height: ${RelSize(48, 16)};
    justify-content: center;
	margin-left: ${RelSize(10, 16)};
`;

export const ModalHeader = styled.div`
	align-items: flex-start;
	background-color: ${theme.primaryGreen};
	display: flex;
	padding: ${RelSize(20, 16)};
	h2 {
		color: ${props => theme.white};
		flex: 1;
		margin: 0;
	}
`;

export const RegisterModalHeader = styled.div`
	margin: 0 0 ${RelSize(50, 16)};
	h2 {
		color: ${props => props.theme.primaryGreen};
		font-size: ${RelSize(16, 16)};
		font-weight: 700;
		line-height: ${Ratio(16)};
	}
	p {
		color: ${props => props.theme.secondaryGrey};
		font-size: ${RelSize(14, 16)};
	}
`;

export const ModalContent = styled.div`
	padding: ${props => props.flush ? 0 : RelSize(20, 16)};
	iframe {
		height: 90vh;
		width: 100%;
	}
`;

export const ModalHelpContent = styled.div`
	font-size: ${RelSize(14, 16)};
	img {
		display: block;
		width: 100%;
	}
`;

export const ModalContentScroll = styled.div`
	max-height: calc(90vh - ${RelSize(200, 16)});
	overflow-y: auto;
	position: relative;
	width: 100%;
	scrollbar-width: thin;
	scrollbar-color: ${theme.primaryGreen} transparent;
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: ${theme.primaryGreen};
	}
`;

export const RegisterModalContent = styled.div`
	background-color: ${theme.backgroundGrey};
	border-radius: ${RelSize(30, 16)};
	padding: ${props => props.flush ? 0 : RelSize(20, 16)};
`;

export const ModalButtons = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: ${RelSize(20, 16)};
    > * {
        &:not(:last-child) {
            margin-right: ${RelSize(10, 16)};
        }
    }
`;

export const ModalFooter = styled.div`
	align-items: center;
	border-top: 1px solid ${theme.borderGrey};
	display: flex;
	justify-content: ${props => props.single ? "flex-end" : "space-between"};
	padding: ${RelSize(20, 16)};
	@media all and (max-width: 768px) {
		flex-direction: column;
	}
	> div {
		@media all and (max-width: 768px) {
			margin-bottom: 20px;
			width: 100%;
			> * {
				margin-bottom: 0;
			}
		}
	}
`;

export const RegisterModalFooter = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	padding: ${RelSize(20, 16)};
	a {
		color: ${props => props.theme.white};
		font-size: ${RelSize(14, 16)};
		text-decoration: underline;
	}
`;

export const ModalGroup = styled.div`
	${props => props.borderLeft && `
		&::after {
			background-color: ${theme.borderGrey}
			bottom: 0;
			content: "";
			left: -1px;
			position: absolute;
			top: 0;
			width: 2px;
		}
	`}
	${props => props.borderRight && `
		&::after {
			background-color: ${theme.borderGrey}
			bottom: 0;
			content: "";
			position: absolute;
			right: -1px;
			top: 0;
			width: 2px;
		}
	`}
	${props => props.borderBottom && `
		&::before {
			background-color: ${theme.borderGrey}
			bottom: -1px;
			content: "";
			height: 2px;
			left: 0;
			position: absolute;
			right: 0;
		}
	`}
	padding: ${props => props.flush ? 0 : RelSize(20, 16)};
	position: relative;
`;