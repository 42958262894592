import styled from 'styled-components'
import { RelSize } from '../mixins'

const ColStyled = styled.div`
    display: flex;
    flex-basis: ${props => props.span * 8.333333333333333}%;
    flex-direction: column;
    flex-grow: ${props => props.grow ? 1 : 0};
    flex-shrink: 0;
	height: ${props => props.fillSpace ? "100%" : "auto"};
    padding: 0 ${props => props.flush ? 0 : "10px"};
    margin-bottom: ${props => props.base ? RelSize(props.base, 16) : 0};
    margin-left: ${props => props.offsetLeft ? props.offsetLeft * 8.333333333333333 : "0"}%;
    margin-right: ${props => props.offsetRight ? props.offsetRight * 8.333333333333333 : "0"}%;
    min-width: ${props => props.minWidth ? RelSize(props.minWidth, 16) : 0};
    max-width: ${props => props.grow ? "none" : `${props.span * 8.333333333333333}%`};
    @media all and (max-width: 1024px) {
        padding: 0 ${props => props.flush ? 0 : "5px"};
    }
	${props => props.center && `
        justify-content: center;
    `}
	${props => props.span1600 && `
        @media (max-width: 1600px) {
            flex-basis: ${props.span1600 * 8.333333333333333}%;
            margin-left: ${props.offsetLeft1660 ? props.offsetLeft1660 * 8.333333333333333 : "0"}%;
            margin-right: ${props.offsetLeft1660 ? props.offsetLeft1660 * 8.333333333333333 : "0"}%;
            max-width: ${props.grow ? "none" : `${props.span1600 * 8.333333333333333}%`};
        }
    `}
    ${props => props.span1440 && `
        @media (max-width: 1440px) {
            flex-basis: ${props.span1440 * 8.333333333333333}%;
            margin-left: ${props.offsetLeft1440 ? props.offsetLeft1440 * 8.333333333333333 : "0"}%;
            margin-right: ${props.offsetLeft1440 ? props.offsetLeft1440 * 8.333333333333333 : "0"}%;
            max-width: ${props.grow ? "none" : `${props.span1440 * 8.333333333333333}%`};
        }
    `}
    ${props => props.span1024 && `
        @media (max-width: 1024px) {
            flex-basis: ${props.span1024 * 8.333333333333333}%;
            margin-left: ${props.offsetLeft1024 ? props.offsetLeft1024 * 8.333333333333333 : "0"}%;
            margin-right: ${props.offsetRight1024 ? props.offsetRight1024 * 8.333333333333333 : "0"}%;
            max-width: ${props.grow ? "none" : `${props.span1024 * 8.333333333333333}%`};
        }
    `}
    ${props => props.span768 && `
        @media (max-width: 768px) {
            flex-basis: ${props.span768 * 8.333333333333333}%;
            margin-left: ${props.offsetLeft768 ? props.offsetLeft768 * 8.333333333333333 : "0"}%;
            margin-right: ${props.offsetRight768 ? props.offsetRight768 * 8.333333333333333 : "0"}%;
            max-width: ${props.grow ? "none" : `${props.span768 * 8.333333333333333}%`};
        }
    `}
    ${props => props.span480 && `
        @media (max-width: 480px) {
            flex-basis: ${props.span480 * 8.333333333333333}%;
            margin-left: ${props.offsetLeft480 ? props.offsetLeft480 * 8.333333333333333 : "0"}%;
            margin-right: ${props.offsetRight480 ? props.offsetRight480 * 8.333333333333333 : "0"}%;
            max-width: ${props.grow ? "none" : `${props.span480 * 8.333333333333333}%`};
        }
    `}
    ${props => props.order1024 && `
        @media (max-width: 1024px) {
            order: ${props.order1024}
        }
    `}
	${props => props.order768 && `
        @media (max-width: 768px) {
            order: ${props.order768}
        }
    `}
`;

export default ColStyled;