import { IFilter } from "../../_models/data.filter.model";
import { ITarget } from "../../_models/data.target.model";
import { GET_USER_TARGETS, ADD_TARGET, UPDATE_TARGET } from "../../config/endpoints";
import { HeadersAuth, HTTP, IHttpResponse } from "./http";

export const GetUserTargets = (Token: string, USER_ID: string, DATA?: IFilter) => {
	return HTTP({
		Method: "POST",
		Url: GET_USER_TARGETS(USER_ID),
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const AddTarget = (Token: string, DATA: ITarget) => {
	return HTTP({
		Method: "POST",
		Url: ADD_TARGET,
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const UpdateTarget = (Token: string, DATA: ITarget, TARGET_ID: string) => {
	return HTTP({
		Method: "PUT",
		Url: UPDATE_TARGET(TARGET_ID),
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const DeleteTarget = (Token: string, TARGET_ID: string) => {
	return HTTP({
		Method: "DELETE",
		Url: UPDATE_TARGET(TARGET_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};
