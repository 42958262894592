import styled from 'styled-components';

const NotificationIcon = styled.i`
    height: ${props => props.large ? "25px" : "18px"};
    width: ${props => props.large ? "25px" : "18px"};
    svg {
        display: block;
        height: 100%;
        width: 100%;
    }
`;

export const NotificationStatusIcon = styled.i`
	height: 14px;
	margin-left: 5px;
	width: 14px;
	svg {
		display: block;
		height: 100%;
		width: 100%;
}
`;

export default NotificationIcon;