import { IBranch } from "../../_models/data.branch.model";
import { IFilter } from "../../_models/data.filter.model";
import { IBranchUsers } from "../../components/forms/branches/users";
import { IUserBranches } from "../../components/forms/user/branches";
import {
	BRANCHES,
	BRANCHES_STUB,
	ADD_BRANCH,
	UPDATE_BRANCH,
	GET_BRANCH_USERS,
	ASSIGN_BRANCH_USERS,
	ASSIGN_USER_BRANCHES,
	UNASSIGN_BRANCH_USERS,
	UNASSIGN_USER_BRANCHES,
	GET_USER_BRANCHES,
	GET_SUBSCRIPTION_BRANCHES_STUB,
} from "../../config/endpoints";
import { HeadersAuth, HTTP, IHttpResponse } from "./http";

export const GetBranches = (Token: string, DATA?: IFilter) => {
	return HTTP({
		Method: "POST",
		Url: BRANCHES,
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const GetBranchesStub = (Token: string, DATA?: IFilter) => {
	return HTTP({
		Method: "POST",
		Url: BRANCHES_STUB,
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const GetBranch = (Token: string, BRANCH_ID: string) => {
	return HTTP({
		Method: "GET",
		Url: UPDATE_BRANCH(BRANCH_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetBranchUsers = (Token: string, BRANCH_ID: string, DATA?: IFilter) => {
	return HTTP({
		Method: "POST",
		Url: GET_BRANCH_USERS(BRANCH_ID),
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const GetUserBranches = (Token: string, USER_ID: string, DATA?: IFilter) => {
	return HTTP({
		Method: "POST",
		Url: GET_USER_BRANCHES(USER_ID),
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const AddBranch = (Token: string, DATA: IBranch) => {
	return HTTP({
		Method: "POST",
		Url: ADD_BRANCH,
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const UpdateBranch = (Token: string, DATA: IBranch, BRANCH_ID: string) => {
	return HTTP({
		Method: "PUT",
		Url: UPDATE_BRANCH(BRANCH_ID),
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const AssignBranchUsers = (Token: string, BRANCH_ID: string, DATA: IBranchUsers) => {
	return HTTP({
		Method: "POST",
		Url: ASSIGN_BRANCH_USERS(BRANCH_ID),
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const AssignUserBranches = (Token: string, USER_ID: string, DATA: IUserBranches) => {
	return HTTP({
		Method: "POST",
		Url: ASSIGN_USER_BRANCHES(USER_ID),
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const UnassignBranchUsers = (Token: string, BRANCH_ID: string, DATA: IBranchUsers) => {
	return HTTP({
		Method: "POST",
		Url: UNASSIGN_BRANCH_USERS(BRANCH_ID),
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const UnassignUserBranches = (Token: string, USER_ID: string, DATA: IUserBranches) => {
	return HTTP({
		Method: "POST",
		Url: UNASSIGN_USER_BRANCHES(USER_ID),
		Headers: HeadersAuth(Token),
		Data: DATA ?? {},
	}) as Promise<IHttpResponse>;
};

export const DeleteBranch = (Token: string, BRANCH_ID: string) => {
	return HTTP({
		Method: "DELETE",
		Url: UPDATE_BRANCH(BRANCH_ID),
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};

export const GetSubscriptionBranches = (Token: string) => {
	return HTTP({
		Method: "GET",
		Url: GET_SUBSCRIPTION_BRANCHES_STUB,
		Headers: HeadersAuth(Token),
	}) as Promise<IHttpResponse>;
};
