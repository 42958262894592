import React, { useState, useEffect } from "react";
import { FormRow, FormRowInner, Label, ReadOnly, CanEdit, Note } from "../../../styles/forms/inputs";
import InputError from "../errors/input";
import moment from "moment";
import { EventTargetType } from "../../../_types/form";
import { PatternFormat } from "react-number-format";
import InputHelp from "./help";
import { IHelp } from "../../../state/context/modals.reducers";

type Props = {
	label?: string;
	light?: boolean;
	help?: IHelp;
	placeholder?: string;
	name: string;
	value: string | number | null | undefined;
	readonly?: boolean;
	error?: string;
	inline?: boolean;
	onChange: (e: EventTargetType) => void;
	required?: boolean;
	canEditReadOnly?: boolean;
	note?: string;
	type?: "text" | "email" | "tel" | "number" | "date";
	min?: number | string;
	max?: number | string;
	clear?: boolean;
};

export const IsValidTime = (time: string) => {
	const reg = /^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9](\.[0-9]{1,3})?)?$/i;
	if (time) {
		if (reg.exec(time)) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

export const IsEmptyTime = (time: string) => {
	if (!time.trim().replace(/:/g, "")?.length) {
		return true;
	} else {
		return false;
	}
};

const TimeFormatInput = ({
	value = null,
	onChange = null,
	onBlur = null,
	clear = false,
	setInputError,
}: {
	value?: string | null;
	onChange?: Function | null;
	onBlur?: Function | null;
	clear?: boolean;
	setInputError: (err: string) => void;
}) => {
	useEffect(() => {
		setTime((prevValue) => {
			if (value !== prevValue) return value;
			else return prevValue;
		});
	}, [value]);

	const [time, setTime] = useState(value);

	return (
		<PatternFormat
			value={time}
			onChange={(e) => {
				setTime(e.target.value);
				if (IsValidTime(e.target.value) && onChange) {
					onChange(e.target.value);
				} else {
					if (clear && IsEmptyTime(e.target.value)) {
						onChange("");
					}
				}
			}}
			onBlur={(e) => {
				if (e.target.value && !IsValidTime(e.target.value)) {
					setInputError("Invalid time");
				} else {
					setInputError(null);
				}
			}}
			format="##:##"
			displayType="input"
			allowEmptyFormatting={true}
		/>
	);
};

const checkDate = (date: Date | string | number) => {
	return moment(date).isValid();
};

const TimeInput: React.FC<Props> = ({ label, light, help, placeholder, name, value, readonly, error, inline, onChange, required, canEditReadOnly = null, note, type = "text", min, max, clear }) => {
	let timeout: any = null;

	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);
	const [isReadOnly, setReadOnly] = useState(readonly);

	const handleChange = (time: string) => {
		setInputValue(time);
		timeout = setTimeout(() => {
			clearTimeout(timeout);
			handleBlur({ target: { name, value: time } });
		}, 100);
	};

	const handleBlur = (e: EventTargetType) => {
		onChange(e);
	};

	useEffect(() => {
		setInputError(error);
		if (value !== inputValue) setInputValue(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, value]);

	useEffect(() => {
		if (readonly !== isReadOnly) setReadOnly(readonly);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readonly]);

	useEffect(() => {
		if (canEditReadOnly !== null && !canEditReadOnly) setReadOnly(true);
	}, [canEditReadOnly]);

	return (
		<FormRow hasError={inputError ? true : false}>
			<FormRowInner type={isReadOnly ? null : type} readonly={isReadOnly}>
				{label && (
					<Label light={light ? true : false} help={help}>
						{label}
						{required ? "*" : null}
						{help ? <InputHelp help={help} /> : null}
					</Label>
				)}
				{note && <Note>{note}</Note>}
				{isReadOnly ? (
					<ReadOnly canEdit={canEditReadOnly}>
						{checkDate(inputValue) ? moment(inputValue as string).format("HH:mm") : inputValue}
						{canEditReadOnly ? <CanEdit onClick={() => setReadOnly(!isReadOnly)} /> : null}
					</ReadOnly>
				) : (
					<TimeFormatInput value={inputValue as string} onChange={handleChange} onBlur={handleBlur} clear={clear} setInputError={setInputError} />
				)}
			</FormRowInner>
			{!inputError ? null : <InputError message={inputError} inline={inline} />}
		</FormRow>
	);
};
export default TimeInput;
