import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toggleLoading, setAlert } from "../../state/creators";
import MultiSelect from "../forms/inputs/multiSelect";
import { Form } from "../../styles/forms";
import { ModalWrapper, ModalContent, ModalClose, ModalHeader, ModalFooter } from "../../styles/modals";
import { H2 } from "../../styles/type/headings";
import { ButtonList, StandardButtonAnchor, StandardCancelAnchor } from "../../styles/type/buttons";
import { OptionsType } from "../../_types/form";
import { AlertType } from "../../state/reducers/setAlert";
import { useAuth } from "../../state/context/auth.store";
import { IHttpResponse } from "../../functions/http-functions/http";
import { ApiResultModel } from "../../_models/api.result.model";
import { GetBranchesStub } from "../../functions/http-functions/branches";
import { IBranch } from "../../_models/data.branch.model";

type Props = {
	name: string;
	closeModal: () => void;
	confirmButtonAction: (branches: string[]) => void;
	filteredBranches: string[];
	toggleLoading: (loading: boolean) => void;
	setAlert: (alert: AlertType) => void;
};

const AssignUserBranches = ({ name, closeModal, confirmButtonAction, filteredBranches, toggleLoading, setAlert }: Props) => {
	const { auth } = useAuth();

	const [options, setOptions] = useState<OptionsType[]>([]);
	const [branches, setBranches] = useState<string[]>([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		getUsers();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getUsers = async () => {
		try {
			toggleLoading(true);
			const res = await GetBranchesStub(auth.token);
			setOptions(
				res.data.map((branch: IBranch) => ({
					ID: branch._id,
					Name: branch.name,
				}))
			);
		} catch (err: unknown) {
			const error = err as IHttpResponse;
			const ErrorResult = new ApiResultModel(error);
			setAlert({ type: "error", message: ErrorResult.message });
			setError(ErrorResult.message);
		} finally {
			toggleLoading(false);
		}
	};

	const handleChange = ({ target }) => {
		setBranches(target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (branches.length) {
			confirmButtonAction(branches);
		} else {
			setError("You must select at least 1 branch to assign.");
		}
	};

	return (
		<>
			<ModalWrapper large>
				<ModalHeader>
					<H2>Assign Teams</H2>
					<ModalClose onClick={closeModal}>close</ModalClose>
				</ModalHeader>
				<ModalContent>
					<p>User: {name}</p>
					<Form>
						<MultiSelect
							label="Teams"
							name="branches"
							value={branches}
							onChange={handleChange}
							options={options.filter((option) => filteredBranches.includes(option.ID as string) === false)}
							error={error}
						/>
					</Form>
				</ModalContent>
				<ModalFooter>
					<ButtonList>
						<StandardCancelAnchor onClick={closeModal}>Cancel</StandardCancelAnchor>
					</ButtonList>
					<ButtonList justify="flex-end">
						<StandardButtonAnchor onClick={handleSubmit}>Assign Selected Teams</StandardButtonAnchor>
					</ButtonList>
				</ModalFooter>
			</ModalWrapper>
		</>
	);
};

const mapDispatchToProps = {
	toggleLoading,
	setAlert,
};

export default connect(null, mapDispatchToProps)(AssignUserBranches);
