import { TOGGLE_LOADING } from "../actions";

const initialState = {
	loading: false,
};

export function toggleLoading(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_LOADING:
			return Object.assign({}, state, {
				loading: action.loading,
			});
		default:
			return state;
	}
}
