export enum CandidatePay {
	Paye = 1,
	Umbrella = 2,
}

export enum CandidatePaymentIntervals {
	Monthly = "Monthly",
	Weekly = "Weekly",
	Biweekly = "Bi-Weekly",
}
