import { TableColumnIconStyled } from "../../styles/tables/table";
import theme from "../../styles/theme";

const TableColumnIcon = ({ type, onClick, dark }: { type: "minimise" | "maximise"; onClick: () => void; dark?: boolean }) => (
	<TableColumnIconStyled onClick={onClick} rotate={type === "maximise" ? true : false} data-tooltip-id="expand" data-tooltip-content={type === "maximise" ? "Expand" : "Collapse"}>
		<i>
			<svg viewBox="0 0 16 16">
				<path
					fill={dark ? theme.primaryGreen : "#fff"}
					d="M2 3a1 1 0 0 1 1 1v8a1 1 0 1 1-2 0V4a1 1 0 0 1 1-1Zm5.293 1.293a1 1 0 0 1 1.414 1.414L7.414 7H14a1 1 0 1 1 0 2H7.414l1.293 1.293a1 1 0 1 1-1.414 1.414L3.586 8l3.707-3.707Z"
				/>
			</svg>
		</i>
	</TableColumnIconStyled>
);

export default TableColumnIcon;
