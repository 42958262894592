import styled from 'styled-components'
import { RelSize } from '../mixins'

export const TableOptionsStyled = styled.div`
    display: flex;
	flex-direction: ${props => props.column ? "column" : "row"};
	@media all and (min-width: 1025px) {
		justify-content: ${props => props.justify ?? "flex-end"};
	}
	@media all and (max-width: 1024px) {
		justify-content: flex-end;
	}
    margin-bottom: ${({ flush }) => flush ? 0 : RelSize(20, 16)};
	@media all and (max-width: 1024px) {
		margin-bottom: ${RelSize(10, 16)};
	}
    > * {
        &:not(:last-child) {
            margin-right: ${RelSize(10, 16)};
        }
    }
`;

export const TableOptionsAdditionalFilters = styled.div`
	align-items: flex-end;
	display: flex;
	> * {
		margin-right: ${RelSize(10, 16)};
		@media all and (max-width: 768px) {
			margin-bottom: ${RelSize(10, 16)};
			margin-right: 0;
		}
	}
	@media all and (max-width: 768px) {
		align-items: flex-start;
		flex-direction: column;
	}
`;

export const FilterGroup = styled.div`
  display: flex;
  align-items: flex-end;
  
  > * {
    margin-right: ${RelSize(10, 16)};
  }
  
  > *:last-child {
    margin-right: 0;
  }

  @media all and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    
    > * {
      margin-right: 0;
      margin-bottom: ${RelSize(10, 16)};
      width: 100%;
    }
    
    > *:last-child {
      margin-bottom: 0;
    }
  }
`;