import * as React from "react";
import App, { AppProps } from "next/app";
import { Provider } from "react-redux";
import { store } from "../state/store";
import Page from "../components/layout/page";
import "../styles/alert.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-image-crop/dist/ReactCrop.css";
import { ModalsStoreProvider } from "../state/context/modal.store";
import { AuthStoreProvider } from "../state/context/auth.store";
import { FormQuestionsStoreProvider } from "../state/context/formQuestions.store";
import momentTz from "moment-timezone";
import { NotificationsStoreProvider } from "../state/context/notifications.store";
import Head from "next/head";
import { CandidateStatusesStoreProvider } from "../state/context/candidateStatus.store";

momentTz.tz.setDefault("Europe/London");

const dev = process.env.DOMAIN === ".dev-helloedun.co.uk";

interface MyAppProps extends AppProps {
	index: boolean;
}

class MyApp extends App<MyAppProps> {
	static async getInitialProps({ Component, ctx }) {
		let pageProps = {};

		if (Component.getInitialProps) {
			pageProps = await Component.getInitialProps(ctx);
		}

		const index = ctx.pathname === "/" || ctx.pathname === "/about" ? true : false;

		return { pageProps, index };
	}

	render() {
		const { Component, pageProps, index } = this.props;

		return (
			<>
				<Head>
					<meta name="robots" content={index && !dev ? "index" : "noindex,nofollow"} />
					<title>Edun | The professional network in education.</title>
				</Head>
				<AuthStoreProvider me={pageProps?.me}>
					<Provider store={store}>
						<NotificationsStoreProvider>
							<ModalsStoreProvider>
								<FormQuestionsStoreProvider>
									<CandidateStatusesStoreProvider me={pageProps?.me}>
										<>
											<Page>
												<Component {...pageProps} />
											</Page>
											<div id="helpModalParent" />
										</>
									</CandidateStatusesStoreProvider>
								</FormQuestionsStoreProvider>
							</ModalsStoreProvider>
						</NotificationsStoreProvider>
					</Provider>
				</AuthStoreProvider>
			</>
		);
	}
}

export default MyApp;
