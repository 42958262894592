import Cookies from "js-cookie";
import { FilterTableType } from "../_enums/table.enum";
import { DecodeObjectToBase64, EncodeObjectToBase64 } from "../functions/helpers";
import { ITag } from "./data.tag.model";

export interface IFilter {
	pageOptions: any;
	filterOptions: any;
	orderOptions: any;
	filterType?: FilterTableType;
	tags?: ITag[];
	tagItems?: ITag[];
}

export class FilterModel {
	pageOptions: any;
	filterOptions: any;
	orderOptions: any;
	tagOptions?: string;

	constructor(pageOptions: any = {}, filterOptions: any = {}, orderOptions: any = {}, filterType?: FilterTableType, tags?: ITag[]) {
		this.pageOptions = pageOptions || { pageNo: 1, pageSize: 10 };
		this.filterOptions = filterOptions || null;
		this.orderOptions = orderOptions ? (isEmptyObject(orderOptions) ? null : orderOptions) : null;
		this.tagOptions = tags?.length ? tags.map((tag) => tag._id).join(",") : null;

		if (filterType) {
			this.storeFilter(filterType, pageOptions, filterOptions, orderOptions, tags?.length ? tags.map((tag) => tag._id).join(",") : null, tags?.length ? tags : null);
		}
	}

	storeFilter = (filterType, pageOptions, filterOptions, orderOptions, tagOptions, tagItems) => {
		if (window) {
			// Clean filterOptions by removing lat/lng items
			const cleanedFilterOptions = filterOptions ? filterOptions.filter((item) => item.column !== "lat" && item.column !== "lng") : null;

			let existingPagination = Cookies.get("ed_pg_ft");
			if (existingPagination) {
				existingPagination = DecodeObjectToBase64(existingPagination);
				existingPagination = {
					...existingPagination,
					[filterType]: {
						pageOptions,
						filterOptions: cleanedFilterOptions,
						orderOptions: orderOptions && Object.keys(orderOptions)?.length ? orderOptions : null,
						defaultRequest: true,
						tagOptions,
						tagItems,
					},
				};
			} else {
				existingPagination = {
					[filterType]: {
						pageOptions,
						filterOptions: cleanedFilterOptions,
						orderOptions: orderOptions && Object.keys(orderOptions)?.length ? orderOptions : null,
						defaultRequest: true,
						tagOptions,
						tagItems,
					},
				};
			}
			Cookies.set("ed_pg_ft", EncodeObjectToBase64(existingPagination), { expires: new Date(new Date().getTime() + 8 * 60 * 60 * 1000), sameSite: "Lax" });
		}
	};
}

function isEmptyObject(obj) {
	if (typeof obj !== "object") return null;
	return Object.keys(obj).length === 0;
}
