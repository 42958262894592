import ButtonIconStyled from "../../styles/icons/buttons";

type Props = {
	type: "add" | "settings" | "export" | "search" | "email" | "request" | "move" | "compliance";
};

const ButtonIcon = ({ type }: Props) => (
	<ButtonIconStyled>
		{type === "add" && (
			<svg viewBox="0 0 7.14 7.14">
				<path data-name="Path 10717" d="M2.828 0v2.9H0v1.344h2.828v2.9h1.484v-2.9H7.14V2.898H4.312v-2.9z" fill="#fff" />
			</svg>
		)}
		{type === "settings" && (
			<svg viewBox="0 0 489.802 489.802" xmlSpace="preserve">
				<path
					d="m20.701 281.901 32.1.2c4.8 24.7 14.3 48.7 28.7 70.5l-22.8 22.6c-8.2 8.1-8.2 21.2-.2 29.4l24.6 24.9c8.1 8.2 21.2 8.2 29.4.2l22.8-22.6c21.6 14.6 45.5 24.5 70.2 29.5l-.2 32.1c-.1 11.5 9.2 20.8 20.7 20.9l35 .2c11.5.1 20.8-9.2 20.9-20.7l.2-32.1c24.7-4.8 48.7-14.3 70.5-28.7l22.6 22.8c8.1 8.2 21.2 8.2 29.4.2l24.9-24.6c8.2-8.1 8.2-21.2.2-29.4l-22.6-22.8c14.6-21.6 24.5-45.5 29.5-70.2l32.1.2c11.5.1 20.8-9.2 20.9-20.7l.2-35c.1-11.5-9.2-20.8-20.7-20.9l-32.1-.2c-4.8-24.7-14.3-48.7-28.7-70.5l22.8-22.6c8.2-8.1 8.2-21.2.2-29.4l-24.6-24.9c-8.1-8.2-21.2-8.2-29.4-.2l-22.8 22.6c-21.6-14.6-45.5-24.5-70.2-29.5l.2-32.1c.1-11.5-9.2-20.8-20.7-20.9l-35-.2c-11.5-.1-20.8 9.2-20.9 20.7l-.3 32.1c-24.8 4.8-48.8 14.3-70.5 28.7l-22.6-22.8c-8.1-8.2-21.2-8.2-29.4-.2l-24.8 24.6c-8.2 8.1-8.2 21.2-.2 29.4l22.6 22.8c-14.6 21.6-24.5 45.5-29.5 70.2l-32.1-.2c-11.5-.1-20.8 9.2-20.9 20.7l-.2 35c-.1 11.4 9.2 20.8 20.7 20.9zm158.6-103.3c36.6-36.2 95.5-35.9 131.7.7s35.9 95.5-.7 131.7-95.5 35.9-131.7-.7-35.9-95.5.7-131.7z"
					fill="#fff"
				/>
			</svg>
		)}
		{type === "export" && (
			<svg viewBox="0 0 1024 1024">
				<path
					fill="#fff"
					d="M888.3 757.4h-53.8c-4.2 0-7.7 3.5-7.7 7.7v61.8H197.1V197.1h629.8v61.8c0 4.2 3.5 7.7 7.7 7.7h53.8c4.2 0 7.7-3.4 7.7-7.7V158.7c0-17-13.7-30.7-30.7-30.7H158.7c-17 0-30.7 13.7-30.7 30.7v706.6c0 17 13.7 30.7 30.7 30.7h706.6c17 0 30.7-13.7 30.7-30.7V765.1c0-4.3-3.5-7.7-7.7-7.7zm18.6-251.7L765 393.7c-5.3-4.2-13-.4-13 6.3v76H438c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z"
				/>
			</svg>
		)}
		{type === "search" && (
			<svg viewBox="0 0 16 16">
				<path
					fill="#fff"
					d="M12.027 9.92 16 13.95 14 16l-4.075-3.976A6.465 6.465 0 0 1 6.5 13C2.91 13 0 10.083 0 6.5 0 2.91 2.917 0 6.5 0 10.09 0 13 2.917 13 6.5a6.463 6.463 0 0 1-.973 3.42zM1.997 6.452c0 2.48 2.014 4.5 4.5 4.5 2.48 0 4.5-2.015 4.5-4.5 0-2.48-2.015-4.5-4.5-4.5-2.48 0-4.5 2.014-4.5 4.5z"
					fillRule="evenodd"
				/>
			</svg>
		)}
		{type === "email" && (
			<svg viewBox="0 -2.5 20 20">
				<path d="m18 2.291-8 7.027-8-7.037V2h16v.291ZM2 13V4.945l8 7.035 8-7.027V13H2Zm-2 2h20V0H0v15Z" fill="#fff" fillRule="evenodd" />
			</svg>
		)}
		{type === "request" && (
			<svg viewBox="0 0 331.37 331.37">
				<path
					fill="#fff"
					d="M111.374 188.961c0 4.956-4.055 9.01-9.01 9.01H57.313c-4.955 0-9.01-4.055-9.01-9.01v-30.113c0-4.956 4.055-9.01 9.01-9.01h45.051c4.956 0 9.01 4.055 9.01 9.01v30.113zM111.374 268.091c0 4.956-4.055 9.01-9.01 9.01H57.313c-4.955 0-9.01-4.055-9.01-9.01v-30.113c0-4.956 4.055-9.01 9.01-9.01h45.051c4.956 0 9.01 4.055 9.01 9.01v30.113zM197.222 188.961c0 4.956-4.055 9.01-9.01 9.01H143.16c-4.956 0-9.01-4.055-9.01-9.01v-30.113c0-4.956 4.055-9.01 9.01-9.01h45.052c4.956 0 9.01 4.055 9.01 9.01v30.113zM197.222 268.091c0 4.956-4.055 9.01-9.01 9.01H143.16c-4.956 0-9.01-4.055-9.01-9.01v-30.113c0-4.956 4.055-9.01 9.01-9.01h45.052c4.956 0 9.01 4.055 9.01 9.01v30.113zM282.018 188.961c0 4.956-4.055 9.01-9.01 9.01h-45.052c-4.956 0-9.01-4.055-9.01-9.01v-30.113c0-4.956 4.055-9.01 9.01-9.01h45.052c4.956 0 9.01 4.055 9.01 9.01v30.113zM282.018 268.091c0 4.956-4.055 9.01-9.01 9.01h-45.052c-4.956 0-9.01-4.055-9.01-9.01v-30.113c0-4.956 4.055-9.01 9.01-9.01h45.052c4.956 0 9.01 4.055 9.01 9.01v30.113zM70.786 82.453c-5.383 0-9.787-4.404-9.787-9.788V19.697c0-5.384 4.404-9.788 9.787-9.788h20.361c5.383 0 9.788 4.404 9.788 9.788v52.968c0 5.383-4.404 9.788-9.788 9.788H70.786zM240.301 82.453c-5.383 0-9.787-4.404-9.787-9.788V19.697c0-5.384 4.404-9.788 9.787-9.788h20.361c5.383 0 9.788 4.404 9.788 9.788v52.968c0 5.383-4.404 9.788-9.788 9.788h-20.361z"
				/>
				<path
					fill="#fff"
					d="M321.917 49.935h-28.491c-1.628 0-4.64 0-4.64 3.753v16.754c0 15.996-8.86 29.01-29.01 29.01h-18.745c-19.106 0-29.01-13.014-29.01-29.01l.001-15.879c0-3-2.096-4.628-4.596-4.628h-82.751c-1.836 0-5.326 0-5.326 4.753v15.754c0 15.996-7.976 29.01-29.01 29.01H71.594c-23.292 0-29.01-13.014-29.01-29.01V55.313c0-4.25-3.826-5.378-5.909-5.378H9.454C4.254 49.935 0 54.189 0 66.393v252.618c0-1.804 4.254 2.45 9.454 2.45h312.462c5.2 0 9.454-4.254 9.454-2.45V66.393c0-12.204-4.254-16.458-9.453-16.458zm-11.555 241.063c0 5.2-4.254 9.454-9.454 9.454H30.463c-5.2 0-9.454-4.254-9.454-9.454V134.464c0-5.2 4.254-9.454 9.454-9.454h270.445c5.2 0 9.454 4.254 9.454 9.454v156.534z"
				/>
			</svg>
		)}
		{type === "move" && (
			<svg preserveAspectRatio="xMidYMid" viewBox="0 0 31.906 31.906">
				<path
					d="m31.567 16.699-3.945 3.945a.998.998 0 0 1-1.41-1.41l2.327-2.328H16.945v11.596l2.289-2.288a.996.996 0 1 1 1.41 1.41l-3.989 3.988c-.031.032-.072.045-.107.071-.069.053-.135.11-.216.144a1.01 1.01 0 0 1-.382.077h-.002c-.012 0-.021-.006-.033-.007a.984.984 0 0 1-.67-.285l-3.988-3.988a.996.996 0 1 1 1.41-1.41L15 28.547V16.906H3.358l2.329 2.328a.998.998 0 0 1-1.41 1.41L.286 16.655a.996.996 0 0 1 .001-1.411l3.99-3.987a.996.996 0 1 1 1.41 1.41l-2.288 2.286H15V3.351l-2.335 2.336a.998.998 0 0 1-1.41-1.41l3.97-3.971a.943.943 0 0 1 .722-.31c.13 0 .26.026.382.077.123.05.233.124.325.216l3.988 3.988a.998.998 0 0 1-1.41 1.41L16.945 3.4v11.553h11.553l-2.286-2.286a.996.996 0 1 1 1.41-1.41l3.987 3.987a1.012 1.012 0 0 1 .293.707.946.946 0 0 1-.335.748Z"
					fill="#fff"
				/>
			</svg>
		)}
		{type === "compliance" && (
			<svg viewBox="0 0 24 24" xmlSpace="preserve">
				<path fill="#fff" d="M22 24H2V2h5V0h10v2h5v22zM4 22h16V4h-3v3H7V4H4v18zM9 5h6V2H9v3zm2 13.4-3.7-3.7 1.4-1.4 2.3 2.3 5.3-5.3 1.4 1.4-6.7 6.7z" />
			</svg>
		)}
	</ButtonIconStyled>
);

export default ButtonIcon;
